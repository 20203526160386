import { Spin } from "antd";
import React, { useState } from "react";
import Divider from "../../../components/divider";

const UserDetails = ({ signUpMode, setSignUpMode, loading, onSubmit ,googleLogin,orgSignUpMode}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullName, setFullName] = useState("");

  const handleSubmit = () => {
    const payload = { email, password, fullName };
    if (!signUpMode) delete payload.fullName;
    onSubmit(payload);
  };

  return (
    <>
      {signUpMode && (
        <input
          placeholder="Enter your Full Name"
          name="name"
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
        />
      )}
      <input
        placeholder="Enter your email"
        name="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        placeholder={signUpMode ? "Create new password" : "Enter your password"}
        type="password"
        name="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleSubmit}>
        {loading ? <Spin /> : (signUpMode ? "Sign Up" : "Sign In")}
      </button>
      {!signUpMode && <p>Forgot Password?</p>}
      {!signUpMode && (
        <p>
          New here?{" "}
          <b className="cursor-pointer" onClick={() => setSignUpMode(true)}>
            Create new account
          </b>
        </p>
      )}
      {(signUpMode && !orgSignUpMode) && (
        <p
          className="cursor-pointer fw-600"
          onClick={() => setSignUpMode(false)}
        >
          {"Login instead?"}
        </p>
      )}
      <Divider text="OR" light />
      <button style={{ background: "#ff2424" }} onClick={googleLogin}>
        Sign In with Google
      </button>
      <button style={{ background: "#008dff",marginBottom:"50px" }}> Sign In with Outlook</button>
    </>
  );
};

export default UserDetails;
