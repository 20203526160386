import moment from "moment";
import RRule from "rrule";

export const MAINENANCE_COLUMNS = [
  { id: 18, title: "Type", type: "text" },
  { id: 1, title: "Asset", type: "text" },
  { id: 22, title: "Individual", type: "bool" },
  { id: 10, title: "Assigned To", type: "text" },
  { id: 2, title: "Title", type: "text" },
  { id: 3, title: "Description", type: "text" },
  { id: 4, title: "From", type: "date" },
  { id: 5, title: "Estimated Completion Time", type: "date" },
  { id: 15, title: "Is Recurring", type: "bool" },
  { id: 16, title: "Frequency", type: "text" },
  { id: 17, title: "Repeat Until", type: "date" },
  { id: 14, title: "Vendor", type: "text" },
  { id: 8, title: "Priority", type: "text" },
  { id: 7, title: "Estimated Material Cost", type: "text" },
  { id: 13, title: "Remark", type: "text" },
  { id: 9, title: "Attachment", type: "file" },
  { id: 6, title: "Maintenance Duration", type: "text" },
  { id: 19, title: "Actual Completion Time", type: "date" },
  {id:"tags",title:"Category",type:"text"},
  { id: 20, title: "Planned Duration", type: "text" },
  { id: 21, title: "Actual Duration", type: "text" },
  { id: 11, title: "Created By", type: "text" },
  { id: 12, title: "Created On", type: "date" },
  { id: "checklists", title: "Checklists", type: "text" },
  { id: "docs", title: "Documents", type: "text" },
];

export const getFormattedEvents = (schedules) => {
  if (!schedules || !schedules.length) return;

  const formatted = [];

  for (const sch of schedules) {
    if (!sch[15]) {
      formatted.push({
        allDay: false,
        id: sch?.id,
        title: sch[2],
        start: new Date(sch[4]),
        end: new Date(sch[5]),
      });
    } else if (sch[15] === true) {
      let addType;
      let addValue;

      if (moment(new Date(sch[5])).diff(moment(new Date(sch[4])), "days") > 0) {
        addType = "days";
        addValue = moment(new Date(sch[5])).diff(
          moment(new Date(sch[4])),
          "days"
        );
      } else {
        addType = "hours";
        addValue = moment(new Date(sch[5])).diff(
          moment(new Date(sch[4])),
          "hours"
        );
      }

      const rule = new RRule({
        freq: sch[16],
        dtstart: new Date(sch[4]),
        until: new Date(sch[17]),
      });

      rule.all().forEach((date) => {
        formatted.push({
          allDay: false,
          id: sch?.id,
          title: sch[2],
          start: new Date(date),
          end: new Date(moment(new Date(date)).add(addValue, addType)),
        });
      });
    }
  }
  return formatted;
};
