import React, { useContext, useState } from "react";
// import FullCalendar from "@fullcalendar/react";
// import interactionPlugin from "@fullcalendar/interaction";
// import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import { useEffect } from "react";
import GlobalContext from "../../../store/GlobalContext";
import Timeline, {
  DateHeader,
  SidebarHeader,
  TimelineHeaders,
  TimelineMarkers,
  TodayMarker,
} from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";

const localizer = momentLocalizer(moment);

const OrdersCalender = ({ orders, monthView }) => {
  const [events, setEvents] = useState([]);
  const [resources, setResources] = useState([]);
  const { ASSETS } = useContext(GlobalContext);

  useEffect(() => {
    if (orders.length && ASSETS.length) {
      let ev = [];

      const as = ASSETS.map((d) => ({
        title: d[2],
        id: d.id,
        height: 40,
      }));

      for (const c of orders) {
        if (!c.subOrders) return;
        c.subOrders.forEach((d) => {
          ev = [
            ...ev,
            {
              start_time: new Date(d.start),
              end_time: new Date(d.end),
              allDay: false,
              group: d.asset,
              title: `${c.title}/${d.title}`,
              id: d.id,
              itemProps: {
                style: {
                  background: "#1164a3",
                  borderRadius: "3px",
                  border: "none",
                },
              },
            },
          ];
        });
      }
      setEvents([...ev]);
      setResources([...as]);
    }
  }, [orders]);

  if (monthView) {
    return (
      <Calendar
        localizer={localizer}
        startAccessor="start_time"
        events={events}
        endAccessor="end_time"
        views={["month","day"]}
        style={{ height: "75vh", minHeight: "75vh" }}
        onSelectEvent={(ev) => {}}
        selectable
        popup={true}
        showMultiDayTimes={true}
      />
    );
  }

  return (
    // <FullCalendar
    //   plugins={[interactionPlugin, resourceTimelinePlugin]}
    //   headerToolbar={{
    //     left: "prev,next",
    //     center: "title",
    //     right: "today",
    //   }}
    //   initialView="resourceTimelineDay"
    //   height="75vh"
    //   editable={true}
    //   selectable={true}
    //   weekends={true}
    //   events={events}
    //   select={(ev) => console.log(ev)}
    //   resources={resources}
    //   resourceAreaWidth="200px"
    //   resourceLabelClassNames="fw-600"
    //   resourceAreaHeaderContent={()=><p className="fw-600 fs-18 mt-10">All Machines</p>}
    //   eventColor="#1164a3"
    // />
    <Timeline
      groups={resources}
      items={events}
      defaultTimeStart={moment().add(-12, "hour")}
      defaultTimeEnd={moment().add(12, "hour")}
      style={{ maxHeight: "75vh" }}
      sidebarWidth={200}
    >
      <TimelineMarkers>
        <TodayMarker />
      </TimelineMarkers>
      <TimelineHeaders calendarHeaderStyle={{ background: "#20b494" }}>
        <SidebarHeader>
          {({ getRootProps }) => {
            return (
              <div
                style={{ background: "#20b494" }}
                className="c-white w-200 text-center pt-10 fs-18"
              >
                All Machines
              </div>
            );
          }}
        </SidebarHeader>
        <DateHeader unit="primaryHeader" className="fw-600 fs-16" />
        <DateHeader
          labelFormat="ha"
          style={{
            fontWeight: 600,
            letterSpacing: "-0.2px",
            color: "#20b494",
            background: "white",
          }}
        />
      </TimelineHeaders>
    </Timeline>
  );
};

export default OrdersCalender;
