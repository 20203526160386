import React, { useContext } from 'react';
import {Select} from 'antd';
import GlobalContext from '../../../store/GlobalContext';
import { PlusCircleOutlined } from '@ant-design/icons';


const TagsSelect = ({onClickAddTag,onChange,value,table,type, hideAdd,placeholder}) => {

    const { getTagList } = useContext(GlobalContext);

  return (
        <Select
            className="w-full"
            value={!value ? [] : value?.split(",")}
            mode="multiple"
            allowClear
            placeholder={placeholder}
            onChange={(e) => (e[e.length-1]=== "addTag" && !hideAdd) ? onClickAddTag() : onChange(e?.join(","))}
        >
            {!hideAdd && <Select.Option  value="addTag" className="link"><PlusCircleOutlined/> Add Tag</Select.Option>}
            {getTagList(table,type).map(({ title, id }) => (
                <Select.Option key={id} value={id}>
                    {title}
                </Select.Option>
            ))}
        </Select>
  )
}

export default TagsSelect