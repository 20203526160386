import React, { useContext } from 'react';
import UnauthorizedView from '../../components/UI/UnauthorizedView';
import GlobalContext from '../../store/GlobalContext';
import MaintenanceMainPage from './components/MaintenanceMainPage';

const Maintenance = ({ }) => {

    const {amIApproved} = useContext(GlobalContext);

    if(amIApproved===false){
        return <UnauthorizedView/>
    }

    return (
        <MaintenanceMainPage />
    )
}

export default Maintenance;