import React from 'react';
import './auth.scss';
import AuthCard from './components/AuthCard';

const AuthScreen = () => {
  return (
    <div className='auth'>
      <AuthCard />
    </div>
  )
}

export default AuthScreen