import {
  Button,
  Input,
  Modal,
  Row,
  Col,
  Select,
  TimePicker,
  Checkbox,
  Progress,
  Tabs,
} from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";
import moment from "moment";
import { MAINENANCE_COLUMNS } from "../maintenance-data";
import { ShowNotification } from "../../../helpers/utils";
import { NotificationType } from "../../../helpers/validators";
import GlobalContext from "../../../store/GlobalContext";
import {
  currency_otps,
  FREQUENCY_TYPES,
  PRIORITY_TYPES,
  TAG_TYPES,
} from "../../../helpers/Constants";
import { TABLES } from "../../../store/global-types";
import {
  CheckCircleFilled,
  CheckOutlined,
  EditFilled,
  EditTwoTone,
  FileSyncOutlined,
  InboxOutlined,
  InfoCircleFilled,
  MessageOutlined,
} from "@ant-design/icons";
import LogBox from "../../../components/logs/LogBox";
import VendorsSelect from "../../vendors/components/VendorsSelect";
import FrequncySelect from "../../../components/selects/FrequencySelect";
import Tag from "../../../components/tag";
import AttachChecklistModal from "../../checklists/components/AttachChecklistModal";
import ChecklistStats, {
  getAvgChecklistCompletion,
} from "../../checklists/components/ChecklistStats";
import ModalFooter from "../../../components/UI/ModalFooter";
import PrioritySelect, {
  getPriorityColor,
  getPriorityTitle,
} from "../../../components/selects/PrioritySelect";
import UploadImage from "../../../components/UI/Upload";
import EventTypeSelect from "../../../components/selects/EventTypeSelect";
import DocsBox from "../../../components/UI/DocsBox";
import UserGroupSelect from "../../users/components/UserGroupSelect";
import AssetsSelect from "../../assetRegister/components/AssetsSelect";
import UsersSelect from "../../users/components/UsersSelect";
import TagsSelect from "../../tags/components/TagsSelect";

const ModalWrapper = ({ isMobile, children }) => {
  return !isMobile ? (
    children
  ) : (
    <Tabs type="card" defaultActiveKey={0} size="middle">
      {[
        <>
          <InfoCircleFilled /> Details
        </>,
        <>
          <FileSyncOutlined /> Files
        </>,
        <CheckCircleFilled />,
        <MessageOutlined />,
      ].map((d, i) => (
        <Tabs.TabPane tab={d} key={i}>
          {children[i]}
        </Tabs.TabPane>
      ))}
    </Tabs>
  );
};

const AddScheduleModal = ({
  open,
  onClose,
  onSubmit,
  editData,
  comment,
  addComment,
  setComment,
  onFileChange,
}) => {
  const [inputData, setInputData] = useState({});
  const [updatedColumns, setUpdatedColumns] = useState([]);
  const { getChecklistById } = useContext(GlobalContext);
  const [titleEditState, setTitleEditState] = useState(false);
  const [files, setFiles] = useState([]);
  const [checklistModalOpen, setChecklistModalOpen] = useState(false);

  useEffect(() => {
    if (!editData) return;
    setInputData({ ...editData });
    setFiles(editData[9]);
  }, [editData]);

  const initData = () => {
    setInputData({});
    setUpdatedColumns([]);
    setTitleEditState(false);
    setFiles([]);
  };

  const onModalClose = () => {
    initData();
    onClose();
  };

  const onChangeHandler = (e, id, type, isEvent = false, title) => {
    if (editData) {
      if (!updatedColumns.includes(title)) {
        updatedColumns.push(title);
        setUpdatedColumns([...updatedColumns]);
      }
    }
    const value = isEvent ? e?.target?.value : e;
    setInputData({ ...inputData, [id]: value });
  };

  const findErrorsInPayload = () => {
    if (Object.keys(inputData)?.length < 5) {
      ShowNotification(
        "Error!",
        "Atleast enter 5 fields!",
        NotificationType[1]
      );
      return true;
    } else if (
      new Date(inputData[4]) > new Date(inputData[5]) ||
      new Date(inputData[4]) > new Date(inputData[19])
    ) {
      ShowNotification(
        "Error!",
        "From date cannot be greater than To date!",
        NotificationType[1]
      );
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (!editData || files?.length === editData[9]?.length) return;
    if (!updatedColumns.includes("Image")) {
      updatedColumns.push("Image");
      setUpdatedColumns([...updatedColumns]);
    }
  }, [files]);

  const handleSubmit = () => {
    const error = findErrorsInPayload();
    if (error) return;
    onSubmit({ ...inputData, 9: [...files] }, updatedColumns);
    onModalClose();
  };

  const addTime = (time, id) => {
    if (!inputData[id]) return;
    inputData[id] = inputData[id].split(" ")[0] + " " + time;
    setInputData({ ...inputData });
  };

  const defaultDate = (date) => {
    if (!!date || date?.split(" ").length === 3) {
      const dateSplit = date.split(" ");
      return moment(dateSplit[1] + " " + dateSplit[2], "hh:mm A");
    } else {
      return moment("12:00 AM", "hh:mm A");
    }
  };

  const onChecklistSelect = (ids) => {
    const checklistData = ids
      .map((id) => getChecklistById(id))
      .filter((d) => !!d?.id);
    if (!checklistData.length) return;
    const allChecklists = inputData["checklists"]
      ? { ...inputData["checklists"] }
      : {};
    for (const d of checklistData) {
      let taksObj = {};
      for (const t of d.tasks) {
        taksObj[t.id] = 0;
      }
      allChecklists[d.id] = { ...taksObj };
    }
    inputData["checklists"] = { ...allChecklists };
    setInputData({ ...inputData });
    setChecklistModalOpen(false);
  };

  const onStatusChange = (checklistId, taskId, newStatus) => {
    inputData["checklists"][checklistId][taskId] = +newStatus;
    setInputData({ ...inputData });
  };

  const onUploadClick = () => {
    document.getElementById("upload").click();
  };

  return (
    <>
      <Modal
        title={false}
        footer={false}
        visible={open}
        width={"min(100%,1700px)"}
        centered
        onCancel={onModalClose}
      >
        <div className="flex items-center justify-between">
          {!editData ? (
            <h2 className="fw-600">Add New Maintenance Schedule</h2>
          ) : titleEditState ? (
            <div className="flex items-center ">
              <Input
                className="w-300"
                value={inputData[2]}
                onChange={(e) =>
                  onChangeHandler(e, 2, "text", true, "Machine Name")
                }
              />
              <CheckCircleFilled
                style={{ color: "green" }}
                className="cursor-pointer fs-26 ml-10"
                onClick={handleSubmit}
              />
            </div>
          ) : (
            <h2 className="fw-600">
              {editData[2]}{" "}
              <EditTwoTone
                onClick={(e) => setTitleEditState(!titleEditState)}
                className="cursor-pointer"
              />
            </h2>
          )}
          <div className="flex items-center w-200">
            <Progress
              className="mr-10 fw-600"
              strokeWidth={20}
              percent={getAvgChecklistCompletion(inputData.checklists)}
            />
            <Tag color={getPriorityColor(inputData[8])} classes="mr-10 pt-1">
              {getPriorityTitle(inputData[8])}
            </Tag>
          </div>
        </div>
        <Row xs={24} gutter={10} justify="center">
          <ModalWrapper isMobile={window.innerWidth < 1000}>
            <Col xs={24} md={11}>
              <Row gutter={16} align="bottom">
                {MAINENANCE_COLUMNS.map(({ id, title, type }, i) => {
                  const value =
                    type === "date"
                      ? moment(inputData[id]).format("YYYY-MM-DD")
                      : inputData[id];
                  const diff1 = moment(inputData[5]).diff(
                    moment(inputData[4]),
                    "days"
                  );
                  const diff2 = moment(inputData[19]).diff(
                    moment(inputData[4]),
                    "days"
                  );

                  return id === 11 ||
                    id === 12 ||
                    id === 22 ||
                    (id === 2 && !!editData) ||
                    id === "checklists" ||
                    id === 21 ? null : id === "docs" ? (
                    <Input
                      type="file"
                      id="upload"
                      onChange={onFileChange}
                      hidden
                    />
                  ) : id === 20 ? (
                    <Col xs={24} md={12} className="mt-10">
                      <div className="flex">
                        <div>
                          <p className="fw-600 fs-14">Planned Duration</p>
                          <Input
                            disabled={true}
                            className="w-full"
                            type={type}
                            value={diff1}
                          />
                        </div>
                        <div>
                          <p className="fw-600 fs-14">Actual Duration</p>
                          <Input
                            disabled={true}
                            className="w-full"
                            type={type}
                            value={diff2}
                          />
                        </div>
                      </div>
                    </Col>
                  ) : id === 15 ? (
                    <Col xs={24} md={12} className="mt-10">
                      <p className="fw-600 fs-14">{title}</p>
                      <Checkbox
                        checked={value}
                        value={value}
                        disabled={inputData[18] == 2 || inputData[18] == 4}
                        onChange={(e) =>
                          onChangeHandler(
                            e.target.checked,
                            id,
                            type,
                            false,
                            title
                          )
                        }
                      />
                    </Col>
                  ) : id === 4 || id === 5 || id === 19 ? (
                    <>
                      <Col xs={24} md={12} className="mt-10">
                        <p className="fw-600 fs-14">{title}</p>
                        <div className="flex">
                          <Input
                            className="w-50p"
                            type="date"
                            value={value}
                            onChange={(e) =>
                              onChangeHandler(e, id, type, true, title)
                            }
                          />
                          <TimePicker
                            defaultValue={defaultDate(inputData[id])}
                            format="hh:mm A"
                            className="w-50p ml-10"
                            onChange={(val, time) => addTime(time, id)}
                          />
                        </div>
                      </Col>
                    </>
                  ) : (
                    <Col xs={24} md={12} className="mt-10">
                      <p className="fw-600 fs-14">{title}</p>
                      {id === 10 ? (
                        <div className="">
                          <div className="flex mb-1">
                            <p className="mr-1">Individual</p>
                            <Checkbox
                              checked={inputData[22] === true}
                              value={inputData[22] === true}
                              onChange={(e) =>
                                onChangeHandler(
                                  true,
                                  22,
                                  "bool",
                                  false,
                                  "Individual"
                                )
                              }
                            />
                            <p className="ml-20 mr-1">Team</p>
                            <Checkbox
                              checked={inputData[22] === false}
                              value={inputData[22] === false}
                              onChange={(e) =>
                                onChangeHandler(
                                  false,
                                  22,
                                  "bool",
                                  false,
                                  "Individual"
                                )
                              }
                            />
                          </div>
                          {inputData[22] == true ? (
                            <UsersSelect
                              value={inputData[10]}
                              onChange={(val) =>
                                onChangeHandler(val, 10, "text", false, title)
                              }
                            />
                          ) : (
                            <UserGroupSelect
                              value={inputData[10]}
                              onChange={(val) =>
                                onChangeHandler(val, 10, "text", false, title)
                              }
                            />
                          )}
                        </div>
                      ) : id === 22 ? (
                        <Checkbox
                          checked={inputData[22]}
                          value={inputData[22]}
                          onChange={(e) =>
                            onChangeHandler(
                              e.target.checked,
                              22,
                              type,
                              false,
                              title
                            )
                          }
                        />
                      ) : id === 1 ? (
                        <AssetsSelect
                          value={inputData[1]}
                          onChange={(val) =>
                            onChangeHandler(val, 1, type, false, title)
                          }
                        />
                      ) : id === "tags" ? (
                        <TagsSelect
                          hideAdd
                          value={value}
                          onChange={(val) =>
                            onChangeHandler(val, "tags", type, false, title)
                          }
                          table={TABLES.MAINTENANCE}
                          type={TAG_TYPES[1]}
                        />
                      ) : id === 16 ? (
                        <FrequncySelect
                          disabled={
                            !inputData[15] ||
                            inputData[18] == 2 ||
                            inputData[18] == 4
                          }
                          value={inputData[id]}
                          onChange={(val) =>
                            onChangeHandler(val, 16, type, false, title)
                          }
                        />
                      ) : id === 8 ? (
                        <PrioritySelect
                          value={inputData[id]}
                          onChange={(val) =>
                            onChangeHandler(val, 8, type, false, title)
                          }
                        />
                      ) : id === 14 ? (
                        <VendorsSelect
                          value={inputData[id]}
                          onChange={(val) =>
                            onChangeHandler(val, 14, type, false, title)
                          }
                        />
                      ) : type === "file" ? (
                        <UploadImage files={files} setFiles={setFiles} />
                      ) : id === 18 ? (
                        <EventTypeSelect
                          value={inputData[18]}
                          onChange={(val) =>
                            onChangeHandler(val, 18, type, false, title)
                          }
                        />
                      ) : id === 7 ? (
                        <Input
                          addonBefore={
                            <Select
                              onChange={(val) =>
                                onChangeHandler(val, id, type, false, title)
                              }
                              defaultValue={currency_otps[0]}
                              className="select-before"
                            >
                              {currency_otps.map((d, i) => (
                                <Select.Option key={d} value={d}>
                                  {d}
                                </Select.Option>
                              ))}
                            </Select>
                          }
                          className="w-full"
                          type={type}
                          value={value}
                          onChange={(e) =>
                            onChangeHandler(e, id, type, true, title)
                          }
                        />
                      ) : (
                        <Input
                          disabled={
                            (id === 17 && !inputData[15]) ||
                            (id === 17 &&
                              (inputData[18] == 2 || inputData[18] == 4))
                          }
                          className="w-full"
                          type={type}
                          value={value}
                          onChange={(e) =>
                            onChangeHandler(e, id, type, true, title)
                          }
                        />
                      )}
                    </Col>
                  );
                })}
              </Row>
            </Col>
            <DocsBox
              docs={inputData.docs}
              handleUpload={onUploadClick}
              md={4}
              height="540px"
            />
            <ChecklistStats
              setChecklistModalOpen={setChecklistModalOpen}
              md={4}
              data={inputData.checklists}
              onStatusChange={onStatusChange}
              height="540px"
            />
            <LogBox
              open={open}
              md={4}
              table={TABLES.MAINTENANCE}
              id={editData?.id}
              comment={comment}
              setComment={setComment}
              addComment={addComment}
              height="540px"
            />
          </ModalWrapper>
        </Row>
        <ModalFooter
          onSubmit={handleSubmit}
          editMode={!!editData}
          onClose={onModalClose}
        />
      </Modal>
      <AttachChecklistModal
        open={checklistModalOpen}
        onSelect={onChecklistSelect}
        onClose={() => setChecklistModalOpen(false)}
      />
    </>
  );
};

export default AddScheduleModal;
