import React from 'react';
import styles from './common.module.scss';

const StatBox = ({ title, value, color }) => {
    return (
        <div style={{ background: !!color && color }} className={styles.statBox}>
            <p>{title} : {value}</p>
        </div>
    )
}

export default StatBox