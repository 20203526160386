import { Button, Dropdown, Menu } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import RedCross from "../../../components/icons/RedCross";
import {
  EVENT_TYPE,
  FREQUENCY_TYPES,
  PRIORITY_TYPES,
  STATUS_TYPES,
  TAG_CATS,
  TAG_TYPES,
} from "../../../helpers/Constants";
import GlobalContext from "../../../store/GlobalContext";
import moment from "moment";
import {
  BellOutlined,
  ContactsOutlined,
  DeploymentUnitOutlined,
  ExclamationCircleOutlined,
  FallOutlined,
  FieldTimeOutlined,
  FlagOutlined,
  HistoryOutlined,
  OneToOneOutlined,
  TagsOutlined,
  TeamOutlined,
  ThunderboltTwoTone,
  UserAddOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { TABLES } from "../../../store/global-types";
import FiltersWrapper from "../../../components/UI/FiltersWrapper";

const MaintenanceFilters = ({ setEvents, EVENTS }) => {
  const { VENDORS, USERS, ASSETS, getTagList, TAGS } =
    useContext(GlobalContext);
  const [users, setUsers] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [assets, setAssets] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [tags, setTags] = useState([]);

  const onClickOption = (id, filterId) => {
    appliedFilters[filterId] = id;
    setAppliedFilters({ ...appliedFilters });
  };

  useEffect(() => {
    if (!Object.keys(appliedFilters)?.length) return;
    setEvents((prev) => {
      return prev.filter((d) => {
        return Object.entries(appliedFilters).some(([key, value]) => {
          if (key === "18") {
            return EVENT_TYPE[d[key]] == value;
          }
          if (key === "today") {
            return moment(new Date(d[5])).diff(new Date(), "days") === 0;
          } else if (key === "this_week") {
            return moment(new Date(d[5])).diff(new Date(), "weeks") === 0;
          } else if (key === "next_week") {
            return moment(new Date(d[5])).diff(new Date(), "weeks") === 1;
          } else {
            return d[key]?.toString() == value?.toString();
          }
        });
      });
    });
  }, [appliedFilters]);

  const filters = [
    {
      id: 18,
      title: "Type",
      icon: <OneToOneOutlined />,
      list: Object.values(EVENT_TYPE).map((d) => ({ key: d, value: d })),
    },
    { id: 10, title: "Assigned To", icon: <UserAddOutlined />, list: users },
    {
      id: 8,
      title: "Priority",
      icon: <FlagOutlined />,
      list: PRIORITY_TYPES.map((d) => ({
        key: d?.title,
        value: d?.id,
      })),
    },
    { id: 1, title: "Asset", icon: <DeploymentUnitOutlined />, list: assets },
    { id: 14, title: "Vendor", list: vendors, icon: <TeamOutlined /> },
    { id: "tags", title: "Tags", icon: <TagsOutlined />, list: tags },
    {
      id: 15,
      title: "Recurrence",
      icon: <TeamOutlined />,
      list: [
        { key: "Recurring", value: true },
        { key: "Non Recurring", value: false },
      ],
    },
    {
      id: 16,
      title: "Frequency",
      icon: <BellOutlined />,
      list: FREQUENCY_TYPES.map((d) => ({
        key: d?.title,
        value: d?.id,
      })),
    },
    { id: 11, title: "Created By", list: users, icon: <ContactsOutlined /> },
    {
      id: 6,
      title: "Status",
      icon: <WarningOutlined />,
      list: STATUS_TYPES.map((d) => ({
        key: d?.title,
        value: d?.id,
      })),
    },
    {
      id: "today",
      title: "Due today",
      list: null,
      icon: <ExclamationCircleOutlined />,
    },
    {
      id: "this_week",
      title: "Due this week",
      list: null,
      icon: <HistoryOutlined />,
    },
    {
      id: "next_week",
      title: "Due next week",
      list: null,
      icon: <FieldTimeOutlined />,
    },
  ];

  useEffect(() => {
    if (!VENDORS || !VENDORS.length) return;
    setVendors(
      VENDORS.map((d) => ({
        key: d?.name,
        value: d?.id,
      }))
    );
  }, [VENDORS]);

  useEffect(() => {
    if (!TAGS || !Object.keys(TAGS).length) return;
    const allTags = getTagList(TABLES.MAINTENANCE, TAG_TYPES[1]);
    setTags(allTags.map((d) => ({ key: d?.title, value: d?.id })));
  }, [TAGS]);

  useEffect(() => {
    if (!ASSETS || !ASSETS.length) return;
    setAssets(
      ASSETS.map((d) => ({
        key: d[2],
        value: d?.id,
      }))
    );
  }, [ASSETS]);

  useEffect(() => {
    if (!USERS || !USERS?.length) return;
    setUsers(
      USERS.map((d) => ({
        key: d?.displayName,
        value: d?.uid,
      }))
    );
  }, [USERS]);

  const clearAll = () => {
    setAppliedFilters({});
    setEvents([...EVENTS]);
  };

  const isApplied = (id) =>
    Object.keys(appliedFilters)?.includes(id?.toString());

  return (
    <>
      <div className="flex">
        <p className="mr-10 mr-10 fw-600">
          <ThunderboltTwoTone /> Quick Filters
        </p>
        {Object.keys(appliedFilters)?.length ? (
          <Button onClick={clearAll} className="h-40 fw-600 rounded-10">
            <div className="flex items-center">
              <RedCross /> <p className="ml-1">Clear All</p>
            </div>
          </Button>
        ) : null}
      </div>
      <FiltersWrapper>
        {filters.map(({ id, title, list, icon }) =>
          !!list ? (
            <Dropdown
              overlay={
                <Menu>
                  {list.map(({ key, value }) => (
                    <Menu.Item
                      key={value}
                      onClick={({ e, key }) => onClickOption(key, id)}
                    >
                      {key}
                    </Menu.Item>
                  ))}
                </Menu>
              }
              placement="bottomCenter"
              key={id}
              trigger={["click"]}
            >
              <Button
                type={isApplied(id) ? "primary" : "default"}
                className="h-40 fw-600 rounded-10 mr-1"
              >
                {icon} {title}
              </Button>
            </Dropdown>
          ) : (
            <Button
              onClick={() => onClickOption(true, id)}
              type={isApplied(id) ? "primary" : "default"}
              className="h-40 fw-600 rounded-10 mr-1 tracking-tight"
            >
              {icon} {title}
            </Button>
          )
        )}
      </FiltersWrapper>
    </>
  );
};

export default MaintenanceFilters;
