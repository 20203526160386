import { Spin } from "antd";
import React, { useState } from "react";
import AddNewButton from "../../../components/buttons/AddNewButton";
import TabSwitchButton from "../../../components/buttons/TabSwitchButton";
import CreateOrderForm from "./CreateOrderForm";
import CreateSuborderForm from "./CreateSuborderForm";
import OrderCard from "./OrderCard";

const OrdersList = ({
  createOrder,
  orders,
  onClickOrder,
  subOrders,
  createSuborder,
  deleteOrder,
  screenState,
  setScreenState,
  deleteSuborder
}) => {

  const onClickCreate = () => {
    setScreenState(screenState + 1);
  };

  const backToAllJobs = () => setScreenState(2);
  const onClickOrderHandler = (id) => {
    onClickOrder(id);
    setScreenState(2);
  };

  return (
    <Spin spinning={false} className="w-full">
      {screenState === 0 && (
        <>
          <AddNewButton classes="w-full top-sticky" onClick={onClickCreate}>
            Create Order
          </AddNewButton>
          {!orders.length ? (
            <p className="itallic mt-20 text-center fs-16 opacity-50">
              No Orders Placed Yet!
            </p>
          ) : (
              orders.map((d) => (
                <OrderCard order={d} key={d.id} onClick={onClickOrderHandler} onDelete={deleteOrder} />
              ))
          )}
        </>
      )}
      {screenState === 1 && (
        <CreateOrderForm
          onSubmit={createOrder}
          onBack={() => setScreenState(0)}
        />
      )}
      {screenState === 2 && (
        <>
          <TabSwitchButton onClick={() => setScreenState(0)}>
            Back To All Orders
          </TabSwitchButton>
          <AddNewButton classes="w-full mt-10" onClick={onClickCreate}>
            Create New Jobs
          </AddNewButton>
          <h1 className="mt-10">All Jobs</h1>
          {!subOrders.length ? (
            <p className="itallic mt-20 text-center fs-16 opacity-50">
              No sub-orders Placed Yet!
            </p>
          ) : (
            subOrders.map((d) => (
              <OrderCard order={d} key={d.id} onClick={() => {}} subOrder onDelete={deleteSuborder}/>
            ))
          )}
        </>
      )}
      {screenState === 3 && (
        <CreateSuborderForm
          backToAllJobs={backToAllJobs}
          onSubmit={createSuborder}
        />
      )}
    </Spin>
  );
};

export default OrdersList;
