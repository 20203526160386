import React from "react";
import cx from "classnames";
import styles from "./common.module.scss";

const FiltersWrapper = ({ children }) => {
  return (
    <div className={cx("flex items-center mb-20 mt-1", styles.filters)}>
      {children}
    </div>
  );
};

export default FiltersWrapper;
