export const ORDER_COLS = [
    {id:'title',title:"Order Id",type:"text",placeholder:"Enter Order title"},
    {id:'customer',title:"Customer",type:"text",placeholder:"Select Customer"},
    {id:'isVendor',title:"Vendor",type:"bool"},
    {id:'vendor',title:"Vendor",type:"text",placeholder:"Select Vendor"}
];

export const SUB_ORDER_COLS = [
    {id:'title',title:"Sub-Order Id",type:"text",placeholder:"Enter Sub-Order title"},
    {id:'description',title:"Description",type:'text',placeholder:"Enter description"},
    {id:'assetGroup',title:"Asset Group",type:"text",placeholder:"Select asset group"},
    {id:'asset',title:"Asset",type:'text',placeholder:'Secect asset'},
    {id:'start',title:"Start time",type:'date'},
    {id:'end',title:"End time",type:'date'}
]