import React from 'react';
import { BrowserRouter as Router, Routes as AllRoutes, Route, Navigate } from 'react-router-dom';
import { IS_SM_LOGGED_IN, SMF_TOKEN, SM_TOKEN } from '../helpers/Constants';
import AssetRegister from './assetRegister';
import AuthScreen from './auth';
import Checklists from './checklists';
import ConfigurationManager from './configurationManager';
import Dashboards from './dashboards';
import FileManager from './fileManager';
import FileList from './fileManager/components/FileList';
import HomePage from './home';
import Locations from './locations';
import MaintenancePage from './maintenance';
import SideBar from './navigation';
import Orders from './orders';
import MachineUtilization from './production/MachineUtilization';
import MyProfile from './profile';
import TagManager from './tags';
import UserManagement from './users';
import Vendors from './vendors';

const Routes = () => {

  const isLoggedIn = localStorage.getItem(IS_SM_LOGGED_IN) === "true";

  return (
    <Router>
      {!isLoggedIn ? <AuthScreen /> :
        <SideBar>
          <AllRoutes>
            <Route exact path='/' element={<Navigate replace to="/dashboard" />} />
            <Route path='/home' element={<HomePage />} />
            <Route path='/profile' element={<MyProfile />} />
            <Route path='/dashboard' element={<Dashboards/>}/>
            <Route path='/performance' element={<MachineUtilization />} />
            <Route path='/configuration-manager' element={<ConfigurationManager />} />
            <Route path="/assets" element={<AssetRegister />} />
            <Route path="/user-management" element={<UserManagement />} />
            <Route path="/maintenance" element={<MaintenancePage />} />
            <Route path="/settings" element={<TagManager />} />
            <Route path="/locations" element={<Locations/>}/>
            <Route path="/vendors" element={<Vendors/>}/>
            <Route path='/checklists' element={<Checklists/>}/>
            <Route path='/files' element={<FileManager/>}/>
            <Route path='/files/:folder' element={<FileList/>}/>
            <Route path='/orders' element={<Orders/>}/>
          </AllRoutes>
        </SideBar>}
    </Router>
  )
}

export default Routes