import { Avatar, Button, Popover } from "antd";
import React, { useState } from "react";
import moment from "moment";

const Header = ({ user }) => {
  return (
    <div className="header">
      {!user ? (
        <></>
      ) : (
        <Popover
          trigger="click"
          placement="bottomRight"
          content={
            <div className="">
              <Avatar
                className="ant-menu-item-selected fs-22 cursor-pointer"
                size={55}
                src={user.photoURL}
              >
                {user?.displayName
                  ? user.displayName.substring(0, 1).toUpperCase()
                  : user?.email?.substring(0, 1)?.toUpperCase() || "U"}
              </Avatar>
              <p className="mb-0 mt-1 fs-16 fw-600 mt-10">
                {user?.displayName}
              </p>
              <p>{user?.email}</p>
              <p> {user.tenantName}</p>
              <p className="fs-14 opacity-50 mt-10 ">
                Joined on {moment(+user.createdAt).format("DD MMM, YYYY")}
              </p>
            </div>
          }
        >
          <Avatar
            className="ant-menu-item-selected c-white fs-18 cursor-pointer c-black"
            size="large"
          >
            {user.displayName
              ? user.displayName.substring(0, 1).toUpperCase()
              : user.email.substring(0, 1).toUpperCase()}
          </Avatar>
        </Popover>
      )}
    </div>
  );
};

export default React.memo(Header);
