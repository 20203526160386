import { Row, Spin } from 'antd';
import { addDoc, collection, doc, setDoc } from 'firebase/firestore';
import React, { useState, useContext, useEffect } from 'react';
import TabSwitchButton from '../../../components/buttons/TabSwitchButton';
import { db } from '../../../firebase';
import AddScheduleModal from './AddScheduleModal';
import { TABLES } from '../../../store/global-types';
import { deleteTableRows, onDocUpload } from '../../../store/global-actions';
import MaintenanceCalender from './MaintenanceCalender';
import MaintenanceTable from './MaintenanceTable';
import GlobalContext from '../../../store/GlobalContext';
import Button from '../../../components/buttons';
import { checkFirebaseAuth, getUserFromStore } from '../../../api/auth';
import moment from 'moment';
import { logActivity, LOG_TYPES } from '../../../api/logs'
import { ShowNotification } from '../../../helpers/utils'
import { NotificationType } from '../../../helpers/validators';
import AddNewButton from '../../../components/buttons/AddNewButton';
import { BarsOutlined, CalendarOutlined } from '@ant-design/icons';

const MaintenanceMainPage = ({ }) => {

    const [tab, setTab] = useState(0);
    const [addScheduleModalOpen, setAddScheduleModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [schedules, setSchedules] = useState([]);
    const [editData, setEditData] = useState(null);
    const [comment, setComment] = useState('');
    const { getMaintenanceSchedules, fakeUpdateLogs, MAINTENANCE, getUserByUid, BASE_URL } = useContext(GlobalContext);

    const user = getUserFromStore();


    const initialize = () => {
        setLoading(false);
        setSelectedRows([]);
    }


    useEffect(() => {
        if (!MAINTENANCE || !MAINTENANCE?.length) return;
        setSchedules([...MAINTENANCE]);
    }, [MAINTENANCE?.length]);


    const onDeleteRows = async () => {
        setLoading(true);
        await deleteTableRows(
            selectedRows,
            TABLES.MAINTENANCE,
            () => {
                getMaintenanceSchedules();
                initialize();
            },
            () => {
                initialize();
            }, BASE_URL);
    }

    const onSubmitAddSchedule = async (data, updatedColumns) => {
        if (!data) return;

        const auth = checkFirebaseAuth(true);
        if (!auth) return;

        setLoading(true);
        try {
            if (data?.id) {
                const doc_id = data?.id;
                delete data.id;
                await setDoc(doc(db, `${BASE_URL}/${TABLES.MAINTENANCE}`, doc_id), { ...data }, { merge: true });
                const what = `${updatedColumns?.join(", ")}`;
                const log = await logActivity(TABLES.MAINTENANCE, user?.email, what, LOG_TYPES.UPDATED, doc_id, BASE_URL);
                fakeUpdateLogs(log, doc_id, TABLES.MAINTENANCE);
                const index = schedules.findIndex(d => d?.id === doc_id);
                schedules[index] = { ...data, id: doc_id };
                setSchedules([...schedules]);
            } else {
                const { uid } = user;
                const userId = getUserByUid(uid)?.id;
                if (!userId) return;

                let payload = {
                    ...data,
                    11: userId,
                    12: moment(new Date).format("YYYY-MM-DD")
                };

                await addDoc(collection(db, `${BASE_URL}/${TABLES.MAINTENANCE}`), payload);
                // const what = `a new schedule ${payload[2]}`;
                // const log = await logActivity(TABLES.MAINTENANCE, user?.email, what, LOG_TYPES.ADDED);
                setSchedules([...schedules, payload]);
            }
            setLoading(false);

        } catch (err) {
            console.log(err);
            ShowNotification("Error!", err?.message || "something went wrong!", NotificationType[1]);
            setLoading(false);
        }
    }

    const onModalClose = () => {
        setAddScheduleModalOpen(false);
        setEditData(null);
    }

    //ADD A COMMENT TO THE TABLE ROW
    const addComment = async (id) => {
        if (!comment?.length) return;

        const auth = checkFirebaseAuth(true);
        if (!auth) return;

        setLoading(true);
        const log = await logActivity(TABLES.MAINTENANCE, user?.email, comment, LOG_TYPES?.COMMENTED, id, BASE_URL);
        fakeUpdateLogs(log, id, TABLES.MAINTENANCE);
        setComment("");
        setLoading(false);
    }

    const onFileChange = (e) =>{
        const file = e.target.files[0];
       if(!file) return;
        if(file?.size > 1200000){
            ShowNotification('Error',"file size is too big, keep it less then 1MB",NotificationType[1]);
            return;
        }

        const auth = checkFirebaseAuth(true);
        if (!auth) return;

        const id = editData?.id;
        if(!id) return;

        setLoading(true);
        setAddScheduleModalOpen(false);

        onDocUpload(file,id,BASE_URL,TABLES.MAINTENANCE,'docs');
    }



    return (
        <div className="container">
            <h1>Maintenance</h1>
            <Row align='center' justify='space-between' gutter={[0, 10]}>
                <div className='flex items-center'>
                    <TabSwitchButton selected={tab === 0} onClick={() => setTab(0)} classes="small"><BarsOutlined style={{fontSize:22}}/></TabSwitchButton>
                    <TabSwitchButton selected={tab === 1} onClick={() => setTab(1)} classes="small"><CalendarOutlined style={{fontSize:22}}/></TabSwitchButton>
                </div>
                <div className='flex items-center'>
                    {!!selectedRows?.length && <Button onClick={onDeleteRows} type="primary" classes="danger mr-10">Delete Rows</Button>}
                    <AddNewButton classes='ml-10' type="primary" onClick={() => setAddScheduleModalOpen(true)} >New Activity</AddNewButton>
                </div>
            </Row>
            {tab === 0 && <MaintenanceTable setAddScheduleModalOpen={setAddScheduleModalOpen} loading={loading} selectedRows={selectedRows} setSelectedRows={setSelectedRows} setEditData={setEditData} schedules={schedules} setSchedules={setSchedules} />}
            {tab === 1 && <MaintenanceCalender schedules={schedules} setEditData={setEditData} setAddScheduleModalOpen={setAddScheduleModalOpen} loading={loading} />}

            <AddScheduleModal open={addScheduleModalOpen} onClose={onModalClose} onSubmit={onSubmitAddSchedule} editData={editData} comment={comment} setComment={setComment} addComment={addComment} onFileChange={onFileChange}/>
            {/* <OrgsData /> */}
        </div>
    )
}

export default MaintenanceMainPage;