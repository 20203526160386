import {
  EyeOutlined,
  PaperClipOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Button, Col, Divider } from "antd";
import React, { useState } from "react";
import FilePreviewWindow from "../../pages/fileManager/components/FilePreviewWindow";

const DocsBox = ({ md, docs, handleUpload, height }) => {
  const [file, setFile] = useState(null);
  return (
    <>
      <Col
        xs={24}
        md={md || 6}
        style={{
          borderLeft: "1px solid #eee",
          borderRadius: 6,
          marginRight: 5,
          marginLeft: 5,
        }}
        className="py-2 logs-bg"
      >
        <h2 className="fw-600 ml-10">
          <PaperClipOutlined /> Documents
        </h2>
        <div
          className="w-full logs__column"
          style={{ paddingRight: "0px", height: !!height && height }}
        >
          {docs && Array.isArray(docs) ? (
            docs.map(({ name, url, user, timestamp }, i) => (
              <div className="">
                <h3 className="fw-600 c-primary">
                  {i + 1}. {name}
                </h3>
                <p className="fs-12  text-nowrap">Uploaded by {user}</p>
                <p className="fs-12 fw-600 text-nowrap tracking-tight opacity-50">
                  {timestamp}
                </p>
                <p
                  className="c-primary fw-500 cursor-pointer underline"
                  onClick={() => setFile(url)}
                >
                  <EyeOutlined/> Preview
                </p>
                <Divider className="my-1" />
              </div>
            ))
          ) : (
            <div className="nologs--text">
              <p className="">No Document Attached!</p>
            </div>
          )}
        </div>
        <Button
          onClick={handleUpload}
          className="bg-primary c-white fw-600 w-full mt-30"
        >
          <UploadOutlined /> Upload a document
        </Button>
      </Col>
      <FilePreviewWindow open={file} onClose={() => setFile(null)} />
    </>
  );
};

export default DocsBox;
