import { Input, Modal, Select } from 'antd'
import React, { useState } from 'react'
import Button from '../../../components/buttons';
import images from '../checklist-data';
import moment from 'moment';
import { useEffect } from 'react';
import { DeleteFilled, PlusSquareOutlined } from '@ant-design/icons';
import { getUserFromStore } from '../../../api/auth';

export const TASK_STATUS = {
  0:'In Progress',
  1:'On Hold',
  2:'Completed',
  3:'Canceled'
}

const AddChecklistModal = ({open,onClose:onCancel,onAdd,copyData}) => {

  const [checklist,setChecklist] =useState({tasks:[{title:''}]});

  useEffect(()=>{
   if(!!copyData){
    setChecklist({
        ...copyData
    })
   }
  },[copyData]);

  const addTask = () =>{
    checklist.tasks.push({title:''});
    setChecklist({...checklist});
  }

  const setTask = (e,index) =>{
    checklist.tasks[index] = {title:e.target.value};
    setChecklist({...checklist,tasks:[...checklist.tasks]});
  }

  const onSubmit = () =>{
   
    const user = getUserFromStore();

    const checklist_data = {
        ...checklist,
        createdBy:user.uid,
        createdOn:moment(new Date()).format('DD MMM, YYYY'),
        tasks:checklist.tasks.map((task,i) =>({
            id:i,
            ...task
        }))
    }
    onAdd(checklist_data);
    setChecklist({tasks:['']});
    onClose();
  }

  const deleteTask = (index) =>{
    checklist.tasks.splice(index,1);
    setChecklist({...checklist,tasks:[...checklist.tasks]});
  }

  const onClose = () =>{
    onCancel();
    setChecklist({tasks:['']});
  }

  return (
    <Modal
            title={false}
            footer={false}
            visible={open}
            width={"min(100%,600px)"}
            centered
            className='checklist__modal'
            onCancel={onClose}
        >
        <div>
            <h1>Add new checklist</h1>
            <p className='fw-600'>Title</p>
            <Input placeholder='enter checklist title' className='' value={checklist.title} onChange={e=>setChecklist({...checklist,title:e.target.value})}/>
            <p className='fw-600 mt-10'>Add Tasks</p>
             {
                checklist.tasks.map(({title},i)=>
                <div className='w-full flex items-center'>
                <Input placeholder={`add task ${i+1}`} key={i} value={title} onChange={e=>setTask(e,i)} className=' mt-10 w-80p'/>
                <DeleteFilled onClick={()=>deleteTask(i)} className="mt-1 ml-10 cursor-pointer" style={{color:"#636363",fontSize:"20px"}}/>      
                </div>
                    )
             }            
             <div className='flex justify-between items-center w-full mt-30'>
            <PlusSquareOutlined className='cursor-pointer' onClick={addTask} style={{fontSize:30}}/>
             <div className='flex mr-10'>
              <Button  classes=' bordered mr-10' onClick={onClose}>Cancel</Button>
              <Button classes='' onClick={onSubmit}>Save Checklist</Button>
            </div>
            {/* <img src={images.add} alt="add" className='mr-10  cursor-pointer' onClick={addTask} height={50}/> */}
            </div>
        </div>
    </Modal>
  )
}

export default AddChecklistModal