import React from "react";
import { DeleteFilled } from "@ant-design/icons";
import { useContext } from "react";
import GlobalContext from "../../../store/GlobalContext";
import moment from "moment";

const OrderCard = ({ order, onClick, subOrder,onDelete }) => {
  const {getVendorById,getAssetById} = useContext(GlobalContext);

  const onClickOrder = () => {
    onClick(order.id);
  };

  const orderDeleteHandler = (e) =>{
    e.preventDefault();
    e.stopPropagation();
    subOrder ? onDelete(order.orderId,order.id) : onDelete(order.id);
  }

  const vendor = !subOrder ? getVendorById(order.vendor) : null;
  const customer = !subOrder ? getVendorById(order.customer) : null;
  const asset = subOrder ? getAssetById(order.asset) : null;

  return (
    <div className="order" onClick={onClickOrder}>
      <div className="flex justify-between">
        <h3 className="fw-600 fs-18 mb-0">{order.title}</h3>
        <DeleteFilled className="c-error" onClick={orderDeleteHandler}/>
      </div>
      {subOrder ? (
        <>
          <p style={{fontStyle:"italic"}} className="opacity-70">{order.description}</p>
          <p>{order.assetGroup}</p>
          <p>{!!asset ? asset[2] : ''}</p>
          <p className="mt-10"><b>Start Time </b> <span className="itallic opacity-80">{moment(order.start).format('DD MMMM, YYYY HH:mm A')}</span></p>
          <p><b>End Time </b> <span className="itallic opacity-80">{moment(order.end).format('DD MMMM, YYYY HH:mm A')}</span></p>
        </>
      ) : (
        <>
          <p>{!!customer ? customer.name : ''}</p>
          <p className="itallic">{order.isVendor ? "Vendor" : "Internal"}</p>
          <p>{!!vendor ? vendor.name : ''}</p>
          <p className="mt-10"><b>Start Time </b><span className="itallic opacity-80">{order.start}</span></p>
          <p><b>End Time </b><span className="itallic opacity-80">{order.end}</span></p>
        </>
      )}
    </div>
  );
};

export default OrderCard;
