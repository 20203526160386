import { Card, Checkbox, Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import GreenTick from "../../../components/icons/GreenTick";
import GlobalContext from "../../../store/GlobalContext";
import Avatar from "../../../components/avatar";
import TableManipulator from "../../../components/table/TableManipulator";
import { USERS_COLUMNS_DIFINITION } from "../user-data";
import { TABLES } from "../../../store/global-types";
import RedCross from "../../../components/icons/RedCross";
import { doc, setDoc } from "firebase/firestore";
import { ShowNotification } from "../../../helpers/utils";
import { NotificationType } from "../../../helpers/validators";
import { auth, db } from "../../../firebase";
import emailjs from '@emailjs/browser';
import { EMAIL_PUBLIC_KEY, EMAIL_SERVICE_ID, EMAIL_TEMPLATE_ID } from "../../../helpers/Constants";
import AntdTable from "../../../components/table/AntdTable";
import { CrownFilled } from "@ant-design/icons";

const Users = () => {
  const { USERS,amIAdmin, BASE_URL } = useContext(GlobalContext);
  const [users, setUsers] = useState([]);
  const [userColumns, setUserColumns] = useState(USERS_COLUMNS_DIFINITION);
  const [view, setView] = useState(0);

  useEffect(() => {
    if (!USERS || !USERS?.length) return;
    setUsers([...USERS]);
  }, [USERS?.length]);

  const approveUserByAdmin = async (user,index) => {
    if(!amIAdmin) return;
    if(!user?.id) return;
    try {
      await setDoc(
        doc(db, `${BASE_URL}/users/${user.id}`),
        { approvedByAdmin: true },
        { merge: true }
      );

      const {displayName,email} = user;
      const data ={email:email,name:displayName};

      await emailjs.send(EMAIL_SERVICE_ID,EMAIL_TEMPLATE_ID, data,EMAIL_PUBLIC_KEY);

      ShowNotification("Success!", "User Approved", NotificationType[0]);
      users[index].approvedByAdmin = true;
      setUsers([...users]);
    } catch (err) {
      ShowNotification("Error!", err.message, NotificationType[1]);
      console.log(err);
    }
  };

  const pendingApproval = (user) => user?.approvedByAdmin === false;

  const USERS_ANTD_COLS = [
    {title:"",id:null,render:(text,record)=><Avatar name={record?.displayName} />},
    ...userColumns.map((col)=>{

    return{
      ...col,
      render: (text,record,index) => 
      col?.id==="displayName" ? 
      (!text ? undefined : (<>
      <div className="flex">
      <div>
      <p>{record?.displayName}</p><p className="opacity-70 mt-0">{record?.email}</p>
      </div>
      {record?.userGroup == "Admin" && <CrownFilled style={{fontSize:20}}/>}
      </div>
      </>)) :
      col?.id === "createdAt"  ? moment(+text).fromNow() :
      col?.id === "phoneNumber" ? (text || "N/A") :
      col?.id === "emailVerified" ? (!text ? <RedCross classes="mx-auto" /> :<GreenTick classes="mx-auto" />):
      col?.id === "approvedByAdmin" ?  <div className="flex items-end h-full justify-center">
      {((amIAdmin && pendingApproval(record)) || pendingApproval(record)) && (
        <RedCross classes="mx-1" />
      )}
      {((amIAdmin && pendingApproval(record)) || !pendingApproval(record)) && (
        <GreenTick classes="mx-1" onClick={()=>approveUserByAdmin(record,index)} />
      )}
    </div> : text
    }
  })];

  return (
    <>
      <TableManipulator
        allColumns={USERS_COLUMNS_DIFINITION}
        columns={userColumns}
        data={USERS}
        filteredData={users}
        setColumns={setUserColumns}
        setFilteredData={setUsers}
        sortOn={1}
        setView={setView}
        table={TABLES.USERS}
      />
      {view === 0 && <AntdTable data={users} columns={USERS_ANTD_COLS} notSelectable/>}
      {view == 1 && (
        <Row gutter={[24, 16]} justify="center" className="mt-20">
          {users.map((user, i) => (
            <UserCard user={user} key={user?.id} />
          ))}
        </Row>
      )}
    </>
  );
};

export default Users;


const UserCard = ({ user }) => {
  return (
    <Col xs={22} lg={8} xl={6}>
      <Card
        hoverable
        style={{ boxShadow: "1px 2px 6px  #eee" }}
        // cover={<div style={{ backgroundColor: "#20b494", height: 20 }}></div>}
      >
        <img
          src={user?.photoURL}
          height={100}
          width="100%"
          className="mb-20 object-contain"
        />
        <h3 className="fw-600">{user?.displayName}</h3>
        <p className="opacity-50">{user?.email}</p>
        <p>Account created {moment(+user?.createdAt).fromNow()}</p>
        <p>
          <b>User Group:</b> {user?.userGroup}
        </p>
        <p className="mt-10">
          {user?.emailVerified ? (
            <div className="flex items-center">
              <GreenTick /> <p className="ml-10">Verified User</p>
            </div>
          ) : (
            "User Unverified"
          )}
        </p>
      </Card>
    </Col>
  );
};
