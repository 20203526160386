import { Card, Col, Row } from 'antd'
import React from 'react'
import Tag from '../../../components/tag'
import UploadImage from '../../../components/UI/Upload'

const GallaryView = ({ data, columns, tagString }) => {
    return (
        <Row gutter={[24, 16]} justify="center" className="mt-20">
            {
                data.map((asset, i) => (
                    <Col xs={22} lg={12} xl={6} key={i}>
                        <Card
                            hoverable
                            style={{ boxShadow: "1px 2px 6px  #eee" }}
                        >
                            <UploadImage files={asset[5]} readOnly classes="my-10"/>
                            {
                                columns.filter(d => d?.type !== "file").map((col) => (
                                    col.id === 15 ?
                                        <div className="flex flex-wrap items-center">
                                            <p><b className='mr-10'>{col?.title}:-</b></p> {tagString(asset[col?.id]).slice(0,2).map(tag => <Tag key={tag} classes="m-1">{tag} </Tag>)}
                                        </div>
                                        :
                                        col?.id === 16 ?
                                        <p><b className='mr-10'>{col?.title}:-</b>{asset[col?.id]?.length}</p> :
                                        <p><b className='mr-10'>{col?.title}:-</b>{asset[col?.id]}</p>
                                ))
                            }
                        </Card>
                    </Col>
                ))
            }

        </Row>
    )
}

export default GallaryView