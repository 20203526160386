import { Button, DatePicker, InputNumber, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { KEYS } from './home-data';
import './home.css';
import moment from 'moment';
import { GetKeys } from '../../api/home';


const HomePage = () => {

  const token = localStorage.getItem('token');
  const [payloadData, setPayloadData] = useState({});
  const [keys,setKeys]=useState();
  const [loading,setLoading]=useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) navigate('/');
  }, [token]);


  const getDataHandler = async () => {
    setLoading(true);
    const { key, start_time, end_time,limit } = payloadData;
    const {data,status} = await GetKeys(key, start_time, end_time,limit);
    setLoading(false);
    if(!!data){
      setKeys({...data});
    }
  }
  const pickerStyles = { width: 300, height: "40px" };

  return (
    <div className='home'>
      <h1 className='c-primary'>Home Page</h1>
      <InputNumber placeholder='Enter Limit!' style={pickerStyles} value={payloadData.limit} onChange={val => setPayloadData({ ...payloadData, limit: val })} />
      <Select
        title="Select a value!"
        placeholder="Select a value!"
        className='mt-10'
        onChange={(value) => setPayloadData({ ...payloadData, key: value })}
        value={payloadData.key}
        style={pickerStyles}
      >
        {
          KEYS.map(key => <Select.Option value={key} key={key}>{key}</Select.Option>)
        }
      </Select>
      <DatePicker style={pickerStyles} placeholder="start time" format="DD MMM YYYY" onChange={val => setPayloadData({ ...payloadData, start_time: moment(val).valueOf() })} />
      <DatePicker style={pickerStyles} placeholder="end time" className="mt-10" format="DD MMM YYYY" onChange={val => setPayloadData({ ...payloadData, end_time: moment(val).valueOf() })} />
      <Button type='primary' onClick={getDataHandler}>Get Data</Button>
      <div className='home__keysContainer'>
        { loading ? <Spin className='align-center'/>:
        (!!keys ?
          Object.keys(keys).map(key=>
          <div>
            <h2 className='c-primary'>{key}</h2>
            {
              keys[key].length ? keys[key].map(({ts,value})=>
                 <div key={value} className='text-left'>
                   <b>Date :-</b>{moment(new Date(ts)).format('DD MMM YYYY')}
                   <b>  Value :- </b>{value}
                </div>):null
            }
          </div>)
        : <p className='c-error'>No Data to show!</p>)}
        </div>
    </div>
  )
}

export default HomePage