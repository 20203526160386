import React from 'react'
import OrdersMainPage from './components/OrdersMainPage'
import './orders.scss';

const Orders = () => {
  return (
   <OrdersMainPage/>
  )
}

export default Orders