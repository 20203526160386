import { Spin, Switch } from 'antd'
import { doc, updateDoc } from 'firebase/firestore';
import React, { useContext, useEffect, useState } from 'react'
import { db } from '../../../firebase';
import { ShowNotification } from '../../../helpers/utils';
import { NotificationType } from '../../../helpers/validators';
import GlobalContext from '../../../store/GlobalContext'


const ModuleAccess = () => {

    const { PERMISSIONS, ROLES } = useContext(GlobalContext);
    const [loading, setLoading] = useState(false);
    const [permissons, setPermissons] = useState({});

    useEffect(() => {
        setPermissons(PERMISSIONS);
    }, [PERMISSIONS]);

    const onToggleSwith = async (val, permissionId, roleId) => {
        setLoading(true);
        const docRef = doc(db, 'permissions', permissionId);

        try {
            await updateDoc(docRef, {
                [roleId]: val
            });
            ShowNotification("Success", "Permission updated successfully!", NotificationType[0]);
            setLoading(false);
            setPermissons(prev => {
                return {
                    ...prev,
                    [permissionId]: {
                        ...prev[permissionId],
                        [roleId]: val
                    }
                }
            });
        } catch (err) {
            ShowNotification(err?.message, NotificationType[1]);
            setLoading(false);
        };
    }


    return (
        <Spin spinning={loading}>
            <table
                className='userData__table'>
                <thead>
                    <td className='pl-2'>Module</td>
                    {
                        Object.entries(ROLES).map(([id, title], index) => <td key={id} className="text-center text-nowrap">{title}</td>)
                    }
                </thead>
                <tbody>
                    {
                        Object.entries(permissons).map(([title, permission], index) => <ModuleAccessRow permission={permission} key={title} title={title} roles={ROLES} onToggle={onToggleSwith} />)
                    }
                </tbody>
            </table>

        </Spin>
    )
}

const ModuleAccessRow = ({ permission, title, roles, onToggle }) => {
    return (
        <tr>
            <td className='pl-2 fw-500'>{title}</td>
            {
                Object.entries(roles).map(([id, name]) =>
                    <td className='text-center'>
                        <Switch checked={permission[id] === true} onChange={(val) => onToggle(val, title, id)} />
                    </td>)
            }
        </tr>
    )
}

export default ModuleAccess