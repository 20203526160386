import { Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react'
import Button from '../../../components/buttons';

const AddUserGroupModal = ({ open, onClose, addData ,editData,editGroupName}) => {

    const [userGroupName, setUserGroupName] = useState('');

    const handleSubmit = async () => {
        if(editData){
            await editGroupName(editData,userGroupName);
        }else{
        await addData(userGroupName);
        }
        setUserGroupName('');
        onClose();
    }

    const handleDelete = async()=>{
        await editGroupName(userGroupName);
        setUserGroupName('');
        onClose();
    }

    useEffect(() => {
        if (!editData) return;
        setUserGroupName(editData);
    }, [editData]);

    const onModalClose = () => {
        setUserGroupName("");
        onClose();
    }


    return (
        <Modal
            title={false}
            footer={false}
            visible={open}
            width={"min(100%,400px)"}
            centered
            onCancel={onModalClose}
        >
            <h2 className='fw-600'>Add New User Group</h2>

            <p className='fw-600 fs-14'>User Group Name</p>
            <Input className='w-full' value={userGroupName} onChange={(e) => setUserGroupName(e.target.value)} />

            <Button onClick={handleSubmit} classes='w-full mt-20' type="primary" >{editData ? "Save" : "Add Asset Group"}</Button>
            {editData && <Button classes='w-full mt-20 danger h-35' onClick={handleDelete}>Delete Group</Button>}
           
        </Modal>
    )
}

export default AddUserGroupModal