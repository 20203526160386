import { DesktopOutlined, TagsOutlined } from '@ant-design/icons';
import React, { useState } from 'react'
import { useContext } from 'react'
import TabSwitchButton from '../../components/buttons/TabSwitchButton';
import UnauthorizedView from '../../components/UI/UnauthorizedView';
import GlobalContext from '../../store/GlobalContext';
import PortalMainPage from './components/PortalMainPage';
import TagsMainPage from './components/TagsMainPage'

const TagManager = () => {

    const [tab,setTab]=useState(0);
    const {amIApproved} = useContext(GlobalContext);

    if(amIApproved===false){
        return <UnauthorizedView/>
    }

    return (
         <>
         <div className='flex items-center jusityf-between'>
         <h1 className='mt-10 mb-10'>Tag Manager</h1>
         </div>
         {/* <div className='flex mb-20 mt-10'>
          <TabSwitchButton selected={tab ===0} onClick={()=>setTab(0)}><TagsOutlined/> Tags</TabSwitchButton>
          <TabSwitchButton selected={tab ===1} onClick={()=>setTab(1)}><DesktopOutlined/> Portals</TabSwitchButton>
         </div> */}
         {
            tab === 0 &&  <TagsMainPage />
         }
         {
            tab === 1 && <PortalMainPage/>
         }
         </>
    )
}

export default TagManager