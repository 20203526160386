import React, { useContext } from "react";
import GlobalContext from "../../../store/GlobalContext";
import { Select } from "antd";
import { split } from "../../../helpers/utils";

const VendorsSelect = ({
  value,
  onChange,
  classes,
  disabled,
  placeholder,
  onlyCustomers,
  onlyVendors,
}) => {
  const { VENDORS } = useContext(GlobalContext);

  return (
    <Select
      className={`w-full ${classes}`}
      disabled={disabled}
      placeholder={placeholder || "select vendor"}
      value={!value ? [] : split(value)}
      mode="multiple"
      allowClear
      onChange={(e) => onChange(e?.join(","))}
    >
      {VENDORS?.length ? (
        VENDORS?.filter((d) =>
          !!onlyCustomers
            ? d.isCustomer === true
            : !!onlyVendors
            ? !d.isCustomer
            : true
        ).map((item, i) => (
          <Select.Option key={item?.id} value={item?.id}>
            {item?.name}
          </Select.Option>
        ))
      ) : (
        <Select.Option disabled={true}>No Vendor Added</Select.Option>
      )}
    </Select>
  );
};

export default VendorsSelect;
