import { Button, Input, Modal } from 'antd'
import React, { useState } from 'react'
import { HexColorPicker } from "react-colorful";

const AddTagModal = ({ open, onClose, onSubmit }) => {

    const [tag, setTag] = useState({});

    const onClickSave = async () => {
        onClose();
        setTag({});
        onSubmit(tag);
    }

    const changeTag = (field, e) => {
        const value = e?.target ? e?.target?.value?.toString() :(typeof e === "string" ? e : '');
        setTag({ ...tag, [field]: value });
    }

    return (
        <Modal
            title={false}
            footer={false}
            visible={open}
            width={370}
            centered
            onCancel={onClose}
        >
            <h1>Add Tag</h1>
            <p className='mt-10 fw-600'>Title</p>
            <Input value={tag.title} onChange={e => changeTag("title", e)} />
            <p className='mt-10 fw-600'>Color</p>
            <HexColorPicker color={tag.color} onChange={(e) => changeTag("color", e)} prefixed />
            <p className='mt-10 fw-600'>Description</p>
            <Input value={tag.description} onChange={e => changeTag("description", e)} />
            <div className='cancelSave__buttons'>
                <Button primary>Cancel</Button>
                <Button type="primary" primary onClick={onClickSave}>Save</Button>

            </div>
        </Modal>
    )
}

export default AddTagModal