import React, { useState } from "react";
import { Col, Input, Row, Spin } from "antd";
import ChecklistsGrid from "./ChecklistsGrid";
import { dummy_checklists } from "../checklist-data";
import images from "../checklist-data";
import AddChecklistModal from "./AddChecklistModal";
import { useContext } from "react";
import GlobalContext from "../../../store/GlobalContext";
import { useEffect } from "react";
import { TABLES } from "../../../store/global-types";
import moment from "moment";
import { ShowNotification } from "../../../helpers/utils";
import { NotificationType } from "../../../helpers/validators";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { checkFirebaseAuth, getUserFromStore } from "../../../api/auth";
import { deleteTableRows } from "../../../store/global-actions";
import { SearchOutlined } from "@ant-design/icons";
import AddNewButton from "../../../components/buttons/AddNewButton";

const CheckListsMainPage = () => {
  
  const {CHECKLISTS,BASE_URL,getChecklists,getUserByUid} = useContext(GlobalContext);

  const [loading, setLoading] = useState(false);
  const [checklistModalOpen,setChecklistModalOpen]=useState(false);
  const [searchVal,setSearchVal]=useState('');
  const [checklists,setChecklists]=useState([]);
  const [copyData,setCopyData]=useState(null);

  const user = getUserFromStore();

  useEffect(()=>{
   if(!CHECKLISTS) return;
   setChecklists([...CHECKLISTS]);
  },[CHECKLISTS]);

  const onChecklistModalClose = () =>{
    setChecklistModalOpen(false);
    setCopyData(null);
  }

  const onAddChecklist = async(data) =>{
    
    const auth = checkFirebaseAuth(true);
    if (!auth || !data) return;

    setLoading(true);

    try {
        const table = `${BASE_URL}/${TABLES.CHECKLISTS}`;
        if (data?.id) {
            const doc_id = data?.id;
            delete data.id;
            await setDoc(doc(db, table, doc_id), { ...data }, { merge: true });
            const index = checklists.findIndex(d => d?.id === doc_id);
            checklists[index] = { ...data, id: doc_id };
            setChecklists([...checklists]);
        } else {
            const {uid} = user;
            await addDoc(collection(db, table), { 
              ...data ,
             createdBy:uid,
             createdOn:moment(new Date).format("YYYY-MM-DD")
            });
            await getChecklists();
        }
        setLoading(false);
    } catch (err) {
        ShowNotification("Error!", err?.message || "something went wrong!", NotificationType[1]);
        setLoading(false);
    }
  }

  const onChecklistDelete = async(id) =>{
    if(!id) return;
    setLoading(true);
      await deleteTableRows([id],TABLES.CHECKLISTS,
        ()=>{
          setLoading(false);
          const index = checklists.findIndex(d=>d?.id === id);
          checklists.splice(index,1);
          setChecklists([...checklists]);
        },
        (err)=>{
          console.log(err);
          setLoading(false);
        },
        BASE_URL,
        false)
  }

  const onChecklistDuplicate = (data) =>{
    setCopyData({...data});
    setChecklistModalOpen(true);
  }

  return (
    <Spin spinning={loading} className="container">
      {/* <div className="flex mt-10">
        <img src={images.checklist} alt="checklist" height={35} />{" "}
        <h1 className=" tracking-wide">Checklists</h1>
      </div> */}
      <h1 className="tracking-wide">Checklists</h1>
      <Row className="mb-20" justify="space-between">
        <Col xs={24} md={12} lg={8}>
        <Input value={searchVal} onChange={e=>setSearchVal(e.target.value)} placeholder="search by checklist name" suffix={<SearchOutlined/>}/>
        </Col>
        <AddNewButton onClick={()=>setChecklistModalOpen(true)}>Add New Checklist</AddNewButton>
       </Row>
      <ChecklistsGrid copy={onChecklistDuplicate} onChecklistDelete={onChecklistDelete} checklists={checklists.filter(d=>!searchVal || d.title.toLowerCase().includes(searchVal.toLowerCase()))} setChecklistModalOpen={setChecklistModalOpen} />
      <AddChecklistModal open={checklistModalOpen} onClose={onChecklistModalClose} onAdd={onAddChecklist} copyData={copyData}/>
    </Spin>
  );
};

export default CheckListsMainPage;
