import { Table } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import "./antd-overrides.css";

const AntdTable = ({ columns, data, bordered, onRowSelect ,onRowClick,notSelectable,size}) => {
  const [antColumns, setAntColumns] = useState([]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      onRowSelect(selectedRowKeys);
    },
    columnWidth: notSelectable ? 0 : 40, // Set the width to 0
  };

  useEffect(() => {
    if (!columns || !columns.length) return;

    const temp = columns.map(({ title, id, ...rest }) => ({
      key: id,
      dataIndex: id,
      title: title,
      ...rest,
    }));

    setAntColumns([...temp]);
  }, [columns]);

  const onRow = (record, rowIndex) => {
    return {
      onClick: event => onRowClick(record),
      onDoubleClick: event => {}
    };
  }

  return (
    <Table
      dataSource={data}
      rowKey="id"
      columns={antColumns}
      bordered={bordered}
      scroll={{ x: true }}
      rowSelection={{ type: "checkbox", ...rowSelection }}
      onRow={onRow}
      hideSelectionColumn={true}
      className={`${notSelectable && 'hide'}`}
      pagination={{ pageSize: 6 ,position:['bottomLeft']}}
      size={size ? size : "large"}
    />
  );
};

export default AntdTable;
