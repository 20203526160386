import React from 'react';
import {Select} from 'antd';
import { FREQUENCY_TYPES } from '../../helpers/Constants';

export const getFreqTitle = (id) => {
    const freq = FREQUENCY_TYPES.find(d=> d.id === id);
    if(!freq) return '-';
    return freq?.title;
}

const FrequncySelect = ({onChange,value,disabled,placeholder}) => {

  return (
        <Select
            className="w-full"
            value={value}
            onChange={val => onChange(val)}
            disabled={disabled}
            placeholder={placeholder || "select frequency"}
        >
            {FREQUENCY_TYPES.map(({ title, id }) => (
                <Select.Option key={id} value={id}>
                    {title}
                </Select.Option>
            ))}
        </Select>
  )
}

export default FrequncySelect