export const ASSET_TABLE_COLUMS = [
    { id: 1, title: "Machine ID", type: "text" },
    { id: 2, title: "Machine Name", type: "text" },
    { id: 3, title: "Supplier", type: "text" },
    { id: 4, title: "Model", type: "text" },
    { id: 5, title: "Image", type: "file" },
    { id: 6, title: "Description", type: "text" },
    { id: 7, title: "Serial Number", type: "number" },
    { id: 8, title: "Bar Code", type: "text" },
    { id: 9, title: "Category", type: "text" },
    { id: 10, title: "Location", type: "text" },
    { id: 11, title: "Asset Group", type: "text" },
    { id: 12, title: "Purchase Date", type: "date" },
    { id: 13, title: "Placed in service date", type: "date" },
    { id: 14, title: "Warranty Expiration Date", type: "date" },
    { id: 15, title: "Tags", type: "text" },
    { id: 16, title: "Documents", type: "text" },
];

