import React, { useContext } from "react";
import GlobalContext from "../../../store/GlobalContext";
import { Select } from "antd";

const UserGroupSelect = ({ value, onChange, classes, disabled}) => {
  const { USER_GROUPS } = useContext(GlobalContext);

  return (
    <Select
      value={value}
      onChange={onChange}
      className={`w-full ${classes}`}
      disabled={disabled}
    >
      {!!USER_GROUPS&&
        Object.keys(USER_GROUPS)?.map((title, i) => (
          <Select.Option key={title} value={title}>
            {title}
          </Select.Option>
        ))}
    </Select>
  );
};

export default UserGroupSelect;
