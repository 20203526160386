import { addDoc, collection, getDoc, getDocs } from "firebase/firestore";
import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { fetchDashboards } from "../api/dashboard";
import { fetchAssetGroups, fetchAssets, fetchChecklists, fetchFolders, fetchLocations, fetchMaintenanceSchedules, fetchOrders, fetchSettings, fetchTags, fetchTenants, fetchUserGroups, fetchUsers, fetchVendors } from './global-actions';
import { auth, db } from "../firebase";
import { TABLES } from "./global-types";
import { getUserFromStore } from "../api/auth";
import { uppercase } from "../helpers/utils";

export const GlobalContext = createContext({
    ROLES: {},
    USERS: [],
    USER_GROUPS: {},
    PERMISSIONS: {},
    ASSETS: [],
    ASSET_GROUPS: [],
    assetsLoading: false
});


export const GlobalContextProvider = (props) => {

    const [roles, setRoles] = useState({});
    const [permissions, setPermissions] = useState({});
    const [assets, setAssets] = useState([]);
    const [maintenance, setMaintenance] = useState([]);
    const [assetGroups, setAssetGroups] = useState({});
    const [assetGroupDocId, setAssetGroupDocId] = useState('');
    const [userGroupDocId, setUserGroupDocId] = useState('');
    const [dashboards, setDashboards] = useState([]);
    const [userGroups, setUserGroups] = useState({});
    const [logs, setLogs] = useState({});
    const [organizations, setOrganizations] = useState([]);
    const [tags, setTags] = useState({});
    const [tenantId, setTenantId] = useState(null);
    const [tenants, setTenants] = useState([]);
    const [vendors,setVendors]=useState([]);
    const [checklists,setChecklists] = useState([]);
    const [folders,setFolders]=useState([]);
    const [orders,setOrders]=useState([]);
    const [tagModalOpen,setTagModalOpen]=useState(false);
    const [locations,setLocations]=useState([]);
    const [settings,setSettings]=useState({});
    const [loading, setLoading] = useState({
        assets: false,
        dashboards: false,
        users: false,
        maintenance: false
    });
    const [users, setUsers] = useState([]);

    const BASE_URL = `${TABLES.MAIN_TABLE}/${tenantId?.trim()}`;

    useEffect(() => {
        if (!!tenantId) return;
        const user = getUserFromStore();
        if (!user?.tenantId) return;
        setTenantId(user?.tenantId);
    }, [tenantId]);

    const fetchLogs = async () => {
        setLoading({ ...loading, logs: true });
        const log_dbs = [TABLES.ASSETS, TABLES.MAINTENANCE,TABLES.FAILURES,TABLES.INSPECTIONS,TABLES.LOCATIONS,TABLES.VENDORS,TABLES.TASKS];
        const data = {};
        for (const db_name of log_dbs) {
            const log_data = {};
            const snapshot = await getDocs(collection(db, `${BASE_URL}/logs/${db_name}/${db_name}_logs`));
            snapshot.forEach((doc) => {
                log_data[doc.id] = doc.data()?.logs?.length ? [ ...doc.data()?.logs ] : [];
            });
            data[db_name] = { ...log_data };
        }
        setLogs(data);
        setLoading({ ...loading, logs: false });
    }

    useEffect(()=>{
      if(!tenantId || folders.length) return;
      getFolders();
    },[tenantId]);

    const getFolders = async() =>{
        const fs = await fetchFolders(BASE_URL);
        if(fs.length) setFolders([...fs]);
    }

    useEffect(()=>{
        if(!tenantId || orders.length) return;
        getOrders();
      },[tenantId]);
  
      const getOrders = async() =>{
          await fetchOrders(BASE_URL,setOrders);
      }

    useEffect(() => {
        if (!tenantId || Object.keys(logs)?.length) return;
        fetchLogs();
    }, [tenantId]);

    useEffect(()=>{
    if(!tenantId || users?.length) return;
    getUsers();
    },[tenantId]);

    const getUsers = async () => {
        setLoading({ ...loading, users: true });
        const data = await fetchUsers(BASE_URL);
        setUsers(data);
        setLoading({ ...loading, users: true });
    }

    useEffect(()=>{
     if(!tenantId || locations?.length) return;
     getLocations();
    },[tenantId]);

    const getLocations = async() =>{
        const data = await fetchLocations(BASE_URL);
        setLocations(data);
    }

    useEffect(()=>{
        if(!tenantId || vendors?.length) return;
        getVendors();
       },[tenantId]);
   
    const getVendors = async() =>{
        const data = await fetchVendors(BASE_URL);
        setVendors(data);
    }
   
    useEffect(()=>{
        if(!tenantId)return;
        if(!checklists.length) getChecklists();
        if(!settings.id) getSettings();
       },[tenantId]);

    const getChecklists = async() =>{
        const data = await fetchChecklists(BASE_URL);
        setChecklists(data);
    }

    const getSettings = async()=>{
        const data = await fetchSettings(BASE_URL);
        setSettings({...data});
    }
   

    useEffect(()=>{
       if(users?.length && Object.keys(userGroups)?.length){
        const added_groups = users.map(u=>({
            ...u,
            userGroup:getUserGroupByUserId(u?.id)
        }));
        setUsers({...added_groups});
       }
    },[users?.length,Object.keys(userGroups)?.length]);


    useEffect(() => {
        if (tags?.length || !tenantId) return;
        getTags();
    }, [tenantId]);

    const getTags = async () => {
        const data = await fetchTags(BASE_URL);
        setTags({ ...data });
    }

    useEffect(() => {
        if (users?.length && Object.keys(userGroups)?.length) {
            setUsers([...users.map(d => ({ ...d, userGroup: getUserGroupByUserId(d?.id) }))]);
        }
    }, [users?.length, Object.keys(userGroups)?.length]);

    const getOrganizations = async () => {
        const snapshot = await getDocs(collection(db, "organizations"));
        const data = [];
        snapshot.forEach((doc) => {
            data.push({ id: doc.id, ...doc.data() });
        })
        setOrganizations(data);
    }

    useEffect(() => {
        getOrganizations();
    }, []);

    const getAssets = async () => {
        setLoading({ ...loading, assets: true });
        const data = await fetchAssets(BASE_URL);
        setAssets([...data]);
        setLoading({ ...loading, assets: false });
    }

    const getTenants = async () => {
        setLoading({ ...loading, tenants: true });
        const data = await fetchTenants();
        setTenants([...data]);
        setLoading({ ...loading, tenants: false });
    }

    useEffect(() => {
        getTenants();
    }, []);

    const getMaintenanceSchedules = async () => {
        const data = await fetchMaintenanceSchedules(BASE_URL);
        setMaintenance([...data]);
    }

    const fetchAndSetAllDashboards = async () => {
        const res = await fetchDashboards();
        if (res?.status === 200)
            setDashboards(res?.data?.data);
    }

    useEffect(() => {
        if (!tenantId || Object.keys(assetGroups)?.length || !assets?.length) return;
        getAssetGroups();
    }, [tenantId,assets?.length]);

    useEffect(() => {
        fetchAndSetAllDashboards();
    }, []);

    const getAssetGroups = async () => {
        const [id, data] = await fetchAssetGroups(BASE_URL,assets);
        setAssetGroups(data);
        setAssetGroupDocId(id);
    };

    useEffect(() => {
        if (assets?.length || !tenantId) return;
        getAssets();
    }, [tenantId]);

    useEffect(() => {
        if (maintenance?.length || !tenantId) return;
        getMaintenanceSchedules();
    }, [tenantId]);

    useEffect(() => {
        fetchRoles();
    }, []);

    const fetchRoles = async () => {
        const snapshot = await getDocs(collection(db, "roles"));
        snapshot.forEach((doc) => {
            setRoles({ ...doc.data() });
        });
    }

    useEffect(() => {
        if (!tenantId || Object.keys(userGroups)?.length || !users?.length) return;
        getUserGroups();
    }, [tenantId,users?.length]);

    const getUserGroups = async () => {
        const [id, data] = await fetchUserGroups(BASE_URL,users);
        setUserGroupDocId(id);
        setUserGroups({ ...data });
    }


    useEffect(async () => {
        const snapshot = await getDocs(collection(db, "permissions"));
        let data = {};
        snapshot.forEach((doc) => {
            data = { ...data, [doc.id]: doc.data() };
        });
        setPermissions({ ...data });
    }, []);


    //GETTERS

    const getAssetById = (id) => {
        const asset = assets.find(d => d?.id === id);
        if (!asset) return null;
        return asset;
    }

    const getUserById = (id) => {
        const user = users.find(d => d?.id === id);
        if (!user) return null;
        return user;
    }

    const getUserByUid = (uid) => {
        const user = users.find(d => d?.uid === uid);
        if (!user) return null;
        return user;
    }

    const getUserGroupByUserId = (id) => {
        for (const [key, { users }] of Object.entries(userGroups)) {
            if (users?.length && users?.includes(id)) {
                return key;
            }
        }
        return "N/A";
    }

    const getTagById = (id, table,type) => {
        if (!table) return null;
        const list = type ? getTagList(table,type) : getTagList(table);
        const tag = list.find(d => d?.id === id);
        if (!tag) return null;
        return tag;
    }

    const getLocationById = (id) =>{
        const loc = locations.find(d=>d?.id === id);
        if(loc) return loc;
        return null;
    }

    const getVendorById = (id) =>{
        const ven = vendors.find(d=>d?.id === id);
        if(ven) return ven;
        return null;
    }

    const getTenantById = (id) =>{
        const tenant = tenants.find(d=>d?.id === id);
        if(tenant) return tenant;
        return null;   
    }

    const getChecklistById = (id) =>{
        const checklist = checklists.find(d=>d?.id === id);
        if(checklist) return checklist;
        return null;
    }

    const getLogsByRowId = (table, id) => {
        const all_logs = logs[table];
        if (!all_logs) return [];
        const row_logs = all_logs[id];
        return row_logs;
    }

    const getTagList = useCallback((table,type) => {

        const all_tags = tags[uppercase(table)];
        if (!all_tags) return [];

        if(type){
            return !!all_tags[type] ? all_tags[type] : [];
        }

        let tag_list = [];
        for (const tgs of Object.values(all_tags)) {
            tag_list.push(...tgs);
        }

        return tag_list;
    }, [tags]);

    const fakeUpdateLogs = (log, row_id, table) => {
        if (!row_id || !log) return;
        const allLogs = { ...logs };
        if (!allLogs[table]) {
            allLogs[table] = {
                [row_id]: []
            }
        } else if (!allLogs[table][row_id]) {
            allLogs[table][row_id] = [];
        }
        allLogs[table][row_id] = [ ...allLogs[table][row_id], log ];
        setLogs({ ...allLogs });
    }

    const amIAdmin = useMemo(() =>{
        if(!users || !users?.length) return;
        const admins = users.filter(u=>u?.userGroup === "Admin");
        const amAdmin = admins.some(a=>a?.uid === auth.currentUser?.uid);
        return amAdmin;   
    },[users]);

    const amIApproved = useMemo(()=>{
      if(!users || !users?.length) return;
      const user = getUserByUid(auth.currentUser?.uid);
      if(user?.approvedByAdmin===true || amIAdmin)
       return true;
       return false;
    },[users]);

    const context = {
        ROLES: roles,
        USERS: users,
        PERMISSIONS: permissions,
        ASSETS: assets,
        LOGS: logs,
        TAGS: tags,
        TENANTS: tenants,
        tenantId,
        setTenantId,
        BASE_URL,
        ASSETGROUP_DOC_ID: assetGroupDocId,
        USERGROUP_DOC_ID: userGroupDocId,
        ORGANIZATIONS: organizations,
        assetsLoading: loading.assets === true,
        dashboardsLoading: loading?.dashboards,
        usersLoading: loading.users === true,
        maintenanceLoading: loading.maintenance === true,
        ASSET_GROUPS: assetGroups,
        USER_GROUPS: userGroups,
        DASHBOARDS: dashboards,
        MAINTENANCE: maintenance,
        LOCATIONS:locations,
        VENDORS:vendors,
        CHECKLISTS:checklists,
        SETTINGS:settings,
        FOLDERS:folders,
        ORDERS:orders,
        getAssets,
        getMaintenanceSchedules,
        getLocations,
        getVendors,
        getFolders,
        getOrders,
        getAssetGroups,
        getAssetById,
        getUserById,
        getUserByUid,
        setAssets,
        setAssetGroups,
        setUsers,
        setUserGroups,
        setLogs,
        setTags,
        getTags,
        getChecklists,
        getSettings,
        getUserGroupByUserId,
        getTagById,
        getLocationById,
        getVendorById,
        getTenantById,
        getChecklistById,
        getLogsByRowId,
        getTagList,
        fakeUpdateLogs,
        tagModalOpen,
        setTagModalOpen,
        amIAdmin,
        amIApproved
    };


    return (
        <GlobalContext.Provider value={context}>
            {props.children}
        </GlobalContext.Provider>
    )
}


export default GlobalContext