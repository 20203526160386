import { EditFilled } from "@ant-design/icons";
import { Col, Divider, Row, Spin } from "antd";
import { doc, setDoc } from "firebase/firestore";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { Draggable } from "react-beautiful-dnd";
import { Droppable } from "react-beautiful-dnd";
import Tag from "../../../components/tag";
import StatBox from "../../../components/UI/StatBox";
import { db } from "../../../firebase";
import { dragArrayTransform, ShowNotification } from "../../../helpers/utils";
import { NotificationType } from "../../../helpers/validators";
import { TABLES } from "../../../store/global-types";
import GlobalContext from "../../../store/GlobalContext";
import AddAssetGroupModal from "./AddAssetGroupModal";

const AssetGroups = ({
  modalOpen,
  setModalOpen,
  setAssetModalOpen,
  setEditData,
  assets,
}) => {
  const [assetGroups, setAssetGroups] = useState({});
  const [loading, setLoading] = useState(false);
  const [editGroupData, setEditGroupData] = useState(null);
  const {
    ASSET_GROUPS,
    ASSETS,
    getAssetById,
    setAssetGroups: setAGs,
    ASSETGROUP_DOC_ID,
    BASE_URL,
    setAssets,
  } = useContext(GlobalContext);

  useEffect(() => {
    if (!ASSET_GROUPS || Object.keys(assetGroups)?.length) return;
    setAssetGroups(ASSET_GROUPS);
  }, [ASSET_GROUPS]);

  const min_length =
    useMemo(
      () =>
        Math.max(...Object.values(ASSET_GROUPS).map((value) => value?.length)),
      []
    ) + 1;

  const onAssetDragEnd = async (result) => {
    setLoading(true);
    const { destination, source, draggableId } = result;
    if (!destination || !source || !draggableId) return;
    let allAssetGroups = { ...assetGroups };
    allAssetGroups[source.droppableId] = allAssetGroups[
      source?.droppableId
    ].filter((d) => d !== draggableId);
    allAssetGroups[destination.droppableId].push(draggableId);
    setAssetGroups({ ...allAssetGroups });
    setAGs({ ...allAssetGroups });
    await setDoc(
      doc(db, `${BASE_URL}/${TABLES.ASSET_GROUPS}`, ASSETGROUP_DOC_ID),
      allAssetGroups,
      { merge: true }
    );
    await setDoc(
      doc(db, `${BASE_URL}/${TABLES.ASSETS}`, draggableId),
      { 11: destination.droppableId },
      { merge: true }
    );
    setAssets((prev) => {
      const all_assets = [...prev];
      const index = all_assets.findIndex((d) => d?.id === draggableId);
      if (index > -1) {
        all_assets[index][11] = destination.droppableId;
      }
      return all_assets;
    });
    setLoading(false);
  };

  const addGroup = async (name) => {
    if (!name) return;
    setLoading(true);
    await setDoc(
      doc(db, `${BASE_URL}/${TABLES.ASSET_GROUPS}`, ASSETGROUP_DOC_ID),
      { [name]: [] },
      { merge: true }
    );
    setAGs({ ...assetGroups, [name]: [] });
    setAssetGroups({ ...assetGroups, [name]: [] });
    setLoading(false);
  };

  const unassigned_length = ASSET_GROUPS["Unassigned"]?.length || "0";
  const assigned_length = ASSETS?.length - +unassigned_length || "0";

  const onTagClick = (data) => {
    setEditData(data);
    setAssetModalOpen(true);
  };

  const onClickEditGroup = (data) => {
    setEditGroupData(data);
    setModalOpen(true);
  };

  const onGroupModalClose = () => {
    setEditGroupData(null);
    setModalOpen(false);
  };

  const editGroupName = async (oldName, newName) => {
    if (!!ASSET_GROUPS[newName] && !!oldName) {
      ShowNotification(
        "Error!",
        "Group with this name already exists!",
        NotificationType[1]
      );
      return;
    }
    if (ASSET_GROUPS[oldName]?.length && !newName) {
      ShowNotification(
        "Error!",
        "Can't delete a group with even one asset assigned!",
        NotificationType[1]
      );
      return;
    }
    setLoading(true);
    const all_groups = { ...ASSET_GROUPS };
    if (newName) all_groups[newName] = [...ASSET_GROUPS[oldName]];
    delete all_groups[oldName];
    await setDoc(
      doc(db, `${BASE_URL}/${TABLES.ASSET_GROUPS}`, ASSETGROUP_DOC_ID),
      all_groups
    );
    setAGs({ ...all_groups });
    setAssetGroups({ ...all_groups });
    setLoading(false);
  };

  return (
    <Spin spinning={loading}>
      <Divider />

      <Row className="mt-20 ml-10" gutter={[16, 16]} justify="start">
        {[
          { title: "Total Assets", value: assets?.length },
          {
            title: "Asset Groups",
            value:
              Object.keys(ASSET_GROUPS)?.length - 1 >= 0
                ? Object.keys(ASSET_GROUPS)?.length - 1
                : "0",
          },
          { title: "Assigned", value: assigned_length },
          { title: "Unassigned", value: unassigned_length },
        ].map(({ title, value }, i) => (
          <Col xs={12} lg={6}>
            <StatBox title={title} value={value} key={i} />
          </Col>
        ))}
      </Row>
      <Divider />

      <DragDropContext onDragEnd={onAssetDragEnd}>
        <div className="permissions__table mt-40">
          {!!assetGroups
            ? Object?.entries(assetGroups)?.map(([title, assets], index) => (
                <Droppable droppableId={title}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="permission__col"
                    >
                      <h3 className="fw-600 pt-10 fs-18">
                        {title}
                        {title !== "Unassigned" && (
                          <EditFilled
                            className="ml-10 cursor-pointer"
                            onClick={() => onClickEditGroup(title)}
                          />
                        )}
                      </h3>
                      {dragArrayTransform(assets, min_length).map(
                        (assetId, i) => (
                          <div
                            style={{ height: 60 }}
                            className="permission__box flex items-center justify-center"
                          >
                            {
                              <Draggable
                                key={assetId}
                                draggableId={assetId}
                                index={i}
                              >
                                {(provided, snapshot) => {
                                  const asset = getAssetById(assetId);
                                  return (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      {asset ? (
                                        <Tag
                                          onClick={() => onTagClick(asset)}
                                          classes="fs-16"
                                          color={
                                            title === "Unassigned"
                                              ? "#f6eda6"
                                              : "#02f8174d"
                                          }
                                        >
                                          {asset[2]}
                                        </Tag>
                                      ) : null}
                                    </div>
                                  );
                                }}
                              </Draggable>
                            }
                          </div>
                        )
                      )}
                    </div>
                  )}
                </Droppable>
              ))
            : null}
        </div>
      </DragDropContext>
      <AddAssetGroupModal
        open={modalOpen}
        onClose={onGroupModalClose}
        addData={addGroup}
        editData={editGroupData}
        editGroupName={editGroupName}
      />
    </Spin>
  );
};

export default AssetGroups;
