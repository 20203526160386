import {
  ArrowLeftOutlined,
  BackwardFilled,
  StopOutlined,
} from "@ant-design/icons";
import { Input, Spin } from "antd";
import { getDownloadURL, getMetadata, listAll, ref } from "firebase/storage";
import React, { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Image from "../../../components/UI/Image";
import { storage } from "../../../firebase";
import GlobalContext from "../../../store/GlobalContext";
import pdf from "../../../assets/png/pdf.png";
import AddNewButton from "../../../components/buttons/AddNewButton";
import { onDocUpload } from "../../../store/global-actions";
import { ShowNotification } from "../../../helpers/utils";
import { NotificationType } from "../../../helpers/validators";
import FilePreviewWindow from "./FilePreviewWindow";

const FileList = () => {
  const { folder } = useParams();
  const { BASE_URL } = useContext(GlobalContext);
  const [files, setFiles] = useState([]);
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!BASE_URL) return;
    getFiles();
  }, [BASE_URL]);

  const getFiles = async () => {
    const listRef = ref(
      storage,
      `${BASE_URL}/${folder?.split("-")?.join(" ")}`
    );

    try {
      const { prefixes: fo, items: fi } = await listAll(listRef);
      if (!fo.length && !fi.length) {
        setLoading(false);
        return;
      }
      let all_files = [];
      if (fo.length) {
        fo.forEach(async (foRef) => {
          const { prefixes: fo1, items: fi1 } = await listAll(foRef);
          fi1.forEach(async (ref, i) => {
            const url = await getDownloadURL(ref);
            const md = await getMetadata(ref);
            all_files = [...all_files, { url, type: md?.contentType }];
            if (i === fi1.length - 1) {
              setLoading(false);
              setFiles([...all_files]);
            }
          });
        });
      }
      if (fi.length > 0) {
        fi.forEach(async (ref, i) => {
          const url = await getDownloadURL(ref);
          const md = await getMetadata(ref);
          all_files = [...all_files, { url, type: md?.contentType }];
          if (all_files.length === fi.length) {
            setFiles([...all_files]);
            setLoading(false);
          }
        });
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const onUploadClick = () => {
    if (folder === "assets" || folder === "maintenance") {
      ShowNotification(
        "Error",
        "Can't upload file to system folder!",
        NotificationType[1]
      );
      return;
    }
    document.getElementById("upload").click();
  };

  const onFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    if (file?.size > 1200000) {
      ShowNotification(
        "Error",
        "file size is too big, keep it less then 1MB",
        NotificationType[1]
      );
      return;
    }
    setLoading(true);
    await onDocUpload(
      file,
      null,
      BASE_URL,
      folder?.split("-")?.join(" "),
      null,
      true
    );
    await getFiles();
    setLoading(false);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <Spin spinning={loading} className="container">
      <div className="flex items-center w-full justify-between mt-20 mb-20">
        <div className="flex items-center">
          <ArrowLeftOutlined
            className="c-primary mr-10 fs-26 opacity-50"
            onClick={() => navigate(-1)}
          />
          <h1 className="mt-10">File List</h1>
        </div>
        <AddNewButton onClick={onUploadClick}>Add File</AddNewButton>
      </div>
      <Input type="file" id="upload" onChange={onFileChange} hidden />
      <div className="mt-20 flex items-center flex-wrap">
        {!loading && !files.length ? (
          <p className="opacity-50 mx-auto fs-18 mt-30">
            <StopOutlined /> No Files Found
          </p>
        ) : null}
        {files.map(({ url, type }, i) => (
          <div className="mr-30 mb-20 h-120 w-120 text-center" key={i}>
            {type.includes("image") ? (
              <Image src={url} readOnly big />
            ) : (
              <img
                onClick={() => setFile(url)}
                height="120px"
                src={
                  type.includes("pdf")
                    ? pdf
                    : "https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/e1651152-091d-4296-bfa8-81e1a3b59df4/d8xwimi-8af65cb7-1aab-42f4-b0c7-8d61e920d9c6.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2UxNjUxMTUyLTA5MWQtNDI5Ni1iZmE4LTgxZTFhM2I1OWRmNFwvZDh4d2ltaS04YWY2NWNiNy0xYWFiLTQyZjQtYjBjNy04ZDYxZTkyMGQ5YzYucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.LVwD4ABAfA7FVAPYqo2QUmtLLotg5De2Iu_oqXIJjws"
                }
                alt="pdf"
                className="mx-auto cursor-pointer"
              />
            )}
          </div>
        ))}
      </div>
      <FilePreviewWindow open={file} onClose={() => setFile()} />
    </Spin>
  );
};

export default FileList;
