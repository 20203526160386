import React, { useState } from 'react'
import { useContext } from 'react'
import GlobalContext from '../../store/GlobalContext'
import { HexColorPicker } from "react-colorful";
import { Button, Input, Modal, Select, Spin } from 'antd';
import { TAG_CATS, TAG_TYPES } from '../../helpers/Constants';
import { ShowNotification } from '../../helpers/utils';
import { NotificationType } from '../../helpers/validators';
import { checkFirebaseAuth } from '../../api/auth';
import { addDoc, collection } from 'firebase/firestore';
import { TABLES } from '../../store/global-types';
import { db } from '../../firebase';
import moment  from 'moment';

const interpolate = (a, b, c, d) => `${a}/${b}/${c}/${d}`;

const AddTagModal = ({category}) => {
    const {tagModalOpen,setTagModalOpen,BASE_URL,getTags} =useContext(GlobalContext);
    const [tag,setTag]=useState({});
    const [tagType,setTagType]=useState(null);
    const [loading,setLoading]=useState(false);

    const onClose = () =>{
      setTag({});
      setTagType(null);
      setTagModalOpen(false);
    }

    const onSubmitAddTag = async () => {
      const { title, description } = tag;
      if (!title || !description || !tagType){
          ShowNotification("Error","Please enter full details!",NotificationType[1]);
          return;
      }
      const user = checkFirebaseAuth();
      if (!user) return;
      tag.createdBy = user.email;
      tag.createdOn = moment().format("DD-MM-YYYY hh:mm A");
      setLoading(true);
      await addDoc(collection(db, interpolate(BASE_URL, TABLES.TAGS, TAG_CATS[category],tagType)), tag);
      await getTags();
      setLoading(false);
      onClose();
  }

    const changeTag = (field, e) => {
      const value = e?.target?.value ? e?.target?.value : (typeof e==="string" && e);
      setTag({ ...tag, [field]: value });
  }
    
  return (
    <Modal
            title={false}
            footer={false}
            visible={tagModalOpen}
            width={370}
            centered
            onCancel={onClose}
        >
            <h1>Add {TAG_CATS[category]} Tag</h1>
            <p className='mt-10 fw-600'>Tag Type</p>
            <Select className="w-full" value={tagType} onChange={val=>setTagType(val)}>
           {
            TAG_TYPES.map(type=>(
              <Select.Option key={type} value={type}>{type}</Select.Option>
            ))
           }
            </Select>
            <p className='mt-10 fw-600'>Title</p>
            <Input value={tag.title} onChange={e => changeTag("title", e)} />
            <p className='mt-10 fw-600'>Color</p>
            <HexColorPicker color={tag.color} onChange={(e) => changeTag("color", e)} prefixed />
            <p className='mt-10 fw-600'>Description</p>
            <Input value={tag.description} onChange={e => changeTag("description", e)} />
            <div className='cancelSave__buttons'>
                <Button primary>Cancel</Button>
                <Button type="primary" primary onClick={onSubmitAddTag}>{loading ? <Spin/> : "Save"}</Button>

            </div>
        </Modal>
  )
}

export default AddTagModal