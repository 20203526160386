import axios from "axios"
import { API_BASE_URL } from "../helpers/Constants"
import { SecureCall, ShowNotification } from "../helpers/utils"
import { NotificationType } from "../helpers/validators"
import { KEYS } from "./end-points";

export const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-Authorization': `Bearer ${localStorage.getItem('sm_token')}`
}


export const GetKeys = async (key, startTs, endTs, limit = 900) => {
    if (!key || !startTs || !endTs) {
        ShowNotification("Error!", "Provide all the necessary field!", NotificationType[1]);
        return;
    }
    const call = async () => {
        const res = await axios.get(`${API_BASE_URL}${KEYS}limit=${limit}&agg=NONE&orderBy=DESC&useStrictDataTypes=false&keys=${key},active_energy_import_grid&startTs=${startTs}&endTs=${endTs}`, { headers });
        return res;
    }
    const onSuccess = (res) => { }
    const response = await SecureCall(call, onSuccess, "Successfully fetched the Data");
    return response;

}