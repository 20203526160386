import {  Row, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import TableManipulator from '../../../components/table/TableManipulator';
import GlobalContext from '../../../store/GlobalContext';
import { MAINENANCE_COLUMNS } from '../maintenance-data';
import { TABLES } from '../../../store/global-types';
import GreenTick from '../../../components/icons/GreenTick';
import RedCross from '../../../components/icons/RedCross';
import AntdTable from '../../../components/table/AntdTable';
import { getFreqTitle } from '../../../components/selects/FrequencySelect';
import Tag from '../../../components/tag';
import { getPriorityTitle,getPriorityColor } from '../../../components/selects/PrioritySelect';
import { VendorRow } from '../../vendors/components/VendorsMainPage';
import UploadImage from '../../../components/UI/Upload';
import { EventTypeCol } from '../../../components/selects/EventTypeSelect';
import MaintenanceFilters from './MaintenanceFilters';
import moment from 'moment';
import TagTableCell from '../../tags/components/TagTableCell';
import { TAG_TYPES } from '../../../helpers/Constants';

const MaintenanceTable = ({ setAddScheduleModalOpen, loading, selectedRows, setSelectedRows, setEditData, schedules, setSchedules }) => {
    const { MAINTENANCE, getUserById, getAssetById,getChecklistById, maintenanceLoading ,getVendorById,getTagById} = useContext(GlobalContext);
    const [maintenanceColumns, setMaintenanceColumns] = useState(MAINENANCE_COLUMNS);
    const [view, setView] = useState(0);

    //OPEN EDIT MODAL
    const onRowClick = (data) => {
        setEditData(data);
        setAddScheduleModalOpen(true);
    }
    
    //ON ROW SELECT
    const handleRowSelect = (rows) => setSelectedRows(rows);

    const MAINTENANCE_ANTD_COLS = maintenanceColumns.map((col)=>{
        return{
          ...col,
          render: (text,record) => 
          col?.type==="file" ? 
          <UploadImage files={text} readOnly/> :
          col?.id === "checklists" ? (!!text ? Object.keys(text).map(id=>getChecklistById(id)?.title || null).map(d=> <Tag classes="m-1" key={d}>{d}</Tag>) : 'N/A'):
          (col?.id === 11 || col?.id === 10)? <p className='text-nowrap'>{getUserById(text)?.displayName || "N/A"}</p>:
          col?.id === 8 ? (text !== undefined && getPriorityTitle(text) ? <Tag color={getPriorityColor(text)} classes="m-1">{getPriorityTitle(text)} </Tag> : <p className='text-center'>{"-"}</p>) :
          col?.id === 1 ? <p className='text-nowrap'>{getAssetById(text) ? getAssetById(text)[2] : "N/A"}</p> :
          col?.id === 15 || col?.id ===22 ? (text==true ? <GreenTick classes="mx-auto"/> : <RedCross classes="mx-auto"/>) : 
          col?.id === 16 ? <p className='text-center'>{getFreqTitle(text)}</p> : 
          col?.id === 14 ? <VendorRow getter={getVendorById} value={text}/>: 
          col?.id === 17 ? <p className='text-center'>{record[15]===true ? text : "-"}</p> : 
          col?.id === 18 ? <EventTypeCol text={text}/>:
          col?.id === 'tags' ? <TagTableCell value={text} table={TABLES.MAINTENANCE} type={TAG_TYPES[1]}/> :
          (col?.id === 20 || col?.id === 21) ? <p className='text-center'>{moment(record[col?.id === 20 ? 5 : 19]).diff(moment(record[4]),"days")}</p>:
          col?.id === 3 ? <p className='tracking-tight w-150'>{text}</p> :
          col?.id === 'docs' ? <p className='text-center'>{Array.isArray(text) ? text?.length : '0'}</p> :
          (col?.id === 4 || col?.id ==5 || col?.id === 19) ? <p className='text-nowrap text-center'>{text ? text : '-'}</p>:
          <p className={`${col?.id === 2 && 'fw-600 w-150'}`}>{text}</p>
        }
      });

    return (
        <Spin spinning={loading === true || maintenanceLoading === true}>
            <TableManipulator allColumns={MAINENANCE_COLUMNS} columns={maintenanceColumns} data={MAINTENANCE} filteredData={schedules} setColumns={setMaintenanceColumns} setFilteredData={setSchedules} setView={setView} table={TABLES.MAINTENANCE} />
            <MaintenanceFilters setEvents={setSchedules} EVENTS={MAINTENANCE}/>
            {view === 0 && <AntdTable data={schedules} columns={MAINTENANCE_ANTD_COLS} bordered onRowSelect={handleRowSelect} onRowClick={onRowClick} size="small" />}
            {
                view == 1 &&
                <Row gutter={[24, 16]} justify="center" className="mt-20">
                    {schedules.map((schedule, i) => <ScheduleCard schedule={schedule} key={schedule?.id} />)}
                </Row>
            }
        </Spin>
    )
}

export default MaintenanceTable

const ScheduleCard = ({ schedule }) => {
    return (
        <>
        </>
        // <Col xs={22} lg={8} xl={6}>
        //     <Card
        //         hoverable
        //         style={{ boxShadow: "1px 2px 6px  #eee" }}
        //     // cover={<div style={{ backgroundColor: "#20b494", height: 20 }}></div>}
        //     >
        //         <img src={user?.photoURL} height={100} width="100%" className='mb-20 object-contain' />
        //         <h3 className='fw-600'>{user?.displayName}</h3>
        //         <p className='opacity-50'>{user?.email}</p>
        //         <p>Account created {moment(+user?.createdAt).fromNow()}</p>
        //         <p><b>User Group:</b> {user?.userGroup}</p>
        //         <p className='mt-10'>{user?.emailVerified ? <div className='flex items-center'><GreenTick /> <p className='ml-10'>Verified User</p></div> : "User Unverified"}</p>
        //     </Card>
        // </Col >
    )
}