import { Modal } from 'antd'
import React from 'react'

const ConfirmationModal = ({ visible, onClose, onOk, title }) => {

    const onCancel = () => {
        onClose();
    }
    return (
        <Modal
            onCancel={onCancel}
            onOk={onOk}
            visible={visible}
            centered
            width={400}
        >
            <h2>Are you Sure?</h2>
            {!!title && <p className='fw-500'>{title}</p>}

        </Modal>
    )
}

export default ConfirmationModal