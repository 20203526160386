import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import cx from 'classnames';
import React from 'react'

const AddNewButton = ({ children, onClick, classes ,icon}) => {
    return (
        <Button onClick={onClick} type="primary" className={cx(classes, 'addNew__btn', 'button')}>{!icon ? <PlusOutlined /> : icon} {children}</Button>
    )
}

export default AddNewButton