import { Checkbox } from 'antd'
import React, { useContext, useState } from 'react'
import Paginator, { ITEMS_PER_PAGE } from '../../../components/paginator'
import AntdTable from '../../../components/table/AntdTable'
import TableManipulator from '../../../components/table/TableManipulator'
import Tag from '../../../components/tag'
import { getFreqTitle } from '../../../components/selects/FrequencySelect'
import { TABLES } from '../../../store/global-types'
import GlobalContext from '../../../store/GlobalContext'
import { ASSET_TABLE_COLUMS } from '../asset-data'
import GallaryView from './GallaryView'
import { split } from '../../../helpers/utils'
import { VendorRow } from '../../vendors/components/VendorsMainPage'
import UploadImage from '../../../components/UI/Upload'
import AssetFilters from './AssetFilters'
import TagTableCell from '../../tags/components/TagTableCell'
import { TAG_TYPES } from '../../../helpers/Constants'

const Assets = ({ assets, setModalOpen, setSelectedRows, filteredAssetData, setFilteredData, setEditData }) => {
    const [assetColumns, setAssetColumns] = useState(ASSET_TABLE_COLUMS);
    const [view, setView] = useState(0);
    const { getTagById,getLocationById,getVendorById} = useContext(GlobalContext);

    //OPEN EDIT MODAL
    const onRowClick = (data) => {
        setEditData(data);
        setModalOpen(true);
    }

    //GET THE TAG TITLE FROM THE LIST OF TAG IDS
    const getTagString = (tagIdString) => {
        const tagIds = tagIdString?.split(",");
        if (!tagIds?.length) return [];
        const tagTitles = tagIds.map(id => {
            const tag = getTagById(id, TABLES.ASSETS);
            if (tag) return tag?.title;
        });
        if (!tagTitles?.length) return [];
        return tagTitles.filter(d=>!!d);
    }

    //ON ROW SELECT
    const handleRowSelect = (rows) => setSelectedRows(rows);

    const ASSETS_ANTD_COLS = assetColumns.map((col)=>{
        return{
          ...col,
          render: (text) => 
          col?.id === 5 ? <UploadImage files={text} readOnly/>:
          col?.id === 6 ? <p className='w-200'>{text}</p>:
          col?.id === 16 ? <p className='text-center'>{Array.isArray(text) ? text?.length : '0'}</p> :
          (col?.id === 15 || col?.id === 9) ? <TagTableCell value={text} table={TABLES.ASSETS} type={col?.id === 9 ? TAG_TYPES[1] : false}/> :
          col?.id === 10 ? (getLocationById(text) ? getLocationById(text).locationTitle : "N/A") :
          col?.id === 3 ? <VendorRow getter={getVendorById} value={text}/> : text
        }
      });

    return (
        <>
            <TableManipulator
                data={assets}
                setFilteredData={setFilteredData}
                filteredData={filteredAssetData}
                allColumns={ASSET_TABLE_COLUMS}
                columns={assetColumns}
                setColumns={setAssetColumns}
                tagId={15}
                table={TABLES.ASSETS}
                setView={setView}
            />
            <AssetFilters  ASSETS={assets} setAssets={setFilteredData} />
            {view === 0 ? <AntdTable data={filteredAssetData} columns={ASSETS_ANTD_COLS} bordered onRowSelect={handleRowSelect} onRowClick={onRowClick} size="small"/>:
            <GallaryView data={filteredAssetData} columns={ASSET_TABLE_COLUMS} tagString={getTagString} />}
        </>
    )
}

export default Assets

