import { Input, Modal } from 'antd'
import React from 'react'
import { useState } from 'react'
import Button from '../../../components/buttons';

const InviteUserModal = ({open,onClose,onInvite}) => {

const [email,setEmail]=useState('');

const onModalClose= () =>{
    setEmail('');
    onClose();
}

const handleSubmit = async() =>{
  await onInvite(email);
  onModalClose();
}

  return (
   <Modal
        title={false}
        footer={false}
        visible={open}
        width={"min(100%,400px)"}
        centered
        onCancel={onModalClose}
    >
    <h2 className='fw-600'>Invite new User</h2>

    <p className='fw-600 fs-14'>Enter Email</p>
    <Input className='w-full' value={email} onChange={(e) => setEmail(e.target.value)} />
    
    <Button onClick={handleSubmit} classes='w-full mt-20' type="primary" >Invite</Button>
           
  </Modal>
  )
}

export default InviteUserModal