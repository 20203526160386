import React from 'react'
import CheckListsMainPage from './components/CheckListsMainPage'
import './checklists.scss';

const Checklists = () => {
  return (
    <div  className='container'>
    <CheckListsMainPage/>
    </div>
  )
}

export default Checklists