import { CheckSquareOutlined, CopyOutlined, DeleteFilled, SnippetsTwoTone } from "@ant-design/icons";
import { Col } from "antd";
import moment from "moment";
import React, { useContext } from "react";
import GlobalContext from "../../../store/GlobalContext";
import images from "../checklist-data";

const ChecklistCard = ({ checklist, selected, onClick, modal,onChecklistDelete,copy,attach }) => {
  const { getUserById } = useContext(GlobalContext);
  const { title, tasks, createdBy, createdOn, id } = checklist;

  const onClickDelete = e=>{
    e.preventDefault();
    e.stopPropagation();
    onChecklistDelete(id);
  }
  const onClickCopy = e=>{
    e.preventDefault();
    e.stopPropagation();
    copy({title,tasks});
  }

  return (
    <div
      className={`checklist__card ${selected && "selected"} ${
        modal && "modal"
      }`}
      onClick={() => onClick(id, checklist)}
    >
      <div className="w-full">
        <div className="flex justify-between">
          <div className="flex">
            <SnippetsTwoTone style={{fontSize:28,marginTop:2}}/>
            <h3 className="tracking-wide ml-1">{title}</h3>
          </div>
          {!modal && !attach && <div className="flex items-center" style={{marginTop:-10}}>
            <CopyOutlined onClick={onClickCopy} style={{ fontSize: 22, color: "#00a0e4" }} className="mr-1"/>
            <DeleteFilled onClick={onClickDelete} style={{ fontSize: 22, color: "#f07070" }} />
          </div>}
        </div>
        <div className="mt-10">
          {!modal
            ? tasks
                .slice(0, 3)
                .map((t, i) => <Task task={t} key={i} modal={modal} />)
            : tasks.map((t, i) => <Task task={t} key={i} modal={modal} />)}
        </div>
      </div>
      <div className="mt-1 flex justify-between">
        <div>
          {tasks.length > 3 && !modal && (
            <p className="fw-600 ml-10 ">+ {tasks.length - 3} more tasks</p>
          )}
        </div>
        <div>
          <p className="tag  green">
            Created By {getUserById(createdBy)?.displayName || "Unknown"}
          </p>
          <p className="tag red" style={{ marginTop: 7 }}>
            Created On {moment(new Date(createdOn)).format("DD MMMM, YYYY")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ChecklistCard;

export const Task = ({ task, modal }) => {
  return (
    <div className={`flex border border-b ${!modal && "items-center"} mt-1`}>
      <CheckSquareOutlined className={`${modal && "mt-1"}`} />
      <p className={`task ml-10 tracking-wide ${!modal && "text-truncate w-300"}`}>
        {task.title}
      </p>
    </div>
  );
};
