import React from 'react'

const OrganizationDetails = ({signUpMode,setSignUpMode,onSubmit,orgName,setOrgName}) => {
  return (
    <>
    <p className='mt-50'>Please enter the name of Organization Name</p>
    <input placeholder={signUpMode ? "Enter you new organization name" : 'Customer 1'} value={orgName} onChange={e=>setOrgName(e.target.value)} />
    <p className='fw-600 mt-40 cursor-pointer' onClick={()=>setSignUpMode(!signUpMode)}>{signUpMode ? "Sign In instead?" : "Create a new Organization?"}</p>
    <button
      className='mt-50'
      onClick={onSubmit}>{signUpMode ? "Create Organization" : "Submit"}</button>
  </>
  )
}

export default OrganizationDetails