export const TABLES = {
    ASSETS: "assets",
    MAINTENANCE: "maintenance",
    TENANTS: "tenants",
    MAIN_TABLE: "smartUdyog",
    ASSET_GROUPS: "assetGroups",
    USER_GROUPS: "userGroups",
    USERS: "users",
    TAGS: "tags",
    LOCATIONS:"locations",
    VENDORS:"vendors",
    CHECKLISTS:"checklists",
    SETTINGS:'settings',
    FOLDERS:'folders',
    ORDERS:'orders',
    SUBORDERS:'subOrders'
}