import React, { useContext } from "react";
import GlobalContext from "../../../store/GlobalContext";
import { Select } from "antd";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { TABLES } from "../../../store/global-types";

const AssetGroupSelect = ({ value, onChange, classes, disabled, assetId,placeholder }) => {
  const { ASSET_GROUPS, BASE_URL, ASSETGROUP_DOC_ID, getAssetGroups } =
    useContext(GlobalContext);

  const onAssetGroupChange = async (val) => {
    if (assetId) {
      let allAssetGroups = { ...ASSET_GROUPS };
      let lastGroup;
      for (const group of Object.entries(allAssetGroups)) {
        const [key, values] = group;
        if (values.includes(assetId)) {
          lastGroup = key;
        }
      }
      if (lastGroup) {
        allAssetGroups[lastGroup] = allAssetGroups[lastGroup].filter(
          (d) => d !== assetId
        );
        allAssetGroups[val].push(assetId);
        await setDoc(
          doc(db, `${BASE_URL}/${TABLES.ASSET_GROUPS}`, ASSETGROUP_DOC_ID),
          allAssetGroups,
          { merge: true }
        );
        await getAssetGroups();
        onChange(val);
      } else {
        onChange(val);
      }
    } else {
      onChange(val);
    }
  };

  return (
    <Select
      value={value}
      onChange={(val) => onAssetGroupChange(val)}
      className={`w-full ${classes}`}
      disabled={disabled}
      placeholder={placeholder}
    >
      {!!ASSET_GROUPS &&
        Object.keys(ASSET_GROUPS)?.map((title, i) => (
          <Select.Option key={title} value={title}>
            {title}
          </Select.Option>
        ))}
    </Select>
  );
};

export default AssetGroupSelect;
