import green_tick from '../../assets/png/tick_green.png';
import checklist from '../../assets/png/checklist.png';
import cross_red from '../../assets/png/cross_red.png';
import cross_green from '../../assets/png/cross_green.png';
import add_new from '../../assets/png/add_new.png';
import pause from '../../assets/png/pause.png';
import pause_red from '../../assets/png/pause_red.png';
import add from '../../assets/png/add.png';
import add2 from '../../assets/png/add2.png';
import tick from '../../assets/png/tick.png';
import tick2 from '../../assets/png/tick2.png';
import tick3 from '../../assets/png/tick3.png';



export const CHECKLIST_COLS = [
  { title: "Title", id: "title", placeholder: "enter checklist title" ,type:"text"},
  { id: "tasks", title: "Tasks",type:"text" },
  { id: "createdBy", title: "Created By", type: "text" },
  { id: "createdOn", title: "Created On", type: "date" },
  { id: "actions",title:"Actions",type:"text"}
];

export const TASK_COLS = [
    {id:"title",title:"Title",type:"text"},
    {id:"status",title:"Status",type:"text"}
];


export default {pause,pause_red,add_new,green_tick,cross_green,cross_red,checklist,add,add2,tick,tick2,tick3};
