import { Checkbox, Spin, Switch, Tabs } from "antd";
import { addDoc, collection } from "firebase/firestore";
import React, { useContext, useState } from "react";
import { checkFirebaseAuth } from "../../../api/auth";
import TabSwitchButton from "../../../components/buttons/TabSwitchButton";
import Tag from "../../../components/tag";
import { db } from "../../../firebase";
import { TAG_CATS, TAG_TYPES } from "../../../helpers/Constants";
import { TABLES } from "../../../store/global-types";
import GlobalContext from "../../../store/GlobalContext";
import AddTagModal from "./AddTagModal";
import moment from "moment";
import { deleteTableRows } from "../../../store/global-actions";
import Button from "../../../components/buttons";
import AddNewButton from "../../../components/buttons/AddNewButton";
import { ShowNotification } from "../../../helpers/utils";
import { NotificationType } from "../../../helpers/validators";
import AntdTable from "../../../components/table/AntdTable";
import { BookOutlined, ControlOutlined, UserOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;

const interpolate = (a, b, c, d) => `${a}/${b}/${c}/${d}`;

const TagsMainPage = () => {
  const [tagModalOpen, setTagModalOpen] = useState(false);
  const { TAGS, getTags, BASE_URL } = useContext(GlobalContext);
  const [tab, setTab] = useState(0);
  const [tagType, setTagType] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const initialize = () => {
    setLoading(false);
    setSelectedTags([]);
  };

  const onTagModalClose = () => setTagModalOpen(false);

  const onSubmitAddTag = async (tag) => {
    const { title, description } = tag;
    if (!title || !description) {
      ShowNotification(
        "Error",
        "Please enter full details!",
        NotificationType[1]
      );
      return;
    }
    const user = checkFirebaseAuth();
    if (!user) return;
    tag.createdBy = user.email;
    tag.createdOn = moment().format("DD-MM-YYYY hh:mm A");
    setLoading(true);
    await addDoc(
      collection(
        db,
        interpolate(BASE_URL, TABLES.TAGS, TAG_CATS[tab], TAG_TYPES[tagType])
      ),
      tag
    );
    await getTags();
    setLoading(false);
  };

  //ON ROW SELECT
  const handleRowSelect = (rows) => setSelectedTags(rows);

  const onDeleteRows = async () => {
    setLoading(true);
    await deleteTableRows(
      selectedTags,
      TAG_TYPES[tagType],
      async () => {
        await getTags();
        initialize();
      },
      () => {
        initialize();
      },
      `${BASE_URL}/${TABLES.TAGS}/${TAG_CATS[tab]}`,
      false
    );
  };

  const ANTD_TAG_COLS = [
    {
      id: "title",
      title: "Title",
      render: (text, record) => <Tag color={record?.color}>{text}</Tag>,
    },
    {
      id: "description",
      title: "Description",
      render: (text) => <p className="fw-500">{text}</p>,
    },
    {
      id: "createdBy",
      title: "Created By",
    },
    {
      id: "createdOn",
      title: "Craeted On",
      render: (text) => <p className="fw-500">{text}</p>,
    },
    // {
    //     id:"status",
    //     title:"Status",
    //     render:()=><Switch checked={true} className="ml-10"/>
    // }
  ];

  const category_icons = {
    0: <BookOutlined />,
    1: <ControlOutlined />,
    2: <UserOutlined />,
  };

  return (
    <Spin spinning={loading}>
      <AddTagModal
        open={tagModalOpen}
        onClose={onTagModalClose}
        onSubmit={onSubmitAddTag}
      />
      <div
        className="flex w-full items-center justify-between mb-20"
        style={{ overflowX: "auto" }}
      >
        <div className="flex mr-2">
          {TAG_CATS.map((t, i) => (
            <TabSwitchButton
              classes=""
              selected={i === tab}
              key={i}
              onClick={() => setTab(i)}
            >
              {category_icons[i]} {t}
            </TabSwitchButton>
          ))}
        </div>
        <div>
          {!!selectedTags?.length && (
            <Button
              onClick={onDeleteRows}
              type="primary"
              classes="danger mr-10"
            >
              Delete Rows
            </Button>
          )}
          <AddNewButton
            type="primary"
            primary
            onClick={() => setTagModalOpen(true)}
          >
            Add new Tag
          </AddNewButton>
        </div>
      </div>
      {/* <Tabs className='mt-20' defaultActiveKey={0} onChange={setTagType} size="large" tabBarGutter={40} style={{ fontWeight: 600 }}>
                {
                    TAG_TYPES.map((t, i) => (
                        <TabPane tab={t} key={i}>
                            <AntdTable data={!!TAGS[TAG_CATS[tab]] ? TAGS[TAG_CATS[tab]][TAG_TYPES[tagType]] : []} columns={ANTD_TAG_COLS} onRowSelect={handleRowSelect}/>
                        </TabPane>
                    ))
                }
            </Tabs> */}
      <AntdTable
        data={
          !!TAGS[TAG_CATS[tab]] ? TAGS[TAG_CATS[tab]][TAG_TYPES[tagType]] : []
        }
        columns={ANTD_TAG_COLS}
        onRowSelect={handleRowSelect}
      />
    </Spin>
  );
};

export default TagsMainPage;
