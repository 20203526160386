import React from 'react';
import styles from './common.module.scss';

const UnauthorizedView = ({}) => {
  return (
    <div className={styles.unauthorizedContainer}>
       <h2>UnAuthorized</h2>
       <img src="https://media.istockphoto.com/vectors/shield-with-hand-block-icon-in-flat-style-with-shadow-vector-id867726980?k=20&m=867726980&s=612x612&w=0&h=ge5fg-rTBLy_OVAyhCiawo4Ou9bHcIQ9WtVORH20N8U=" alt="unauth"/>
       <p>You need to get approved by the admin to access the content!</p>
    </div>
  )
}

export default UnauthorizedView