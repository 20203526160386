import { Button } from 'antd'
import React from 'react'

const ModalFooter = ({onSubmit,editMode,onClose}) => {
  return (
    <div className='w-full flex-reverse items-center mt-20'>
    <Button onClick={onSubmit} type="primary" >{editMode ? "Save" : "Add"}</Button>
    <Button className="mr-10" type="secondary" onClick={onClose}>Cancel</Button>
  </div>
  )
}

export default ModalFooter