import { Avatar } from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import './profile.scss';

const MyProfile = () => {

    const isFbUser = localStorage.getItem('isFbUser');
    const [user, setUser] = useState(null);

    useEffect(() => {
        if (isFbUser !== 'true') return;
        const string_user = localStorage.getItem('user');
        const parsed_user = JSON.parse(string_user);
        setUser(parsed_user);
    }, [isFbUser]);

    return (
        <div className='profile'>
            {!!user &&
                <div className='profile__card'>
                    <div className='flex w-full justify-between  items-center my-20'>
                        <h1>My Profile</h1>
                        <Avatar src={user?.photoURL} size="large" />

                    </div>
                    <KeyValue title="Name" value={user?.displayName} />
                    <KeyValue title="Email" value={user?.email} />
                    <KeyValue title="Verified User" value={user?.emailVerified ? "Yes" : "No"} />
                    <KeyValue title="Account Created" value={moment(new Date(+user?.createdAt)).format('DD MMM YYYY, hh:mm A')} />
                    <KeyValue title="Last Login" value={moment(new Date(+user?.lastLoginAt)).format('DD MMM YYYY, hh:mm A')} />
                    <KeyValue title="User Id" value={user?.uid} />

                </div>
            }
        </div>
    )
}

export default MyProfile

const KeyValue = ({ title, value }) => {
    return (
        <div className='flex w-full justify-between fw-600 items-center mt-10'>
            <p className='opacity-50'>{title}</p>
            <p>{value}</p>
        </div>
    )
}