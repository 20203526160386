import { Input, Modal } from 'antd';
import React, { useState } from 'react'
import { useEffect } from 'react';
import Button from '../../../components/buttons';

const AddAssetGroupModal = ({ open, onClose, addData, editData ,editGroupName}) => {

    const [assetGroupName, setAssetGroupName] = useState('');

    const handleSubmit = async () => {
        if(editData){
            await editGroupName(editData,assetGroupName);
        }else{
        await addData(assetGroupName);
        }
        setAssetGroupName('');
        onClose();
    }

    const handleDelete = async()=>{
        await editGroupName(assetGroupName);
        setAssetGroupName('');
        onClose();
    }

    useEffect(() => {
        if (!editData) return;
        setAssetGroupName(editData);
    }, [editData]);

    const onModalClose = () => {
        setAssetGroupName("");
        onClose();
    }

    return (
        <Modal
            title={false}
            footer={false}
            visible={open}
            width={"min(100%,400px)"}
            centered
            onCancel={onModalClose}
        >
            <h2 className='fw-600'>{editData ? "Edit" : "Add New"}  Asset Group</h2>

            <p className='fw-600 fs-14'>Asset Group Name</p>
            <Input className='w-full' value={assetGroupName} onChange={(e) => setAssetGroupName(e.target.value)} />
            <Button onClick={handleSubmit} classes='w-full mt-20 h-35' type="primary" >{editData ? "Save" : "Add Asset Group"}</Button>
            {editData && <Button classes='w-full mt-20 danger h-35' onClick={handleDelete}>Delete Group</Button>}
        </Modal >
    )
}

export default AddAssetGroupModal