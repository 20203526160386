import { Spin } from 'antd';
import React, { useContext } from 'react';
import './react-big-calendar.css';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { getFormattedEvents } from '../maintenance-data';
import { getPriorityColor } from '../../../components/selects/PrioritySelect';

const localizer = momentLocalizer(moment);


const MaintenanceCalender = ({ setEditData, schedules, setAddScheduleModalOpen, loading }) => {


    const formatted_events = getFormattedEvents(schedules);

    const onClickEvent = (event) => {
        const { id } = event;
        if (!id) return;
        const schedule = schedules.find(d => d?.id === id);
        if (!schedule) return;
        setEditData(schedule);
        setAddScheduleModalOpen(true);
    }

    const getEventStyles = (ev) =>{
        let style = {backgroundColor:"#1164a3"}
        const {id} = ev;
        const sch = schedules.find(d=>d?.id === id);
        if(!sch) return {style};
        const color = getPriorityColor(sch[8]);
        if(color) style.backgroundColor=color;
        return{style};
    }

    return (
        <Spin spinning={loading} className="w-full">
            <Calendar
                localizer={localizer}
                startAccessor="start"
                events={formatted_events}
                endAccessor="end"
                className='mt-20'
                style={{ height: "75vh",minHeight:"75vh" }}
                onSelectEvent={ev => onClickEvent(ev)}
                selectable
                popup={true}
                showMultiDayTimes={true}
                eventPropGetter={getEventStyles}
            />
        </Spin>
    )
}

export default MaintenanceCalender