import React, { useContext } from 'react'
import GlobalContext from '../../../store/GlobalContext';
import {Select} from 'antd';

const LocationsSelect = ({value,onChange,classes,disabled}) => {

const {LOCATIONS} = useContext(GlobalContext);

  return (
    <Select
        value={value}
        onChange={val => onChange(val)}
        className={`w-full ${classes}`}
        disabled={disabled}
    >
        {LOCATIONS?.length ?
            LOCATIONS?.map((item, i) => (
                <Select.Option key={item?.id} value={item?.id}>
                    {item?.locationTitle}
                </Select.Option>
            )) :
            <Select.Option disabled={true}>
            No Location Added
           </Select.Option> 
        }
    </Select>
  )
}

export default LocationsSelect
