import React, { useState } from 'react'
import { useEffect } from 'react';
import { useContext } from 'react';
import Tag from '../../../components/tag';
import GlobalContext from '../../../store/GlobalContext';

const TagTableCell = ({value,table,type}) => {
const [tags,setTags]=useState([]);

const {getTagById,TAGS} = useContext(GlobalContext);

useEffect(()=>{
  if(!TAGS || !Object.keys(TAGS)?.length) return;
  const tagsArr = getTagString(value);
  setTags([...tagsArr]);
},[value,TAGS,type]);

//GET THE TAG TITLE FROM THE LIST OF TAG IDS
const getTagString = (tagIdString) => {
    const tagIds = tagIdString?.split(",");
    if (!tagIds?.length) return [];
    const tagTitles = tagIds.map(id => {
        const tag = getTagById(id,table);
        if (tag) return tag?.title;
    });
    if (!tagTitles?.length) return [];
    return tagTitles.filter(d=>!!d);
}
  return (
    (tags.length ? 
       tags.map(tag => 
       <Tag key={tag} classes="m-1">{tag} </Tag>) : 
    <p className='text-center'>{"-"}</p>)
  )
}

export default TagTableCell