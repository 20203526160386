import { addDoc, collection, doc, setDoc ,arrayUnion} from '@firebase/firestore';
import { Row, Spin } from 'antd';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { checkFirebaseAuth, getUserFromStore } from '../../api/auth';
import { logActivity, LOG_TABLES, LOG_TYPES } from '../../api/logs';
import Button from '../../components/buttons';
import AddNewButton from '../../components/buttons/AddNewButton';
import TabSwitchButton from '../../components/buttons/TabSwitchButton';
import AddTagModal from '../../components/modals/AddTagModal';
import UnauthorizedView from '../../components/UI/UnauthorizedView';
import { db, storage } from '../../firebase';
import { ShowNotification } from '../../helpers/utils';
import { NotificationType } from '../../helpers/validators';
import { deleteTableRows, onDocUpload } from '../../store/global-actions';
import { TABLES } from '../../store/global-types';
import GlobalContext from '../../store/GlobalContext';
import "./assetRegister.scss";
import AddAssetModal from './components/AddAssetModal';
import AssetGroups from './components/AssetGroups';
import { v4 as uuidv4 } from 'uuid';
import Assets from './components/Assets';
import { AppstoreOutlined, ControlOutlined } from '@ant-design/icons';


const AssetRegister = () => {


    const [assetModalOpen, setAssetModalOpen] = useState(false);
    const [assetGroupModalOpen, setAssetGroupModalOpen] = useState(false);
    const [tabSelected, setTabSelected] = useState(0);
    const [selectedRows, setSelectedRows] = useState([]);
    const [filteredAssetData, setFilteredData] = useState([]);
    const [editData, setEditData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [comment, setComment] = useState('');
    const user = getUserFromStore();

    const { ASSETS, assetsLoading, getAssets, fakeUpdateLogs, LOGS, BASE_URL,setTagModalOpen,amIApproved } = useContext(GlobalContext);

    useEffect(() => {
        if (!ASSETS || !ASSETS?.length) return;
        setFilteredData(ASSETS);
    }, [ASSETS?.length]);


    useEffect(() => {
        if (ASSETS?.length) return;
        setLoading(true);
        const subscribe = setTimeout(() => {
            setLoading(false);
        }, 3000);
        return () => clearTimeout(subscribe);
    }, []);


    if(amIApproved===false){
        return <UnauthorizedView/>
    }

    const initialize = () => {
        setLoading(false);
        setSelectedRows([]);
    }

    const onDeleteRows = async () => {
        setLoading(true);
        await deleteTableRows(
            selectedRows,
            TABLES.ASSETS,
            () => {
                getAssets();
                initialize();
            },
            () => {
                initialize();
            }, BASE_URL);
    }

    //ADD OR EDIT DATA ON DATABASE
    const addAssetData = async (data, updatedColumns) => {

        const auth = checkFirebaseAuth(true);
        if (!auth || !data) return;

        setLoading(true);
        try {
            const table = `${BASE_URL}/${TABLES.ASSETS}`;
            if (data?.id) {
                if (!updatedColumns?.length)
                    throw Error("No columns to update");
                const doc_id = data?.id;
                delete data.id;
                await setDoc(doc(db, table, doc_id), { ...data }, { merge: true });
                const what = `${updatedColumns?.join(", ")}`;
                const log = await logActivity(LOG_TABLES.ASSETS, user?.email, what, LOG_TYPES.UPDATED, doc_id, BASE_URL);
                fakeUpdateLogs(log, doc_id, TABLES.ASSETS);
                const index = filteredAssetData.findIndex(d => d?.id === doc_id);
                filteredAssetData[index] = { ...data, id: doc_id };
                setFilteredData([...filteredAssetData]);
            } else {
                await addDoc(collection(db, table), { ...data });
                // const log = await logActivity(table, user?.email, "a new asset", LOG_TYPES.ADDED);
                // fakeUpdateLogs(log);
                await getAssets();
            }
            setLoading(false);
        } catch (err) {
            ShowNotification("Error!", err?.message || "something went wrong!", NotificationType[1]);
            setLoading(false);
        }
    }


    //CLOSE MODAL
    const onModalClose = () => {
        setAssetModalOpen(false);
        setEditData(null);
    }

    //ADD A COMMENT TO THE TABLE ROW
    const addComment = async (id) => {
        if (!comment?.length) return;

        const auth = checkFirebaseAuth(true);
        if (!auth) return;

        setLoading(true);
        const log = await logActivity(LOG_TABLES?.ASSETS, user?.email, comment, LOG_TYPES?.COMMENTED, id, BASE_URL);
        fakeUpdateLogs(log, id, TABLES.ASSETS);
        setComment("");
        setLoading(false);
    }

    const onFileChange = (e) =>{
        const file = e.target.files[0];
       if(!file) return;
        if(file?.size > 1000000){
            ShowNotification('Error',"file size is too big, keep it less then 1MB",NotificationType[1]);
            return;
        }

        const auth = checkFirebaseAuth(true);
        if (!auth) return;

        const id = editData?.id;
        if(!id) return;

        setLoading(true);
        setAssetModalOpen(false);

        onDocUpload(file,id,BASE_URL,TABLES.ASSETS,16);
    }

    const onClickAddTag = (e) =>{
        setTagModalOpen(true);
      }

    return (
        <div className=' container'>
            <Spin spinning={loading || assetsLoading} className='assetRegister'>
                <h1>Asset Register</h1>
                    <Row justify='space-between' align='center' className='mt-10  container' gutter={[0, 14]}>
                    <div className='flex items-center'>
                        <TabSwitchButton selected={tabSelected === 0} onClick={() => setTabSelected(0)} classes="small"><ControlOutlined style={{fontSize:22}}/></TabSwitchButton>
                        <TabSwitchButton selected={tabSelected === 1} onClick={() => setTabSelected(1)} classes="small"><AppstoreOutlined style={{fontSize:22}}/></TabSwitchButton>
                    </div>
                    <div className='flex items-center'>
                        {!!selectedRows?.length && <Button onClick={onDeleteRows} type="primary" classes="danger mr-10">Delete Rows</Button>}
                        <AddNewButton onClick={() => setAssetModalOpen(true)} >New Asset</AddNewButton>
                        {tabSelected === 1 && <AddNewButton classes="ml-10" onClick={() => setAssetGroupModalOpen(true)}>New Asset Group</AddNewButton>}
                    </div>
                </Row>
                {tabSelected === 0
                    ? < Assets assets={ASSETS} setModalOpen={setAssetModalOpen} selectedRows={selectedRows} setSelectedRows={setSelectedRows} filteredAssetData={filteredAssetData} setFilteredData={setFilteredData} setEditData={setEditData} />
                    : <AssetGroups modalOpen={assetGroupModalOpen} setModalOpen={setAssetGroupModalOpen} setAssetModalOpen={setAssetModalOpen} setEditData={setEditData} assets={ASSETS} />
                }
                 <AddAssetModal open={assetModalOpen} onClose={onModalClose} addData={addAssetData} editData={editData} comment={comment} addComment={addComment} setComment={setComment} onClickAddTag={onClickAddTag} onFileChange={onFileChange}/>
                 <AddTagModal category={1}/>
            </Spin>
        </div>
    )
}

export default AssetRegister

