import React, { useState } from "react";
import { SUB_ORDER_COLS } from "../orders-data";
import {  Col, Input } from "antd";
import ModalFooter from "../../../components/UI/ModalFooter";
import AssetGroupSelect from "../../assetRegister/components/AssetGroupSelect";
import AssetsSelect from "../../assetRegister/components/AssetsSelect";
import TabSwitchButton from "../../../components/buttons/TabSwitchButton";
import DateTimePicker from "../../../components/UI/DateTimePicker";

const CreateSuborderForm = ({ backToAllJobs,onSubmit }) => {
  const [data, setData] = useState({});

  const onChange = (e, id, isEvent = true) => {
    const value = isEvent ? e.target.value : e;
    data[id] = value;
    setData({ ...data });
  };

  const handleSubmit = async()=>{
    await onSubmit(data);
    backToAllJobs();
  }

  return (
    <div className="">
      <TabSwitchButton onClick={backToAllJobs}>
        Back To All Jobs
      </TabSwitchButton>
      <h1 className="mb-20 mt-10">New Job</h1>
      {SUB_ORDER_COLS.map(({ title, id, placeholder, type }) => (
        <Col xs={24} key={id}>
          <p className="fw-600 mt-20">{title}</p>
          {id === "assetGroup" ? (
            <AssetGroupSelect placeholder={placeholder} value={data[id]} onChange={val=>onChange(val,id,false)}/>
          ) : id === "asset" ? (
            <AssetsSelect disabled={!data.assetGroup} groupFilter={data.assetGroup} placeholder={placeholder} value={data[id]} onChange={val=>onChange(val,id,false)} />
          ) : type === "date" ? (
            <DateTimePicker value={data[id]} onChange={val=>onChange(val,id,false)}/>
          ) : (
            <Input
              value={data[id]}
              placeholder={placeholder}
              type={type}
              onChange={(e) => onChange(e, id)}
            />
          )}
        </Col>
      ))}
      <ModalFooter editMode={false} onSubmit={handleSubmit} onClose={backToAllJobs} />
    </div>
  );
};

export default CreateSuborderForm;

