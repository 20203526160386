import { Select, Spin } from "antd";
import React, { useContext, useEffect, useMemo, useState } from "react";
import Iframe from "react-iframe";
import { useLocation } from "react-router-dom";
import UnauthorizedView from "../../components/UI/UnauthorizedView";
import { API_BASE_URL } from "../../helpers/Constants";
import GlobalContext from "../../store/GlobalContext";
import "./production.css";

const MachineUtilization = () => {
  const [currDashboard, setCurrDashboard] = useState(0);
  const [loading, setLoading] = useState(false);
  const { DASHBOARDS, amIApproved, SETTINGS } = useContext(GlobalContext);
  const [locUrl, setLocUrl] = useState(null);
  const { search } = useLocation();

  useEffect(() => {
    setLoading(true);
    const unsubscribe = setTimeout(() => setLoading(false), 4000);
    return()=> clearTimeout(unsubscribe);
  }, []);

  useEffect(() => {
    // const array = search.split('=');
    // if(array[0]==='?dId'){
    //     const dId = array[1];
    //     if (!dId || !dId?.length) return;
    //     const index = DASHBOARDS.findIndex(d => d?.id?.id === dId);
    //     if (index > -1) setCurrDashboard(index);
    // }
    const isLive = window.location.pathname.includes("performance");
    if (SETTINGS.portals?.length) {
      if (isLive) {
        setLocUrl(SETTINGS.portals[0]?.url);
      } else {
        setLocUrl(SETTINGS.portals[1]?.url);
      }
    }
    // if(array[0]==='?url'){
    //     const url = array[1];
    //     setLocUrl(url);
    // }}else{
    //     if(SETTINGS.portals?.length){
    //         window.location.replace(`/performance?url=${SETTINGS.portals[0]?.url}`);
    //     }
  }, [SETTINGS, window.location.pathname]);

  const iFrameUrl = useMemo(() => {
    if (!DASHBOARDS) return "";
    if (locUrl) return locUrl;
    // return `${API_BASE_URL}customers/${DASHBOARDS[currDashboard]?.assignedCustomers[0]?.customerId?.id}/dashboards/${DASHBOARDS[currDashboard]?.id?.id}`;
  }, [DASHBOARDS, locUrl]);

  if (amIApproved === false) {
    return <UnauthorizedView />;
  }

  return (
    <Spin className="machine-utilization" spinning={loading}>
      <Iframe
        url={iFrameUrl}
        width="100%"
        height="900vh"
        className="iframe"
        display="initial"
        position="relative"
      />
    </Spin>
  );
};

export default MachineUtilization;
