import './App.scss';
import Routes from './pages/Routes';
import 'antd/dist/antd.css';
import { GlobalContextProvider } from './store/GlobalContext';

function App() {
  return (
    <GlobalContextProvider>
      <div className="app">
        <Routes />
      </div>
    </GlobalContextProvider>
  );
}

export default App;
