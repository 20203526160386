import { Row, Spin } from 'antd'
import { addDoc, collection, doc, setDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { useContext } from 'react';
import { checkFirebaseAuth, getUserFromStore } from '../../../api/auth';
import { logActivity, LOG_TYPES } from '../../../api/logs';
import Button from '../../../components/buttons';
import AddNewButton from '../../../components/buttons/AddNewButton';
import { db } from '../../../firebase';
import { ShowNotification } from '../../../helpers/utils';
import { NotificationType } from '../../../helpers/validators';
import { deleteTableRows } from '../../../store/global-actions';
import { TABLES } from '../../../store/global-types';
import GlobalContext from '../../../store/GlobalContext';
import AddLocationModal from './AddLocationModal';
import LocationsTable from './LocationsTable';
import moment from 'moment';

const LocationsMainPage = () => {
  const {BASE_URL,LOCATIONS,getLocations, fakeUpdateLogs} = useContext(GlobalContext);

  const [loading,setLoading]=useState(false);
  const [locModalOpen,setLocModalOpen]=useState(false);
  const [comment,setComment]=useState("");
  const [locations,setLocations]=useState([]);
  const [editData,setEditData]=useState(null);
  const [selectedRows,setSelectedRows]=useState([]);

  useEffect(()=>{
   if(!LOCATIONS || !LOCATIONS?.length) return;
   setLocations(LOCATIONS);
  },[LOCATIONS]);

  const initialize = () => {
    setLoading(false);
    setSelectedRows([]);
}

  const onLocModalClose = () =>{
    setLocModalOpen(false);
    setEditData(null);
  }

  const user = getUserFromStore();
  const onRowSelect = (rows) =>setSelectedRows(rows);

   //OPEN EDIT MODAL
   const onRowClick = (data) => {
    setEditData(data);
    setLocModalOpen(true);
   }

  //ADD OR EDIT DATA ON DATABASE
  const addLocationData = async (data, updatedColumns) => {

    const auth = checkFirebaseAuth(true);
    if (!auth || !data) return;

    setLoading(true);
    try {
        const table = `${BASE_URL}/${TABLES.LOCATIONS}`;
        if (data?.id) {
            if (!updatedColumns?.length)
                throw Error("No columns to update");
            const doc_id = data?.id;
            delete data.id;
            await setDoc(doc(db, table, doc_id), { ...data }, { merge: true });
            const what = `${updatedColumns?.join(", ")}`;
            const log = await logActivity(TABLES.LOCATIONS, user?.email, what, LOG_TYPES.UPDATED, doc_id, BASE_URL);
            fakeUpdateLogs(log, doc_id, TABLES.LOCATIONS);
            const index = locations.findIndex(d => d?.id === doc_id);
            locations[index] = { ...data, id: doc_id };
            setLocations([...locations]);
        } else {
            await addDoc(collection(db, table), { ...data,
                createdBy: user.uid,
                createdOn: moment(new Date()).format("YYYY-MM-DD"),
            });
            // const log = await logActivity(table, user?.email, "a new location", LOG_TYPES.ADDED);
            // fakeUpdateLogs(log);
            await getLocations();
        }
        setLoading(false);
    } catch (err) {
        ShowNotification("Error!", err?.message || "something went wrong!", NotificationType[1]);
        setLoading(false);
    }
}


//ADD A COMMENT TO THE TABLE ROW
const addComment = async (id) => {
    if (!comment?.length) return;

    const auth = checkFirebaseAuth(true);
    if (!auth) return;

    setLoading(true);
    const log = await logActivity(TABLES?.LOCATIONS, user?.email, comment, LOG_TYPES?.COMMENTED, id, BASE_URL);
    fakeUpdateLogs(log, id, TABLES.LOCATIONS);
    setComment("");
    setLoading(false);
}

//DELETE ROWS
const onDeleteRows = async () => {
    setLoading(true);
    await deleteTableRows(
        selectedRows,
        TABLES.LOCATIONS,
        () => {
            getLocations();
            initialize();
        },
        () => {
            initialize();
        }, BASE_URL);
}

  return (
    <Spin spinning={loading} >
    <Row align='center' justify='space-between' gutter={[0, 10]} className="mt-20">
         <h1>Locations</h1>
        <div className='flex items-center'>
        {!!selectedRows?.length && <Button onClick={onDeleteRows} type="primary" classes="danger mr-10">Delete Rows</Button>}
        <AddNewButton onClick={()=>setLocModalOpen(true)}>Add Location</AddNewButton>
        </div>
    </Row>
    <LocationsTable locations={locations} setLocations={setLocations} LOCATIONS={LOCATIONS} onRowClick={onRowClick} onRowSelect={onRowSelect}/>
    <AddLocationModal open={locModalOpen} onClose={onLocModalClose} comment={comment} setComment={setComment} addComment={addComment} addData={addLocationData} editData={editData} />
</Spin>
  )
}

export default LocationsMainPage