import { EditFilled } from "@ant-design/icons";
import { Col, Divider, Row, Spin } from "antd";
import { doc, setDoc } from "firebase/firestore";
import React, { useContext, useMemo, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { DragDropContext } from "react-beautiful-dnd";
import { Droppable } from "react-beautiful-dnd";
import Avatar from "../../../components/avatar";
import StatBox from "../../../components/UI/StatBox";
import { db } from "../../../firebase";
import { dragArrayTransform, ShowNotification } from "../../../helpers/utils";
import { NotificationType } from "../../../helpers/validators";
import { TABLES } from "../../../store/global-types";
import GlobalContext from "../../../store/GlobalContext";
import AddUserGroupModal from "./AddUserGroupModal";

const UserGroups = ({ modalOpen, onModalClose, setModalOpen }) => {
  const [loading, setLoading] = useState(false);
  const [editGroupData, setEditGroupData] = useState(null);

  const {
    USERS,
    USER_GROUPS,
    getUserById,
    setUserGroups,
    BASE_URL,
    USERGROUP_DOC_ID,
  } = useContext(GlobalContext);

  const unassigned_length = USER_GROUPS["Unassigned"]?.users?.length || "0";
  const assigned_length = USERS?.length - +unassigned_length || "0";
  const min_length =
    useMemo(
      () =>
        Math.max(
          ...Object.values(USER_GROUPS).map((value) =>
            value?.users ? value.users?.length : 0
          )
        ),
      [USER_GROUPS]
    ) + 1;

  const addGroup = async (name) => {
    if (!name) return;
    setLoading(true);
    await setDoc(
      doc(db, `${BASE_URL}/${TABLES.USER_GROUPS}`, USERGROUP_DOC_ID),
      { [name]: { users: [] } },
      { merge: true }
    );
    setUserGroups({ ...USER_GROUPS, [name]: { users: [] } });
    setLoading(false);
  };

  const onDragEnd = async (result) => {
    setLoading(true);
    const { destination, source, draggableId } = result;
    let allUserGroups = { ...USER_GROUPS };
    allUserGroups[source.droppableId].users = allUserGroups[
      source?.droppableId
    ].users.filter((d) => d !== draggableId);
    if (allUserGroups[destination.droppableId]?.users) {
      allUserGroups[destination.droppableId].users.push(draggableId);
    } else {
      allUserGroups[destination.droppableId] = {
        ...allUserGroups[destination?.droppableId],
        users: [draggableId],
      };
    }
    setUserGroups({ ...allUserGroups });
    await setDoc(
      doc(db, `${BASE_URL}/${TABLES.USER_GROUPS}`, USERGROUP_DOC_ID),
      { ...allUserGroups },
      { merge: true }
    );
    setLoading(false);
  };

  const editGroupName = async (oldName, newName) => {
    if (!!USER_GROUPS[newName] && !!oldName) {
      ShowNotification(
        "Error!",
        "Group with this name already exists!",
        NotificationType[1]
      );
      return;
    }
    if (USER_GROUPS[oldName]?.length && !newName) {
      ShowNotification(
        "Error!",
        "Can't delete a group with even one asset assigned!",
        NotificationType[1]
      );
      return;
    }
    setLoading(true);
    const all_groups = { ...USER_GROUPS };
    if (newName) all_groups[newName] = { ...USER_GROUPS[oldName] };
    delete all_groups[oldName];
    await setDoc(
      doc(db, `${BASE_URL}/${TABLES.USER_GROUPS}`, USERGROUP_DOC_ID),
      all_groups
    );
    setUserGroups({ ...all_groups });
    setLoading(false);
  };

  const onClickEditGroup = (data) => {
    setEditGroupData(data);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    onModalClose();
    setEditGroupData(null);
  };

  const isTitleUnEditable = (title) =>
    title === "Unassigned" || title === "Admin";

  return (
    <Spin spinning={loading}>
      <Divider />

      <Row className="mt-20 ml-10" gutter={[8, 8]} justify="start">
        {[
          { title: "Total Users", value: USERS?.length },
          {
            title: "User Groups",
            value: Object.keys(USER_GROUPS)?.length || "0",
          },
          { title: "Assigned", value: assigned_length },
          { title: "Unassigned", value: unassigned_length },
        ].map(({ title, value }, i) => (
          <Col xs={12} md={6} key={i}>
            <StatBox title={title} value={value} />
          </Col>
        ))}
      </Row>
      <Divider />
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="permissions__table mt-20">
          {USER_GROUPS &&
            Object.entries(USER_GROUPS).map(([title, value], index) => (
              <Droppable droppableId={title}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className="permission__col"
                  >
                    <h3 className="fw-600 pt-10 fs-18">
                      {title}
                      {!isTitleUnEditable(title) && (
                        <EditFilled
                          className="ml-10 cursor-pointer"
                          onClick={() => onClickEditGroup(title)}
                        />
                      )}
                    </h3>
                    {dragArrayTransform(value?.users, min_length).map(
                      (userId, index) => (
                        <div className="permission__box">
                          <Draggable
                            key={userId}
                            draggableId={userId}
                            index={index}
                          >
                            {(provided, snapshot) => {
                              const user = getUserById(userId);
                              return (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className="flex items-center"
                                >
                                  <Avatar name={user?.displayName} />
                                  <div className="ml-10">
                                    <p className="fw-600">
                                      {user?.displayName}
                                    </p>
                                    <p className="opacity-70 w-150 text-truncate">
                                      {user?.email}
                                    </p>
                                  </div>
                                </div>
                              );
                            }}
                          </Draggable>
                        </div>
                      )
                    )}
                  </div>
                )}
              </Droppable>
            ))}
        </div>
      </DragDropContext>
      <AddUserGroupModal
        open={modalOpen}
        onClose={handleModalClose}
        addData={addGroup}
        editData={editGroupData}
        editGroupName={editGroupName}
      />
    </Spin>
  );
};

export default UserGroups;
