import { EVENT_TYPE } from "../../../helpers/Constants";
import moment from "moment";

const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const lastSixMonths = () => {
  const first = moment().subtract(5, "months").format("MMM");
  let index = MONTHS.indexOf(first);
  let months = MONTHS.slice(index, index + 6);
  if (index >= 7) {
    months = [...months, ...MONTHS.splice(0, index - 6)];
  }
  return months;
};

const checkDateEquality = (date, month) => {
  return (
    moment(new Date(date)).format("MMM YYYY") ===
    `${month} ${moment().format("YYYY")}`
  );
};

export const getActivityDistribution = (activities) => {
  let labels = [];
  let data = [];
  for (const type of Object.entries(EVENT_TYPE)) {
    const [key, value] = type;
    const length = activities.filter((d) => d[18] == key).length;
    labels.push(value);
    data.push(length);
  }
  return { labels, data };
};

export const userActivityDistribution = (activities, users,type) => {
  let labels = [];
  let data = [];
  for (const user of users) {
    labels = [...labels, user.displayName];
    const total_activities = activities.filter(
      (a) =>   (a[10] === user.uid) && (type === undefined ? true : a[18]===type)
    ).length;
    data = [...data, total_activities];
  }
  return { labels, data };
};

export const getUsersOverTime = (users) => {
  const labels = MONTHS;
  let data = [];
  for (const m of labels) {
    const length = users.filter((d) =>
      checkDateEquality(+d.createdAt, m)
    ).length;
    data = [...data, length];
  }
  return { labels, data };
};

export const getOrderStatsOverTime = (orders) => {
  const labels = MONTHS;
  let data = [];
  for (const m of labels) {
    const length = orders.filter(
      (d) => checkDateEquality(d.start, m)
    ).length;
    data = [...data, length];
  }
  return { labels, data };
};

export const getSubOrderStatsOverTime = (orders) => {
  const labels = MONTHS;
  let data = [];
  let subOrders = [];
  for (const o of orders) {
    subOrders = [...subOrders, ...o.subOrders];
  }
  for (const m of labels) {
    const length = subOrders.filter(
      (d) => checkDateEquality(d.start, m)
    ).length;
    data = [...data, length];
  }
  return { labels, data };
};

export const getMachinesOverTime = (machines) => {
  const labels = MONTHS;
  let data = [];
  for (const m of labels) {
    const length = machines.filter(
      (d) => checkDateEquality(d[12], m)
    ).length;
    data = [...data, length];
  }
  return { labels, data };
};

export const totalCountWithSpan = (key, data, span) => {
  if (!data || !data.length) return 0;
  if (span === "all_time") {
    return data?.length;
  } else if (span === "this_month") {
    return data.filter((d) =>
      moment(new Date(d[key])).isSame(new Date(), "month")
    ).length;
  } else if (span === "this_week") {
    return data.filter((d) =>
      moment(new Date(d[key])).isSame(new Date(), "week")
    ).length;
  } else if (span === "last_month") {
    return data.filter((d) =>
      moment(new Date(d[key])).isSame(
        moment(new Date()).subtract(1, "month"),
        "month"
      )
    ).length;
  } else if (span === "last_week") {
    return data.filter((d) =>
      moment(new Date(d[key])).isSame(
        moment(new Date()).subtract(1, "week"),
        "week"
      )
    ).length;
  }
};
