import { CommentOutlined, SendOutlined } from '@ant-design/icons';
import { Col, Input } from 'antd'
import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import { firstWord, isMyLog, removeFirstWord } from '../../helpers/utils';
import GlobalContext from '../../store/GlobalContext';

const LogBox = ({md,table,id,comment,setComment,addComment,open,height}) => {
 
    const [logs, setLogs] = useState([]);
    const { getLogsByRowId } = useContext(GlobalContext);

    useEffect(()=>{
      setLogs([]);
      getLogs();
    },[id,open]);

    const getLogs = () => {
        if(!id) return;
        const row_logs = getLogsByRowId(table,id);
        if (Array.isArray(row_logs)) setLogs(row_logs.sort((a,b)=>new Date(b?.date) - new Date(a?.date)));
    }

    const handleAddComment = async (id) => {
        await addComment(id);
        getLogs();
    }

  return (
    <Col xs={24} md={md || 6} style={{ borderLeft: "1px solid #eee", borderRadius: 6 }} className="py-2 logs-bg">
    <h2 className='fw-600'><CommentOutlined/> Logs</h2>
    <div className='w-full logs__column' style={{height:!!height && height}}>
        {
            logs?.length ? logs.map(({date,message}, i) => (
                    <li key={i} className={`message ${isMyLog(message) && "mine"}`} >
                        <span className="opacity-50 fs-10">{firstWord(message)}</span><br/>
                        {removeFirstWord(message)} <span class="metadata">
                            <span className="time">{date}</span></span>
                        <div className='chat-profile__pic'>{message.substring(0, 1)}</div>
                    </li>
                )) :  <div className='nologs--text'>
                <p className=''>No logs to show yet!</p>
                </div>
        }
    </div>
    <p className='fw-600 fs-14 mt-10'>Add a Comment</p>
    <div className='flex items-center justify-between'>
        <Input className='w-full flex-1' value={comment} onChange={e => setComment(e.target.value)} />
        <SendOutlined style={{  fontSize: 25 }} className="ml-10" onClick={() => handleAddComment(id)} />
    </div>
</Col>
  )
}

export default LogBox