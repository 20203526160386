import React, { useContext, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { handleGoogleLogin, SignIn, SignUp } from "../../../api/auth";
import home from "../../../assets/png/home.png";
import { PASSWORD, USERNAME } from "../../../helpers/Constants";
import { getEmailDomain, ShowNotification } from "../../../helpers/utils";
import { NotificationType } from "../../../helpers/validators";
import { getAnalytics } from "firebase/analytics";
import GlobalContext from "../../../store/GlobalContext";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../../firebase";
import OrganizationDetails from "./OrganizationDetails";
import UserDetails from "./UserDetails";
import { useEffect } from "react";
import logo from '../../../assets/png/light_logo.png';

const AuthCard = () => {
  const analytics = getAnalytics();
  const [formState, setFormState] = useState(0);
  const [signUpMode, setSignUpMode] = useState(false);
  const [orgSignUpMode, setOrgSignUpMode] = useState(false);
  const [orgName,setOrgName]=useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const { TENANTS, tenantId, setTenantId } = useContext(GlobalContext);

  const handleEmailLogin = async (payload) => {
    const { email, password, fullName } = payload;
    if (!email || !password) {
      ShowNotification(
        "Login Error",
        "Invalid Username or Password",
        NotificationType[1]
      );
      return;
    }
    setLoading(true);
    signUpMode
      ? await SignUp(fullName, email, password, tenantId,orgSignUpMode,orgName)
      : await SignIn(email, password, tenantId);
    setLoading(false);
  };

  useEffect(()=>{
    if(!location.search || !TENANTS || !TENANTS?.length)return;
    if(location.search.includes('tenant')){
      const id=location.search.split('=')[1];
      const t = TENANTS.find((d) => d?.id === id);
      if (!t) {
        ShowNotification(
          "Error!",
          "Cant find organisation with this id",
          NotificationType[1]
        );
        return;
      }
      setTenantId(id);
      setOrgName("");
      setSignUpMode(true);
      setFormState(1);
    }
  },[location?.search,TENANTS]);

  const googleLogin = (e) => {
    if (!orgSignUpMode && !tenantId ) {
      ShowNotification(
        "Error!",
        "please select the tenant",
        NotificationType[1]
      );
      return;
    }
    handleGoogleLogin(e, tenantId,orgSignUpMode,orgName);
  };

  const handleSubmitOrganization = () => {
    if (!orgName) {
      ShowNotification("Error!","No organisation entered",NotificationType[1]);
      return;
    }
    if (orgSignUpMode) {
      setSignUpMode(true);
      setFormState(1);
      return;
    }
    const t = TENANTS.find((d) => d?.title === orgName.trim());
    if (!t) {
      ShowNotification(
        "Error!",
        "Cant find organisation with this name",
        NotificationType[1]
      );
      return;
    }
    setTenantId(t?.id);
    setOrgName("");
    setFormState(1);
  };

  return (
    <div className="authCard">
       <img src={logo} alt="logo" className="mt-30 logo"/>
        <h1 className={`${formState === 0 && "mt-50"}`}>
          Sign {(signUpMode || orgSignUpMode) ? "Up" :"In"} 
        </h1>
        {formState === 0 && (
          <OrganizationDetails
            signUpMode={orgSignUpMode}
            setSignUpMode={setOrgSignUpMode}
            onSubmit={handleSubmitOrganization}
            orgName={orgName}
            setOrgName={setOrgName}
          />
        )}
        {formState === 1 && (
          <UserDetails
            signUpMode={signUpMode}
            setSignUpMode={setSignUpMode}
            loading={loading}
            onSubmit={handleEmailLogin}
            googleLogin={googleLogin}
            orgSignUpMode={orgSignUpMode}
          />
        )}
    </div>
  );
};

export default AuthCard;
