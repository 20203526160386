import { Button, Input, Modal, Row, Col, Select, Tabs } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ASSET_TABLE_COLUMS } from "../asset-data";
import moment from "moment";
import { Base64, isMyLog, ShowNotification } from "../../../helpers/utils";
import { NotificationType } from "../../../helpers/validators";
import GlobalContext from "../../../store/GlobalContext";
import {
  CheckCircleFilled,
  EditFilled,
  PlusCircleOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { getUserFromStore } from "../../../api/auth";
import { TABLES } from "../../../store/global-types";
import LocationsSelect from "../../locations/components/LocationsSelect";
import TagsSelect from "../../tags/components/TagsSelect";
import AssetGroupSelect from "./AssetGroupSelect";
import VendorsSelect from "../../vendors/components/VendorsSelect";
import { TAG_TYPES } from "../../../helpers/Constants";
import FrequncySelect from "../../../components/selects/FrequencySelect";
import ModalFooter from "../../../components/UI/ModalFooter";
import LogBox from "../../../components/logs/LogBox";
import UploadImage from "../../../components/UI/Upload";
import { db, storage } from "../../../firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import DocsBox from "../../../components/UI/DocsBox";
import { doc, setDoc } from "firebase/firestore";

const ModalWrapper = ({ isMobile, children }) => {
  return !isMobile ? (
    children
  ) : (
    <Tabs type="card" defaultActiveKey={0} size="middle">
      {["Details", "Documents", "Comments"].map((d, i) => (
        <Tabs.TabPane tab={d} key={i}>
          {children[i]}
        </Tabs.TabPane>
      ))}
    </Tabs>
  );
};

const AddAssetModal = ({
  open,
  onClose,
  addData,
  editData,
  comment,
  addComment,
  setComment,
  onClickAddTag,
  onFileChange,
}) => {
  const [inputData, setInputData] = useState({});
  const { TAGS, getLogsByRowId, BASE_URL } = useContext(GlobalContext);
  const [updatedColumns, setUpdatedColumns] = useState([]);
  const [titleEditState, setTitleEditState] = useState(false);
  const [files, setFiles] = useState([]);
  const [logs, setLogs] = useState({});

  const initData = () => {
    setInputData({});
    setLogs({});
    setUpdatedColumns([]);
    setTitleEditState(false);
    setFiles([]);
  };

  useEffect(() => {
    if (!!editData) {
      setInputData({ ...editData });
      editData[5]?.length &&
        (Array.isArray(editData[5])
          ? setFiles(editData[5])
          : setFiles([editData[5]]));
      getLogs();
    }
  }, [editData]);

  const getLogs = () => {
    const row_logs = getLogsByRowId("assets", editData?.id);
    if (!!row_logs) setLogs(row_logs);
  };

  const onChangeHandler = (e, id, type, title, isEvent = true) => {
    if (editData) {
      if (!updatedColumns.includes(title)) {
        updatedColumns.push(title);
        setUpdatedColumns([...updatedColumns]);
      }
    }

    const value = !isEvent ? e : e.target.value;
    setInputData({ ...inputData, [id]: value });
  };

  const findErrorsInPayload = () => {
    if (Object.keys(inputData)?.length < 5) {
      ShowNotification(
        "Error!",
        "Atleast enter 5 fields!",
        NotificationType[1]
      );
      return true;
    } else if (new Date(inputData[12]) > new Date(inputData[13])) {
      ShowNotification(
        "Error!",
        "Purchase date cannot be greater than Placed in service date!",
        NotificationType[1]
      );
      return true;
    } else if (new Date(inputData[12]) > new Date(inputData[14])) {
      ShowNotification(
        "Error!",
        "Purchase date cannot be greater than Warranty Expiration Date!",
        NotificationType[1]
      );
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (!editData || files?.length === editData[5]?.length) return;
    if (!updatedColumns.includes("Image")) {
      updatedColumns.push("Image");
      setUpdatedColumns([...updatedColumns]);
    }
  }, [files]);

  const handleSubmit = async () => {
    const error = findErrorsInPayload();
    if (error) return;
    addData({ ...inputData, 5: [...files] }, updatedColumns);
    initData();
    onClose();
  };

  const onModalClose = () => {
    initData();
    onClose();
  };

  const onUploadClick = () => {
    document.getElementById("upload").click();
  };

  return (
    <Modal
      title={false}
      footer={false}
      visible={open}
      width={"min(100%,1600px)"}
      centered
      onCancel={onModalClose}
    >
      {!editData ? (
        <h2 className="fw-600">Add New Asset</h2>
      ) : titleEditState ? (
        <div className="flex items-center ">
          <Input
            className="w-300"
            value={inputData[2]}
            onChange={(e) => onChangeHandler(e, 2, "text", "Machine Name")}
          />
          <CheckCircleFilled
            style={{ color: "green" }}
            className="cursor-pointer fs-26 ml-10"
            onClick={handleSubmit}
          />
        </div>
      ) : (
        <h2 className="fw-600">
          {editData[2]}{" "}
          <EditFilled
            onClick={(e) => setTitleEditState(!titleEditState)}
            className="cursor-pointer"
          />
        </h2>
      )}
      <Row gutter={16}>
        <ModalWrapper isMobile={window.innerWidth < 1000}>
          <Col xs={24} md={13}>
            <Row gutter={16}>
              {ASSET_TABLE_COLUMS.map(({ id, title, type }, i) => {
                const value =
                  type === "date"
                    ? moment(inputData[id]).format("YYYY-MM-DD")
                    : inputData[id];
                return id === 2 && !!editData ? null : id === 16 ? (
                  <Input
                    type="file"
                    id="upload"
                    onChange={onFileChange}
                    hidden
                  />
                ) : (
                  <Col xs={24} md={12} className="mt-10">
                    <p className="fw-600 fs-14">{title}</p>
                    {id === 15 || id === 9 ? (
                      <TagsSelect
                        value={value}
                        onChange={(val) =>
                          onChangeHandler(val, id, type, title, false)
                        }
                        onClickAddTag={onClickAddTag}
                        table={TABLES.ASSETS}
                        type={id === 9 ? TAG_TYPES[1] : false}
                      />
                    ) : id === 3 ? (
                      <VendorsSelect
                        value={value}
                        onChange={(val) =>
                          onChangeHandler(val, id, type, title, false)
                        }
                      />
                    ) : id === 10 ? (
                      <LocationsSelect
                        value={value}
                        onChange={(val) =>
                          onChangeHandler(val, id, type, title, false)
                        }
                      />
                    ) : id === 11 ? (
                      <AssetGroupSelect
                        value={value}
                        assetId={editData?.id}
                        onChange={(val) =>
                          onChangeHandler(val, id, type, title, false)
                        }
                      />
                    ) : id === 5 ? (
                      <UploadImage files={files} setFiles={setFiles} />
                    ) : (
                      <Input
                        className="w-full"
                        type={type}
                        value={value}
                        onChange={(e) => onChangeHandler(e, id, type, title)}
                      />
                    )}
                  </Col>
                );
              })}
            </Row>
          </Col>
          <DocsBox md={5} docs={inputData[16]} handleUpload={onUploadClick} />
          <LogBox
            open={open}
            md={5}
            table={TABLES.ASSETS}
            id={editData?.id}
            comment={comment}
            setComment={setComment}
            addComment={addComment}
          />
        </ModalWrapper>
      </Row>
      <ModalFooter
        onSubmit={handleSubmit}
        editMode={!!editData}
        onClose={onModalClose}
      />
    </Modal>
  );
};

export default AddAssetModal;
