import axios from 'axios';
import { API_BASE_URL, CUSTOMER_ID } from "../helpers/Constants"
import { SecureCall } from '../helpers/utils';
import { DASHBOARDS } from './end-points';
import { headers } from './home';

export const fetchDashboards = async () => {

    const call = async () => {
        const res = await axios.get(`${API_BASE_URL}${DASHBOARDS(CUSTOMER_ID)}?pageSize=100&page=0`,
            { headers: headers });
        return res;
    }
    const response = await SecureCall(call, () => { }, "Dashboards fetched successfully!");
    return response;
}
