import { Button, Input, Modal, Row, Col, Select } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import {  isMyLog, ShowNotification } from '../../../helpers/utils'
import { NotificationType } from '../../../helpers/validators'
import GlobalContext from '../../../store/GlobalContext'
import { CheckCircleFilled, EditFilled,  SendOutlined } from '@ant-design/icons'
import { TABLES } from '../../../store/global-types'
import { LOCATION_COLUMNS } from '../location-data'
import ModalFooter from '../../../components/UI/ModalFooter'

const AddLocationModal = ({ open, onClose, addData, editData, comment, addComment, setComment }) => {

    const [inputData, setInputData] = useState({});
    const { getLogsByRowId } = useContext(GlobalContext);
    const [updatedColumns, setUpdatedColumns] = useState([]);
    const [titleEditState,setTitleEditState]=useState(false);
    const [logs, setLogs] = useState({});

    const initData = () => {
        setInputData({});
        setLogs({});
        setUpdatedColumns([]);
        setTitleEditState(false);
    }

    useEffect(() => {
        if (!!editData) {
            setInputData({ ...editData });
            getLogs();
        }
    }, [editData]);


    const getLogs = () => {
        const row_logs = getLogsByRowId(TABLES.LOCATIONS, editData?.id);
        if (!!row_logs) setLogs(row_logs);
    }

    const onChangeHandler = (e, id, title) => {

        if (editData) {
            if (!updatedColumns.includes(title)) {
                updatedColumns.push(title);
                setUpdatedColumns([...updatedColumns]);
            }
        }

            const value = e.target.value;
            setInputData({ ...inputData, [id]: value });
    }

    const findErrorsInPayload = () =>{
        if (Object.keys(inputData)?.length < 5) {
            ShowNotification("Error!", "Atleast enter 5 fields!", NotificationType[1]);
            return true;
        }else{
            return false;
        }
    }


    const handleSubmit = async() => {
        const error = findErrorsInPayload();
        if(error) return;
        addData({ ...inputData }, updatedColumns);
        initData();
        onClose();
    }

    const onModalClose = () => {
        initData();
        onClose();
    }

    const handleAddComment = async (id) => {
        await addComment(id);
        getLogs();
    }

       return (
        <Modal
            title={false}
            footer={false}
            visible={open}
            width={"min(100%,800px)"}
            centered
            onCancel={onModalClose}
        >
            {
                !editData ?
                <h2 className='fw-600'>Add New Location</h2>:
                (titleEditState ? 
                <div className='flex items-center '>
               <Input className='w-300' value={inputData.locationTitle} onChange={e=>onChangeHandler(e,"locationTitle","text","Location Title")} /> 
               <CheckCircleFilled style={{color:"green"}} className="cursor-pointer fs-26 ml-10" onClick={handleSubmit}/>
                </div>
               : <h2 className='fw-600'>{editData.locationTitle} <EditFilled onClick={e=>setTitleEditState(!titleEditState)} className='cursor-pointer'/></h2>)
            }
            <Row gutter={16}>
                <Col xs={24} md={14}>
                    <Row gutter={16}>
                        {
                            LOCATION_COLUMNS.map(({ id, title, type,placeholder }, i) => {
                                return ((id === 'locationTitle' && !!editData) ? null :
                                <Col xs={24} className="mt-10">
                                <p className='fw-600 fs-14'>{title}</p>
                                <Input className='w-full' type={type} value={inputData[id]} placeholder={placeholder} onChange={(e) => onChangeHandler(e, id, title)} />
                                </Col>
                                )
                            })
                        }
                    </Row>
                </Col>

                <Col xs={24} md={10} style={{ borderLeft: "1px solid #eee", borderRadius: 6 }} className="py-2 logs-bg">
                    <h2 className='fw-600'>Logs</h2>
                    <div className='w-full logs__column'>
                        {
                            ((logs && Object?.entries(logs)?.length)) ?
                                Object.entries(logs).sort().reverse().map(([key, value], i) => (
                                    <li key={i} className={`message ${isMyLog(value) && "mine"}`} >
                                        {value} <span class="metadata">
                                            <span class="time">{key}</span></span>
                                        <div className='chat-profile__pic'>{value.substring(0, 1)}</div>
                                    </li>
                                )) : 
                                <div className='nologs--text'>
                                <p className=''>No logs to show yet!</p>
                                </div>
                        }
                    </div>
                    <p className='fw-600 fs-14 mt-10'>Add a Comment</p>
                    <div className='flex items-center justify-between'>
                        <Input className='w-full flex-1' value={comment} onChange={e => setComment(e.target.value)} onKeyPress={e => e.key === "Enter" && addComment(inputData?.id)} />
                        <SendOutlined style={{ fontSize: 25 }} className="ml-10" onClick={() => handleAddComment(inputData?.id)} />
                    </div>
                </Col>
            </Row>
            <ModalFooter onSubmit={handleSubmit} editMode={!!editData} onClose={onModalClose}/>
        </Modal>
    )
}

export default AddLocationModal