import { DiffOutlined, EyeFilled, ToTopOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Menu, Progress } from "antd";
import React, { useState } from "react";
import { useContext } from "react";
import { nextStatusMenu, STATUS_TYPES } from "../../../helpers/Constants";
import GlobalContext from "../../../store/GlobalContext";
import images from "../checklist-data";
import ChecklistDetails from "./ChecklistDetails";

export const getAvgChecklistCompletion = (data) => {
  if (!data) return 0;
  const length = Object.keys(data)?.length;
  if (!length) return 0;
  const sum = Object.values(data)
    .map((value, i) => {
      return percentage(value);
    })
    .reduce((prev, value) => prev + +value, 0);
  const avg = sum ? (sum / length).toFixed(0) : 0;
  return avg;
};

const percentage = (stats) =>
  (
    (Object.values(stats).filter((value) => value === 3 || value === 4).length /
      Object.keys(stats).length) *
    100
  ).toFixed(0);

const ChecklistStats = ({
  md,
  data,
  onStatusChange,
  setChecklistModalOpen,
  height
}) => {
  const [checklist, setChecklist] = useState(false);
  const { getChecklistById } = useContext(GlobalContext);

  return (
    <>
      <Col
        xs={24}
        md={md || 6}
        style={{
          borderLeft: "1px solid #eee",
          borderRadius: 6,
          marginRight: 5,
          marginLeft: 5,
        }}
        className="py-2 logs-bg"
      >
        <h2 className="fw-600 ml-10"><DiffOutlined/> Checklists</h2>
        <div className="w-full logs__column" style={{ paddingRight: "0px",height:!!height && height }}>
          {data && Object?.entries(data)?.length ? (
            Object.entries(data).map(([key, value], i) => (
              <ChecklistStatCard
                stats={value}
                id={key}
                key={key}
                data={getChecklistById(key)}
                setChecklist={setChecklist}
                onStatusChange={onStatusChange}
              />
            ))
          ) : (
            <div className="nologs--text">
              <p className="">No Checklist Attached!</p>
            </div>
          )}
        </div>
        <Button
          onClick={() => setChecklistModalOpen(true)}
          className="bg-primary c-white fw-600 w-full mt-30"
        >
        <ToTopOutlined/>  Attach a Checklist
        </Button>
      </Col>
      <ChecklistDetails
        open={!!checklist}
        data={checklist}
        onClose={() => setChecklist(false)}
      />
    </>
  );
};

export default ChecklistStats;

const ChecklistStatCard = ({
  data,
  stats,
  id: checkId,
  onStatusChange,
  setChecklist,
}) => {
  const percentageFinished = percentage(stats);

  const color = (value) => STATUS_TYPES.find((d) => d.id === value)?.color;
  const status = (value) => STATUS_TYPES.find((d) => d.id === value)?.title;

  if (!data) return null;

  return (
    <div className="checklist__stat">
      <div className="flex items-center justify-between mx-1">
        <p className="fw-600 mb-1">{data?.title}</p>
        <p
          className="task_status c-primary"
          onClick={() => setChecklist({ ...data })}
        >
          <EyeFilled /> View
        </p>
      </div>
      <Progress type="circle" percent={percentageFinished} />
      {data.tasks.map(({ title, id }) => (
        <div className="flex items-center justify-between mt-1">
          <div className="flex items-center flex1">
            <img src={images.tick2} alt="tick" height={15} />
            <p className="tracking-tight w-150 text-left ml-1  text-truncate">
              {title}
            </p>
          </div>
          {nextStatusMenu(stats[id]) ? (
            <Dropdown
              overlay={
                <Menu>
                  {nextStatusMenu(stats[id]).map((item) => (
                    <Menu.Item
                      onClick={(e) => onStatusChange(checkId, id, item.id)}
                      key={item.id}
                    >
                      {item.title}
                    </Menu.Item>
                  ))}
                </Menu>
              }
            >
              <p style={{ color: color(stats[id]) }} className={`task_status`}>
                {status(stats[id])}
              </p>
            </Dropdown>
          ) : (
            <p style={{ color: color(stats[id]) }} className={`task_status`}>
              {status(stats[id])}
            </p>
          )}
        </div>
      ))}
    </div>
  );
};
