import { Button, Input, Modal, Row, Col, Select, Checkbox } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { isMyLog, ShowNotification } from "../../../helpers/utils";
import { NotificationType } from "../../../helpers/validators";
import GlobalContext from "../../../store/GlobalContext";
import { CheckCircleFilled, EditFilled, SendOutlined } from "@ant-design/icons";
import { TABLES } from "../../../store/global-types";
import { VENDOR_COLUMNS } from "../vendor-data";
import ModalFooter from "../../../components/UI/ModalFooter";
import LogBox from "../../../components/logs/LogBox";

const AddVendorModal = ({
  open,
  onClose,
  addData,
  editData,
  comment,
  addComment,
  setComment,
}) => {
  const [inputData, setInputData] = useState({});
  const { getLogsByRowId } = useContext(GlobalContext);
  const [updatedColumns, setUpdatedColumns] = useState([]);
  const [titleEditState, setTitleEditState] = useState(false);
  const [logs, setLogs] = useState({});

  const initData = () => {
    setInputData({});
    setLogs({});
    setUpdatedColumns([]);
    setTitleEditState(false);
  };

  useEffect(() => {
    if (!!editData) {
      setInputData({ ...editData });
      getLogs();
    }
  }, [editData]);

  const getLogs = () => {
    const row_logs = getLogsByRowId(TABLES.VENDORS, editData?.id);
    if (!!row_logs) setLogs(row_logs);
  };

  const onChangeHandler = (e, id, title,isEvent=true) => {
    if (editData) {
      if (!updatedColumns.includes(title)) {
        updatedColumns.push(title);
        setUpdatedColumns([...updatedColumns]);
      }
    }

    const value = isEvent ? e.target.value : e;
    setInputData({ ...inputData, [id]: value });
  };

  const findErrorsInPayload = () => {
    if (Object.keys(inputData)?.length < 5) {
      ShowNotification(
        "Error!",
        "Atleast enter 5 fields!",
        NotificationType[1]
      );
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async () => {
    const error = findErrorsInPayload();
    if (error) return;
    addData({ ...inputData }, updatedColumns);
    initData();
    onClose();
  };

  const onModalClose = () => {
    initData();
    onClose();
  };

  const handleAddComment = async (id) => {
    await addComment(id);
    getLogs();
  };

  console.log(inputData);

  return (
    <Modal
      title={false}
      footer={false}
      visible={open}
      width={"min(100%,800px)"}
      centered
      onCancel={onModalClose}
    >
      {!editData ? (
        <h2 className="fw-600">Add New Vendor</h2>
      ) : titleEditState ? (
        <div className="flex items-center ">
          <Input
            className="w-300"
            value={inputData.name}
            onChange={(e) => onChangeHandler(e, "name", "text", "Name")}
          />
          <CheckCircleFilled
            style={{ color: "green" }}
            className="cursor-pointer fs-26 ml-10"
            onClick={handleSubmit}
          />
        </div>
      ) : (
        <h2 className="fw-600">
          {editData.name}{" "}
          <EditFilled
            onClick={(e) => setTitleEditState(!titleEditState)}
            className="cursor-pointer"
          />
        </h2>
      )}
      <Row gutter={16}>
        <Col xs={24} md={14}>
          <Row gutter={16}>
            {VENDOR_COLUMNS.map(({ id, title, type, placeholder }, i) => {
              return (id === "name" && !!editData) ||
                id === "createdOn" ||
                id === "createdBy" ||
                id === "id" ? null : (
                <Col xs={24} className="mt-10">
                  <p className="fw-600 fs-14">{title}</p>
                  {id === "isCustomer" ? (
                    <Checkbox
                      checked={inputData[id] === true}
                      onChange={(e) => onChangeHandler(e.target.checked, id, title,false)}
                    />
                  ) : (
                    <Input
                      className="w-full"
                      type={type}
                      value={inputData[id]}
                      placeholder={placeholder}
                      onChange={(e) => onChangeHandler(e, id, title)}
                    />
                  )}
                </Col>
              );
            })}
          </Row>
        </Col>
        <LogBox
          open={open}
          md={10}
          table={TABLES.VENDORS}
          id={editData?.id}
          comment={comment}
          setComment={setComment}
          addComment={addComment}
        />
      </Row>
      <ModalFooter
        onSubmit={handleSubmit}
        editMode={!!editData}
        onClose={onModalClose}
      />
    </Modal>
  );
};

export default AddVendorModal;
