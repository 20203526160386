import React from 'react';
import Button from './index.jsx';
import './buttons.scss';

const TabSwitchButton = ({ children, selected, classes, ...props }) => {
    return (
        <Button classes={`tabSwitch--btn fs-14 fw-600 ${selected && "selected"} ${classes}`} {...props}>{children}</Button>
    )
}

export default TabSwitchButton