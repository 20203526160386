import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


const BarChart = ({ labels, data, color, height, width,centered,classes,horizontal }) => {

  const options = {
    indexAxis:horizontal ? 'y' : 'x',
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        display:false
      },
      title: {
        display: false,
        text: "Bar Chart",
      },
    },
    yAxes:{
      ticks: {
        stepSize: 1
    }
    },
    scales: {
      y: {
         grid: {
            display: false
         }
      },
      x: {
        grid: {
           display: false
        }
     }
   }
  };

  const chart_data = {
    labels,
    datasets: [
      {
        data: data || [],
        backgroundColor: color || '#40e0d0',
      },
    ],
  };
  return (
    <div style={{ height: !!height ? height : "auto", width: !!width ? width : '300px',maxHeight:!!height && height }} className={`${centered && 'mx-auto'} ${classes} flex flex-col justify-end`}>
      <Bar options={options} data={chart_data}/>
    </div>
  );
};

export default BarChart;
