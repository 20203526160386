import { Modal } from 'antd'
import React, { useState } from 'react'
import OtpInput from 'react-otp-input'
import Button from '../../../components/buttons'

const styles = {
    container:{
        width:"fit-content",
        margin:"40px auto"
    },
    input:{
        height:50,
        width:50,
        margin:"0 5px",
        border:"1px solid #E5E5E5",
        filter:"drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.25))",
        borderRadius:"4px",
        fontSize:"20px",
        fontWeight:700,
        userSelect:'none'
    },
    error:{
        border:"1px solid #EC8686"
    }
  }

const OtpModal = ({open,onClose,onSubmit}) => {
    const [otp,setOtp]=useState('');

  return (
   <Modal
   footer={false}
   visible={open}
   onCancel={onClose}
   width="min(400px,95%)"
   >
    <div className={`py-4 text-center column-center`}>
        <h3 style={{ fontWeight: 600, fontSize: 24 }} className="c-black mt-1">
          Enter OTP
        </h3>
        <p style={{ fontSize: 18, fontWeight: 400 }} className="c-black mt-4">
        Please enter the one time password sent to your phone number
        </p>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          separator={null}
          containerStyle={styles.container}
          inputStyle={styles.input}
          hasErrored={false}
          errorStyle={styles.error}
        />
        <Button onClick={e=>onSubmit(e,otp)}>Submit</Button>
      </div>
   </Modal>
  )
}

export default OtpModal