export const VENDOR_COLUMNS = [
    {id:"id",title:"Vendor ID",type:"text"},
    {id:"name",title:"Name",type:"text",placeholder:"Enter Vendor's name"},
    {id:"address",type:"text",title:"Address",placeholder:"Enter address"},
    {id:"city",type:"text",title:"City",placeholder:"Enter City"},
    {id:"state",type:"text",title:"State",placeholder:"Enter State"},
    {id:"contact",type:"text",title:"Contact",placeholder:"Enter contact"},
    {id:"email",type:"text",title:"Email",placeholder:"Enter Email"},
    {id:"phone",type:"text",title:"Phone",placeholder:"Enter phone"},
    {id:"website",type:"text",title:"Website",placeholder:"Enter website"},
    {id:"isCustomer",type:"bool",title:"Is Customer"},
    {id:"category",type:"text",title:"Vendor's category",placeholder:"Choose category"},
    {id:"createdOn",type:"date",title:"Created On"},
    {id:"createdBy",type:"text",title:"Created By"},
]

export const CUSTOMER_COLUMNS = [
    {id:"id",title:"Customer ID",type:"text"},
    {id:"name",title:"Name",type:"text",placeholder:"Enter Customer's name"},
    {id:"address",type:"text",title:"Address",placeholder:"Enter address"},
    {id:"city",type:"text",title:"City",placeholder:"Enter City"},
    {id:"state",type:"text",title:"State",placeholder:"Enter State"},
    {id:"contact",type:"text",title:"Contact",placeholder:"Enter contact"},
    {id:"email",type:"text",title:"Email",placeholder:"Enter Email"},
    {id:"phone",type:"text",title:"Phone",placeholder:"Enter phone"},
    {id:"website",type:"text",title:"Website",placeholder:"Enter website"},
    {id:"isCustomer",type:"bool",title:"Is Customer"},
    {id:"category",type:"text",title:"Customer's category",placeholder:"Choose category"},
    {id:"createdOn",type:"date",title:"Created On"},
    {id:"createdBy",type:"text",title:"Created By"},
]