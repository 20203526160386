import { collection, doc, deleteDoc, getDocs, setDoc, arrayUnion } from "@firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { checkFirebaseAuth, getUserFromStore } from "../api/auth";
import { db, storage } from "../firebase";
import { TAG_CATS, TAG_TYPES } from "../helpers/Constants";
import { ShowNotification } from "../helpers/utils";
import { NotificationType } from '../helpers/validators';
import { TABLES } from "./global-types";
import {v4 as uuidv4} from 'uuid';
import moment from "moment";

export const fetchAssets = async (BASE_URL) => {
    const snapshot = await getDocs(collection(db, `${BASE_URL}/${TABLES.ASSETS}`));
    let data = [];
    snapshot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
    });
    return data;
}

export const fetchAssetGroups = async (BASE_URL,assets) => {
    const snapshot = await getDocs(collection(db, `${BASE_URL}/${TABLES.ASSET_GROUPS}`));
    let data;
    let id;
    snapshot.forEach((doc) => {
        data = { ...doc.data()}
        id = doc.id;
    });

    const assigned = [];

    const arrayOfArrays = [...Object.values(data)];
    for (const values of arrayOfArrays){
        assigned.push(...values);
    }

    const unassigned = assets.map(a=>a.id).filter(d=>!assigned.includes(d));

    data ={
        "Unassigned":[...unassigned],
        ...data
    }
    return [id, data];
}

export const fetchUsers = async (BASE_URL) => {
    const snapshot = await getDocs(collection(db, `${BASE_URL}/${TABLES.USERS}`));
    const data = [];
    snapshot.forEach((doc) => {
        data.push({ id: doc.id, userGroup:doc.data().userGroup || "N/A", ...doc.data() });
    });
    return data;
}

export const fetchUserGroups = async (BASE_URL,users) => {
    const snapshot = await getDocs(collection(db, `${BASE_URL}/${TABLES.USER_GROUPS}`));
    let data={};
    let id;
    snapshot.forEach((doc) => {
        data = {...doc.data()};
        id = doc.id;
    });

    data = {
        "Unassigned":{},
        "Admin":{},
        ...data
    };

    const unassigned =[];
    let assigned = [];

    Object.entries(data).forEach(([key,values])=>{
      if(key === "Unassigned") return;
      const u = Array.isArray(values?.users) ? values.users : [];
      assigned = [...assigned,...u];
    });

    for(const a of users){
        if(a.userGroup === "Admin"){
            data["Admin"].users = [a.id];
        }else
        if(!assigned.includes(a?.id)){
            unassigned.push(a?.id);
        }
    }
    data["Unassigned"].users = [...unassigned];
    return [id, data];
}

export const fetchMaintenanceSchedules = async (BASE_URL) => {
    const snapshot = await getDocs(collection(db, `${BASE_URL}/${TABLES.MAINTENANCE}`));
    let data = [];
    snapshot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
    });
    return data;
}

export const fetchTags = async (BASE_URL) => {
    const data = {};
    for (const tag_title of TAG_CATS) {
        const tag_data = {};
        for (const tag_type of TAG_TYPES) {
            const sp = await getDocs(collection(db, `${BASE_URL}/${TABLES.TAGS}/${tag_title}/${tag_type}`));
            let list = [];
            sp.forEach((doc) => {
                list.push({ ...doc.data(), id: doc.id });
            });
            tag_data[tag_type] = [...list];
        }
        data[tag_title] = { ...tag_data };
    }
    return data;
}

export const deleteTableRows = async (rowIdList, table, onSuccess, onError, BASE_URL, haveLogs = true) => {
    try {
        rowIdList.forEach(async (id, i) => {
            await deleteDoc(doc(db, `${BASE_URL}/${table}`, id));
            if (haveLogs) await deleteDoc(doc(db, `${BASE_URL}/logs`, table, `${table}_logs`, id));
            if (i === rowIdList?.length - 1) {
                ShowNotification("Success!", rowIdList?.length + " row/rows deleted successfully", NotificationType[0]);
                onSuccess();
            }
        });
    } catch (err) {
        console.log(err);
        onError();
        ShowNotification("Error!", err.message || "Something went wrong", NotificationType[1]);
    }
}

export const fetchTenants = async () => {
    try {
        const snapshot = await getDocs(collection(db, TABLES.MAIN_TABLE));
        let data = [];
        snapshot.forEach((doc) => {
            data.push({ ...doc.data(), id: doc.id });
        });
        return data;
    } catch (err) {
        console.log(err);
        ShowNotification("Error!", err.message || "Cant fetch tenents", NotificationType[1]);
        return [];
    }
}


export const fetchLocations = async(BASE_URL) =>{
    const snapshot = await getDocs(collection(db, `${BASE_URL}/${TABLES.LOCATIONS}`));
    const data = [];
    snapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
    });
    return data;
}

export const fetchVendors = async(BASE_URL) =>{
    const snapshot = await getDocs(collection(db, `${BASE_URL}/${TABLES.VENDORS}`));
    const data = [];
    snapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
    });
    return data;
}

export const fetchChecklists = async(BASE_URL) =>{
    const snapshot = await getDocs(collection(db, `${BASE_URL}/${TABLES.CHECKLISTS}`));
    const data = [];
    snapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
    });
    return data;
}
export const fetchSettings = async(BASE_URL) =>{
    const snapshot = await getDocs(collection(db, `${BASE_URL}/${TABLES.SETTINGS}`));
    let data = {};
    snapshot.forEach((doc) => {
        data = {...doc.data(),id:doc.id};
    });
    return data;
}

export const fetchFolders = async(BASE_URL) =>{
    const snapshot = await getDocs(collection(db, `${BASE_URL}/${TABLES.FOLDERS}`));
    let data = [];
    snapshot.forEach((doc) => {
        data.push({...doc.data(),id:doc.id});
    });
    return data;
}

export const fetchOrders = async(BASE_URL,setter) =>{
    const snapshot = await getDocs(collection(db, `${BASE_URL}/${TABLES.ORDERS}`));
    let data = [];
    snapshot.forEach(async(doc) => {
        if(doc.exists()){
            const sp = await getDocs(collection(db,BASE_URL,TABLES.ORDERS,doc.id,TABLES.SUBORDERS));
            let subOrders = [];
            sp.forEach((d)=>{
               subOrders.push({...d.data(),id:d.id,orderId:doc.id});
            })
            let dates = [];
            subOrders.forEach(o=>{
                dates = [...dates,moment(o.start),moment(o.end)];
            });
            const start = dates.length ? moment.min(dates).format("DD MMMM, YYYY HH:mm A") : 'N/A';
            const end = dates.length ? moment.max(dates).format("DD MMMM, YYYY HH:mm A") : 'N/A';
            data = [...data,{...doc.data(),id:doc.id,subOrders:[...subOrders],start,end}];
            if(data.length===snapshot.size){
                setter([...data]);
            }
        }
    });
}


export const onDocUpload = async(file,id,base,table,field,custom=false) =>{

    const name = file?.name || 'noname';
    const user = getUserFromStore();

    const storageRef = !custom ? ref(storage, `/${base}/${table}/${id}/${uuidv4()}`) :
                                 ref(storage, `/${base}/${table}/${file.name ? file.name?.split(' ')?.join('-') : uuidv4()}`)

    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
        "state_changed",
        (snapshot) => {
            const percent = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            console.log(percent);
        },
        (err) => console.log(err),
        () => {
            if(!custom){
            getDownloadURL(uploadTask.snapshot.ref).then(async(url) => {
                const data = {
                    name:name,
                    url:url,
                    user: user?.email,
                    timestamp:moment().format("DD-MM-YYYY hh:mm A")
                }
                await setDoc(doc(db,base,table,id ), { [field]:arrayUnion(data) }, { merge: true });
                ShowNotification('Success!',"document upload successfully!",NotificationType[0]);
                setTimeout(()=>{
                  window.location.reload();
                },1000);
            });
            }
        }
    );
}



