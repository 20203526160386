import React, { useContext } from 'react';
import GlobalContext from '../../store/GlobalContext';
import { CloseCircleOutlined, EyeInvisibleFilled, FilterOutlined, FundViewOutlined, ImportOutlined, MoreOutlined, SearchOutlined, SortAscendingOutlined } from '@ant-design/icons';
import { Checkbox, Dropdown, Input, Menu } from 'antd';


const TableManipulator = ({ data, filteredData, setFilteredData, allColumns, columns, setColumns, tagId, setView, table }) => {


    const { TAGS, getTagList } = useContext(GlobalContext);

    //GET THE COLUMN BY ID
    const getColumnById = (id) => allColumns.find(d => d?.id === id);

    const getColumnIndex = (id) => allColumns.findIndex(d => d?.id === id);

    //CHECK IF ANY COLUMN IS HIDDEN
    const isColumnHidden = (key) => {
        if (columns.some(c => c?.id === key)) return false;
        return true;
    }

    //MASTER MANIPULATOR FUNCTION
    const handleManipulate = (manipulate_id, key, secondKey) => {
        switch (manipulate_id) {
            case 0: {
                let new_rows = [];
                if (isColumnHidden(key)) {
                    const column = getColumnById(key);
                    const index = getColumnIndex(key);
                    columns.splice(index, 0, column);
                    for (const [i, d] of filteredData.entries()) {
                        let obj = { ...d, [key]: data[i][key] };
                        new_rows.push(obj);
                    }
                    setColumns([...columns]);
                } else {
                    const new_columns = columns.filter(d => d?.id !== key);
                    for (const d of filteredData) {
                        let obj = { ...d };
                        delete obj[key];
                        new_rows.push(obj);
                    }
                    setColumns([...new_columns]);
                }
                setFilteredData([...new_rows]);
                return;
            }
            case 1: {
                let sorted_data;
                const { type } = getColumnById(key);
                if (type === "text") {
                    if (secondKey === 0) {
                        sorted_data = filteredData.sort((a, b) => a[key].localeCompare(b[key]));
                    } else {
                        sorted_data = filteredData.sort((a, b) => b[key].localeCompare(a[key]));
                    }
                } else if (type === "number") {
                    if (secondKey === 0) {
                        sorted_data = filteredData.sort((a, b) => a[key] - b[key]);
                    } else {
                        sorted_data = filteredData.sort((a, b) => b[key] - a[key]);
                    }
                } else if (type === "date") {
                    if (secondKey === 0) {
                        sorted_data = filteredData.sort((a, b) => new Date(a[key]) - new Date(b[key]));
                    } else {
                        sorted_data = filteredData.sort((a, b) => new Date(b[key]) - new Date(a[key]));
                    }
                }
                setFilteredData([...sorted_data]);
                return;
            }
            case 3: {
                setColumns([...allColumns]);
                setFilteredData([...data]);
                return;
            }
            case 4: {
                setView(key);
                return;
            }
            case 5: {
                if (!tagId) return;
                let new_rows = data.filter(d => d[tagId]?.includes(key));
                setFilteredData([...new_rows]);
            }
        }
    }

    //MENU ITEMS DEFINITION
    const menu_items = [
        {
            title: "Fields",
            icon: <EyeInvisibleFilled />,
            onClick: () => { },
            menuItems: allColumns.map(d => ({ key: d?.id, label: d?.title || d, onClick: () => handleManipulate(0, d?.id || d) }))
        },
        {
            title: "Sort",
            icon: < SortAscendingOutlined />,
            onClick: () => { },
            menuItems: columns.map(d =>
            ({
                key: d?.id, label: <Checkbox>{d?.title}</Checkbox>,
                menuItems: [{ key: 0, label: "A to Z", onClick: () => handleManipulate(1, d?.id, 0) }, {
                    key: 1, label: "Z to A", onClick: () => handleManipulate(1, d?.id, 1)
                }]
            }))
        },
        // {
        //     title: "Filter By Tags",
        //     icon: <FilterOutlined />,
        //     onClick: () => { },
        //     menuItems: getTagList(table).map(tag => ({
        //         key: tag?.disabled,
        //         label: tag?.title,
        //         onClick: () => handleManipulate(5, tag?.id)
        //     }))
        // },
        {
            title: "Clear All",
            icon: <CloseCircleOutlined style={{ color: "red" }} />,
            onClick: () => handleManipulate(3)
        },
        // {
        //     title: "Change View",
        //     icon: <FundViewOutlined />,
        //     onClick: () => { },
        //     menuItems: [
        //         { label: "Kanban", key: 0, onClick: () => handleManipulate(4, 0) },
        //         { label: "Gallary", key: 1, onClick: () => handleManipulate(4, 1) },
        //     ]
        // },
        // {
        //     title: 'More',
        //     icon: <MoreOutlined />,
        //     onClick: () => { },
        //     menuItems: [{ key: 1, label: "Download as CSV" }, { key: 2, label: "Upload CSV" }]
        // }
    ];

    //SEARCH HANDLER
    const onSearchHandler = (e) => {
        const value = e.target.value;
        const filtered_data = data.filter((d, i) => {
            return Object.values(d).some((v, i) => v?.toString()?.includes(value?.trim()))
        });
        setFilteredData([...filtered_data]);
    }


    return (
        <div className='flex items-center justify-between permissions__nav container'>
            <Input prefix={<SearchOutlined />} placeholder="Search columns" style={{ minWidth: 300, maxWidth: 400 }} onChange={onSearchHandler} />
            <div className="flex-1 flex items-center">
                {
                    menu_items.map((item, index) => item.menuItems ?
                        <Dropdown overlay={(
                            <Menu>
                                {
                                    item.menuItems.map(({ label, key, onClick, menuItems }) =>
                                        <Menu.Item key={key} onClick={e => !!onClick ? onClick() : e.preventDefault()}
                                            className={`${item?.title === "Fields" && (isColumnHidden(key) && "bg-light")}`}>
                                            {
                                                menuItems ?
                                                    <Dropdown overlay={(
                                                        <Menu>
                                                            {
                                                                menuItems.map(({ label, key, onClick }) => (
                                                                    <Menu.Item key={key} onClick={onClick}>{label}</Menu.Item>
                                                                ))
                                                            }
                                                        </Menu>
                                                    )} placement="bottomLeft">
                                                        {label}
                                                    </Dropdown> :
                                                    label
                                            }
                                        </Menu.Item>)
                                }
                            </Menu>
                        )} placement="bottomCenter" key={index}>
                            <div className='flex items-center fw-600 ml-20 cursor-pointer'>
                                {item.icon}
                                <p className='ml-10 text-nowrap '>{item.title}</p>
                            </div>
                        </Dropdown>
                        : <MenuItem title={item?.title} icon={item?.icon} key={index} onClick={item?.onClick} />
                    )
                }
            </div>
        </div>
    )
}

export default TableManipulator

const MenuItem = ({ title, icon, onClick }, index) => {
    return (
        <div className='flex items-center fw-600 ml-20 cursor-pointer' onClick={onClick}>
            {icon}
            <p className='ml-10 text-nowrap '>{title}</p>
        </div>
    )
}