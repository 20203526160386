import { DeleteOutlined, ExportOutlined, FilterOutlined, ImportOutlined, MenuOutlined, PlusOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React, { useContext, useMemo, useState } from 'react';
import './permissions.scss';
import { db } from '../../firebase';
import { doc, setDoc } from 'firebase/firestore';
import GlobalContext from '../../store/GlobalContext';
import { USERGROUP_DOC_ID } from '../../helpers/Constants';
import Users from '../users/components/Users';
import UserGroups from '../users/components/UserGroups';



const PermissionManager = () => {

    const [loading, setLoading] = useState(false);
    const { USERS, usersLoading, USER_GROUPS, getUserById, setUserGroups, getUserGroupByUserId } = useContext(GlobalContext);


    const menu_items = [
        {
            title: "Add",
            icon: <PlusOutlined />,
            onClick: () => { }
        },
        {
            title: "Import",
            icon: < ImportOutlined />,
            onClick: () => { }
        },
        {
            title: "Invitees",
            icon: <UsergroupAddOutlined />,
            onClick: () => { }
        },
        {
            title: 'Export',
            icon: <ExportOutlined />,
            onClick: () => { }
        },
        {
            title: "Delete via CSV",
            icon: <DeleteOutlined />,
            onClick: () => { }
        },
        {
            title: "Filter",
            icon: <FilterOutlined />,
            onClick: () => { }
        }
    ];

    return (
        <Spin spinning={loading || usersLoading}>
            <div className='flex items-center justify-between permissions__nav '>
                <div className='flex items-center'>
                    {
                        menu_items.map((item, index) => <MenuItem title={item.title} onClick={item.onClick} icon={item.icon} key={index} />)
                    }
                </div>
                <div>
                    Total Users: <b>{USERS.length}</b>
                    <MenuOutlined className='ml-10' />
                </div>

            </div>
            <Users />
            <UserGroups />
        </Spin>
    )
}

export default PermissionManager


const MenuItem = ({ title, icon, onClick }, index) => {
    return (
        <div className='flex items-center fw-600 mr-20'>
            {icon}
            <p className='ml-10'>{title}</p>
        </div>
    )
}