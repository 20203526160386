import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend);

const options = {        
    cutout: 92
};

const DonutChart = ({ labels, data, colors, height, width ,classes,centered}) => {
  const donut_data = {
    labels: labels || [],
    datasets: [
      {
        data: data || [],
        backgroundColor: colors || [],
      },
    ],
  };

  return (
    <div
      style={{ height: !!height && height, width: !!width ? width : "250px" }}
      className={`${classes} ${!!centered && 'mx-auto'}`}
    >
      <Doughnut data={donut_data} options={options}/>
    </div>
  );
};

export default DonutChart;
