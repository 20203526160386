import React from "react";
import { Random } from "../../helpers/utils";
import styles from "./avatar.module.css";

const getLetters = (name) => {
  if (name) {
    const array = name.split(" ");
    const first = array[0]?.charAt(0);
    const second =
      array?.length === 1 ? array[0]?.charAt(1) : array[1]?.charAt(0);
    return first + second;
  } else {
    return "N/A";
  }
};

const Avatar = ({ name, image }) => {
  const colors = ["blue", "red", "#f9cf00", "#45c706", "#af04cf"];
  const letters = getLetters(name);
  return (
    <div
      style={{ background: colors[Random(0, colors.length - 1)] }}
      className={styles.avatar}
    >
      <p>{letters}</p>
    </div>
  );
};

export default Avatar;
