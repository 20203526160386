import { SearchOutlined } from "@ant-design/icons";
import { Col, Input, Modal, Row } from "antd";
import React, { useState } from "react";
import { useContext } from "react";
import Button from "../../../components/buttons";
import { ShowNotification } from "../../../helpers/utils";
import { NotificationType } from "../../../helpers/validators";
import GlobalContext from "../../../store/GlobalContext";
import ChecklistCard from "./ChecklistCard";

const AttachChecklistModal = ({ open, onClose ,onSelect}) => {
  const { CHECKLISTS } = useContext(GlobalContext);
  const [searchVal,setSearchVal]=useState('');
  const [selected,setSelected] = useState([]);

  const onChecklistClick = (id) =>{
    if(selected.includes(id)){
      const index = selected.findIndex(d=>d===id);
      selected.splice(index,1);
    }else{
      selected.push(id);
    }
    setSelected([...selected]);
  }

  const onSubmit = () =>{
    if(!selected.length){
      ShowNotification("Error!","Please select atleast one checklist",NotificationType[1]);
      return;
    }
    onSelect(selected);
  }

  return (
    <Modal visible={open} onClose={onClose} width="min(1000px,95%)" footer={false}>
       <h1>Choose a Checklist template</h1>
       <Row>
        <Col xs={24} md={12}>
        <Input value={searchVal} onChange={e=>setSearchVal(e.target.value)} placeholder="search by checklist name" suffix={<SearchOutlined/>}/>
        </Col>
       </Row>
      <Row xs={24} className="mt-20">
        {CHECKLISTS.filter(d=>!searchVal || d.title.toLowerCase().includes(searchVal.toLowerCase())).map((checklist) => (
          <Col xs={24} md={12} key={checklist.id}>
            <ChecklistCard key={checklist.id} checklist={checklist} selected={selected.includes(checklist.id)} onClick={onChecklistClick} attach/>
          </Col>
        ))}
        <div className="w-full frow-reverse items-center mt-20">
          <Button classes="mr-10 danger" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onSubmit} type="primary">
            Select
          </Button>
        </div>
      </Row>
    </Modal>
  );
};

export default AttachChecklistModal;
