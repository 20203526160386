import { Button as AntButton, Row, Spin } from 'antd'
import React, { useContext, useState } from 'react'
import TabSwitchButton from '../../components/buttons/TabSwitchButton'
import GlobalContext from '../../store/GlobalContext';
import UserGroups from './components/UserGroups';
import Users from './components/Users';
import emailjs from '@emailjs/browser';
import AddNewButton from '../../components/buttons/AddNewButton';
import { MailOutlined, UsergroupAddOutlined, UserOutlined } from '@ant-design/icons';
import InviteUserModal from './components/InviteUserModal';
import { EMAIL_PUBLIC_KEY, EMAIL_SERVICE_ID, INVITE_USER_TEMPLATE_ID } from '../../helpers/Constants';
import { ShowNotification } from '../../helpers/utils';
import { NotificationType } from '../../helpers/validators';

const UserManagement = () => {
    const [tab, setTab] = useState(0);
    const { usersLoading,tenantId } = useContext(GlobalContext);
    const [inviteUserModalOpens,setInviteUserModalOpen]=useState(false);
    const [addUserGroupModalOpen, setUserGroupModalOpen] = useState(false);
    const [loading,setLoading]=useState(false);

    const onInviteUser = async(email) =>{
        if(!email) return;
        try{
        setLoading(true);
        const data ={email:email,url:window.location.origin+"?tenant="+tenantId};
  
        await emailjs.send(EMAIL_SERVICE_ID,INVITE_USER_TEMPLATE_ID, data,EMAIL_PUBLIC_KEY);
  
        ShowNotification("Success!", "User Invited", NotificationType[0]);
        setLoading(false);
        }catch(err){
            ShowNotification("Error!",err.message || "Failed to invite user", NotificationType[1]);
            setLoading(false);
        }
    }

    return (
        <Spin spinning={usersLoading || loading} >
            <h1>User Management</h1>
            <Row align='center' justify='space-between' gutter={[0, 10]}>
                <div className='flex items-center'>
                    <TabSwitchButton selected={tab === 0} onClick={() => setTab(0)} classes="small"><UserOutlined style={{fontSize:22}}/></TabSwitchButton>
                    <TabSwitchButton selected={tab === 1} onClick={() => setTab(1)} classes="small"><UsergroupAddOutlined style={{fontSize:22}}/></TabSwitchButton>
                </div>
                <div className='flex items-center'>
                    {tab===0 && <AddNewButton icon={<MailOutlined/>} onClick={()=>setInviteUserModalOpen(true)}>Invite User</AddNewButton>}
                    {tab === 1 && <AddNewButton onClick={() => setUserGroupModalOpen(true)}>Add User Group</AddNewButton>}
                </div>
            </Row>
            {tab === 0 && <Users />}
            {tab === 1 && <UserGroups modalOpen={addUserGroupModalOpen} onModalClose={() => setUserGroupModalOpen(false)} setModalOpen={setUserGroupModalOpen} />}
            <InviteUserModal open={inviteUserModalOpens} onClose={()=>setInviteUserModalOpen(false)} onInvite={onInviteUser}/>
        </Spin>
    )
}

export default UserManagement

