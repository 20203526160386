import {
  ArrowUpOutlined,
  BookOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { Col, Progress, Row, Select, Spin } from "antd";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import GlobalContext from "../../../store/GlobalContext";
import CountUp from "react-countup";
import BarChart from "../../../components/graphs/BarChart";
import DonutChart from "../../../components/graphs/DonutChart";
import AreaChart from "../../../components/graphs/AreaChart";
import RedCross from "../../../components/icons/RedCross";
import {
  getActivityDistribution,
  getMachinesOverTime,
  getOrderStatsOverTime,
  getSubOrderStatsOverTime,
  getUsersOverTime,
  totalCountWithSpan,
  userActivityDistribution,
} from "./dashboard-utils";
import { EVENT_TYPE } from "../../../helpers/Constants";

const span_options = [
  { title: "All Time", id: "all_time" },
  { title: "This Week", id: "this_week" },
  { title: "This Month", id: "this_month" },
  { title: "Last Month", id: "last_month" },
  { title: "Last Week", id: "last_week" },
];

const MainDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState([]);
  const [activityDist, setActivityDist] = useState({ labels: [], data: [] });
  const [userStats, setUserStats] = useState({ labels: [], data: [] });
  const [orderStats, setOrderStats] = useState({ labels: [], data: [] });
  const [subOrderStats, setSubOrderStats] = useState({ labels: [], data: [] });
  const [activityType,setActivityType] = useState();
  const [userActivityStats, setUserActivityStats] = useState({
    labels: [],
    data: [],
  });
  const [assetStats, setAssetStats] = useState({ labels: [], data: [] });
  const [activityStats, setActivityStats] = useState({ labels: [], data: [] });
  const [option, setOption] = useState("all_time");

  const {
    CHECKLISTS,
    VENDORS,
    ASSETS,
    MAINTENANCE,
    LOCATIONS,
    ASSET_GROUPS,
    USER_GROUPS,
    ORDERS,
    USERS,
  } = useContext(GlobalContext);


  useEffect(()=>{
  const user_activity_stats = userActivityDistribution(MAINTENANCE, USERS,activityType);
  setUserActivityStats(user_activity_stats);
  },[activityType]);

  useEffect(() => {
    if (
      ASSET_GROUPS &&
      USER_GROUPS &&
      MAINTENANCE &&
      CHECKLISTS &&
      ORDERS &&
      VENDORS &&
      LOCATIONS &&
      USERS.length
    ) {
      setLoading(false);
      const distribution = getActivityDistribution(MAINTENANCE);
      const users_data = getUsersOverTime(USERS);
      const order_stats = getOrderStatsOverTime(ORDERS);
      const suborder_stats = getSubOrderStatsOverTime(ORDERS);
      const asset_stats = getMachinesOverTime(ASSETS);
      const activity_stats = getMachinesOverTime(MAINTENANCE);
      const user_activity_stats = userActivityDistribution(MAINTENANCE, USERS);
      setActivityStats(activity_stats);
      setAssetStats(asset_stats);
      setSubOrderStats(suborder_stats);
      setOrderStats(order_stats);
      setActivityDist(distribution);
      setUserStats(users_data);
      setUserActivityStats(user_activity_stats);
      const dashboard_data = [
        {
          title: "Total Assets",
          id: "assets",
          count: totalCountWithSpan(12, ASSETS, option),
          color: "#50C878",
          percent: (asset_stats.data[5] / ASSETS.length) * 100,
        },
        {
          title: "Total Activities",
          id: "vendors",
          count: totalCountWithSpan(18, MAINTENANCE, option),
          color: "#00BBFF",
          percent: (activity_stats.data[5] / MAINTENANCE.length) * 100,
        },
        {
          title: "Total Asset Groups",
          id: "asset_groups",
          allTime: true,
          count: Object.keys(ASSET_GROUPS).length,
          color: "#40E0D0",
        },
        {
          title: "Total Orders",
          id: "orders",
          count: totalCountWithSpan("start", ORDERS, option),
          color: "#50C878",
          percent: (order_stats.data[5] / ORDERS.length) * 100,
        },
        {
          title: "Total Locations",
          id: "locations",
          count: totalCountWithSpan("createdOn", LOCATIONS, option),
          color: "#00BBFF",
        },
        {
          title: "Total Users",
          id: "users",
          count: totalCountWithSpan("createdAt", USERS, option),
          color: "#40E0D0",
          percent: (users_data.data[5] / USERS.length) * 100,
        },
        {
          title: "Total Checklists",
          id: "checklists",
          count: totalCountWithSpan("createdOn", CHECKLISTS, option),
          color: "#50C878",
        },
        {
          title: "Vendors",
          id: "vendors",
          count: totalCountWithSpan("createdOn", VENDORS, option),
          color: "#00BBFf",
        },
      ];
      setStats([...dashboard_data]);
    }
  }, [
    ASSET_GROUPS,
    MAINTENANCE,
    ORDERS,
    USERS,
    USER_GROUPS,
    VENDORS,
    CHECKLISTS,
    option,
  ]);

  const getValidDonutData = (stats) => {
    let labels = [];
    let data = [];
    stats.data.forEach((d, i) => {
      if (+d > 0) {
        labels = [...labels, stats.labels[i]];
        data = [...data, d];
      }
    });
    return { labels, data };
  };

  return (
    <Spin spinning={loading} className="container">
      <div>
        <Select
          suffixIcon={
            <CalendarOutlined style={{ color: "#21b394", fontSize: "18px" }} />
          }
          className="w-200 fw-500"
          value={option}
          onChange={setOption}
        >
          {span_options.map(({ title, id }) => (
            <Select.Option key={id} value={id}>
              {title}
            </Select.Option>
          ))}
        </Select>
      </div>
      <Row gutter={[24, 24]} className="mt-20">
        {stats.map((data, i) => (
          <Col sm={24} md={12} lg={6} key={data.id}>
            <div className="dashboard__card w-full">
              <h3>{data.title}</h3>
              <div className="float-right text-right mt-20">
                <p className="subTitle">
                  {data.allTime
                    ? "All Time"
                    : span_options.find((d) => d.id === option).title}
                </p>
                <h1 className="count">
                  <ArrowUpOutlined style={{ color: data.color }} />
                  <CountUp end={data.count} duration={2} />
                </h1>
              </div>
              <Progress
                className="mt-10"
                percent={data.percent ? data.percent.toFixed(0) : 0}
                strokeColor={data.color}
                style={{ fontWeight: 600 }}
              />
            </div>
          </Col>
        ))}
        <Col xs={24} lg={12}>
          <div className="dashboard__card w-full">
            <h3>Users</h3>
            <BarChart
              labels={userStats.labels}
              data={userStats.data}
              width="min(700px,90%)"
              classes="mt-1 ml-20"
            />
          </div>
        </Col>
        <Col xs={24} lg={12}>
          <div className="dashboard__card w-full">
            <div className="flex items-center">
              <h3>User Activities</h3>
              <Select
                suffixIcon={
                  <BookOutlined
                    style={{ color: "#21b394", fontSize: "18px"}}
                  />
                }
                className="w-200 fw-500 ml-20"
                value={activityType}
                onChange={setActivityType}
              >
                {Object.entries(EVENT_TYPE).map(([ key,value ]) => (
                  <Select.Option key={key} value={key}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
              {!!activityType && <RedCross onClick={()=>setActivityType()} classes="ml-1 cursor-pointer"/>}
            </div>
            <BarChart
              labels={userActivityStats.labels}
              data={userActivityStats.data}
              width="min(700px,90%)"
              classes="mt-1 ml-20"
              horizontal
            />
          </div>
        </Col>
        <Col xs={24} lg={8}>
          <div className="dashboard__card w-full">
            <h3>Activity Distribution</h3>
            <DonutChart
              labels={activityDist.labels}
              data={activityDist.data}
              colors={["#40e0d0", "#1890ff", "#f59aff", "#eee", "#fd4444"]}
              width="fit-content"
              height="300px"
              classes="mt-20 mb-10 "
              centered
            />
          </div>
        </Col>
        <Col xs={24} lg={8}>
          <div className="dashboard__card w-full">
            <h3>Activity Distribution</h3>
            <DonutChart
              labels={activityDist.labels}
              data={activityDist.data}
              colors={["#40e0d0", "#1890ff", "#f59aff", "#eee", "#fd4444"]}
              width="fit-content"
              height="300px"
              classes="mt-20 mb-10 "
              centered
            />
          </div>
        </Col>
        <Col xs={24} lg={8}>
          <div className="dashboard__card w-full">
            <h3>User Activity Distribution</h3>
            <DonutChart
              labels={getValidDonutData(userActivityStats).labels}
              data={getValidDonutData(userActivityStats).data}
              colors={["#40e0d0", "#1890ff", "#f59aff", "#eee", "#fd4444"]}
              width="fit-content"
              height="300px"
              classes="mt-20 mb-10 "
              centered
            />
          </div>
        </Col>
        {/* <Col xs={24} lg={12}>
          <div className="dashboard__card w-full">
            <h3>Orders</h3>
            <AreaChart
              labels={orderStats.labels}
              data={orderStats.data}
              width="max(280px,90%)"
              classes="mt-30"
              centered
            />
          </div>
        </Col>
        <Col xs={24} lg={12}>
          <div className="dashboard__card w-full">
            <h3>Sub-Orders</h3>
            <AreaChart
              labels={subOrderStats.labels}
              data={subOrderStats.data}
              width="max(280px,90%)"
              classes="mt-30"
              centered
              color="#f59aff40"
              border="#f59aff"
            />
          </div>
        </Col> */}
        <Col xs={24} lg={12}>
          <div className="dashboard__card w-full">
            <h3>Machines</h3>
            <AreaChart
              labels={assetStats.labels}
              data={assetStats.data}
              width="max(280px,90%)"
              classes="mt-30"
              centered
              color="#f59aff40"
              border="#f59aff"
            />
          </div>
        </Col>
        <Col xs={24} lg={12}>
          <div className="dashboard__card w-full">
            <h3>Activities</h3>
            <AreaChart
              labels={activityStats.labels}
              data={activityStats.data}
              width="max(280px,90%)"
              classes="mt-30"
              centered
            />
          </div>
        </Col>
      </Row>
    </Spin>
  );
};

export default MainDashboard;
