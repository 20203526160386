import { addDoc, arrayUnion, collection, doc, setDoc } from "firebase/firestore";
import { db } from "../firebase";
import moment from 'moment';

export const LOG_TYPES = {
    ADDED: "added",
    UPDATED: "updated",
    DELETED: "deleted",
    COMMENTED: "commented"
}

export const LOG_TABLES = {
    ASSETS: "assets"
}

export const logActivity = async (table, who, what, type, documentId, base) => {
    const whoPerson = who || "An Anonymous user";
    const date = moment().format("DD-MM-YYYY hh:mm A");
    const string = `${whoPerson} ${type} ${what}`;
    const data = {
        date:date,
        message:string
    }
    await setDoc(doc(db, `${base}/logs`, table, `${table}_logs`, documentId), { logs:arrayUnion(data) }, { merge: true });
    return data;
}

