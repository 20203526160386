import { Col, Input, TimePicker } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import moment from "moment";

const DateTimePicker = ({ classes, value,onChange }) => {
  const [date, setDate] = useState('');

  useEffect(()=>{
   if(!!value){
    setDate(value);
   }
  },[value]);

  const addTime = (val,time) => {
    if (!date) return;
    const newDate = date + " " + time;
    setDate(newDate);
    onChange(newDate);
  };

  const defaultDate = (date) => {
    if (!!date || date?.split(" ").length === 3) {
      const dateSplit = date.split(" ");
      return moment(dateSplit[1] + " " + dateSplit[2], "hh:mm A");
    } else {
      return moment("12:00 AM", "hh:mm A");
    }
  };

  const onChangeDate = (e) =>{
    setDate(e.target.value);
    onChange(e.target.value);
  }

  return (
      <div className={`flex ${classes}`}>
        <Input
          className="w-50p"
          type="date"
          value={date.split(" ")[0]}
          onChange={onChangeDate}
        />
        <TimePicker
          defaultValue={defaultDate(date)}
          format="hh:mm A"
          className="w-50p ml-10"
          onChange={addTime}
        />
      </div>
  );
};

export default DateTimePicker;
