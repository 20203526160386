import React from 'react';
import './divider.css';


const Divider = ({text,light}) => {
  return<div className='divider' style={{color: light && "white"}}>
      <div className='divider__left'></div>
     <div><b>{text}</b></div>
      <div className='divider__right'></div>
  </div>
}

export default Divider