import { Input, Modal } from "antd";
import React from "react";
import { useState } from "react";
import Button from "../../../components/buttons";
import Upload from "../../../components/UI/Upload";
import { ShowNotification } from "../../../helpers/utils";
import { NotificationType } from "../../../helpers/validators";

const AddFolderModal = ({ open, onClose, onSubmit }) => {
  const [name, setname] = useState("");
  // const [files, setFiles] = useState("");

  const onModalClose = () => {
    setname("");
    onClose();
  };

  const handleSubmit = async () => {
    await onSubmit(name);
    onModalClose();
  };


  return (
    <Modal
      title={false}
      footer={false}
      visible={open}
      width={"min(100%,400px)"}
      centered
      onCancel={onModalClose}
    >
      <h2 className="fw-600">Add New Folder</h2>

      <p className="fw-600 fs-14">Enter Folder Name</p>
      <Input
        className="w-full"
        value={name}
        onChange={(e) => setname(e.target.value)}
      />
      {/* <p className="fw-600 fs-14 mt-10">Upload File</p>
      <Input type="file" id="upload" onChange={onFileChange} hidden />
      <Upload files={files} readOnly />
      <Button onClick={onUploadClick} classes="w-full mt-20" type="primary">
        Upload File
      </Button> */}
      <Button onClick={handleSubmit} classes="w-full mt-20" type="primary">
        Add
      </Button>
    </Modal>
  );
};

export default AddFolderModal;
