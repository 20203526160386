import React, { useState } from 'react';
import styles from './common.module.scss';
import cx from 'classnames';
import RedCross from '../icons/RedCross';
import { Image as AntImage } from 'antd';
import { useEffect } from 'react';
import axios from 'axios';

const Image = ({src,classes,onCross,readOnly,big}) => {

const source = !!src ? (typeof src === "string" ? src : URL.createObjectURL(src)) : "https://static.wixstatic.com/media/09561e_48204e269f4e47efafa8736f098cac51~mv2_d_1500_1500_s_2.png/v1/crop/x_32,y_0,w_1436,h_1500/fill/w_596,h_622,al_c,q_90,usm_0.66_1.00_0.01/no-img-placeholder.webp";
  return (
    <div style={{position:"relative"}}>
    <AntImage onClick={e=>{
      e.stopPropagation()}} className={cx(styles.imagePrev,{[styles.big]:!!big},{[classes]:!!classes})}  src={source} alt="image"/>
    {!readOnly && <RedCross onClick={onCross} style={{position:"absolute",top:"-5px",right:"-5px"}}/>}
    </div>
  )
}

export default Image
