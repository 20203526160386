import { Modal } from 'antd'
import React from 'react'
import ChecklistCard from './ChecklistCard'

const ChecklistDetails = ({open,onClose,data}) => {
  return (
   <Modal
    visible={open}
    onCancel={onClose}
    width="min(500px,98%)"
    footer={false}
   >
   <ChecklistCard checklist={data} onClick={()=>false} selected={false} modal={true}/>
   </Modal>
  )
}

export default ChecklistDetails