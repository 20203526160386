import { DeleteFilled, EditFilled, InfoCircleOutlined, InfoOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import ConfirmationModal from '../../../components/confirmationModal';
import Tag from '../../../components/tag';

const roles = [
    { id: 0, title: "owner", description: "can add/remove creators. And fully edit database sturctures and fields." },
    { id: 1, title: "creator", description: "Can fully edit database sturctures and values." },
    { id: 2, title: "editor", description: "Can edit records but cannot change the sturucture of database fields!" },
    { id: 3, title: "commentor", description: "can view and comment on the records but cannot edit anything." },
    { id: 4, title: "viewer", description: "can view the records but cannot edit anything" },
]

const groups_data = [
    { id: 0, title: "Group 1", role: 0 },
    { id: 1, title: "Group 2", role: 1 },
    { id: 2, title: "Group 3", role: 2 },
    { id: 3, title: "Group 4", role: 3 },
    { id: 4, title: "Group 5", role: 4 },
]

const UserGroups = () => {

    const [groups, setGroups] = useState([]);
    const [deleteState, setDeleteState] = useState({ visible: false });
    const [editState, setEditState] = useState({
        open: false,
        id: null
    });

    useEffect(() => {
        const all_groups = [...groups_data];
        setGroups([...all_groups]);
    }, []);

    //WHEN USER CLICKS ON THE EDIT BUTTONS IN A ROW
    const onClickEdit = (group_id) => {
        setEditState({
            open: true,
            id: group_id,
            new_role: groups.find(g => g.id === group_id)?.role
        })
    }

    //TO DELETE A GROUP
    const onDeleteOkayHandler = () => {
        const index = groups.findIndex(d => d?.id === deleteState?.id);
        if (index < 0) return;
        groups.splice(index, 1);
        setGroups([...groups]);
        setDeleteState({ visible: false });
    }

    //TO OPEN THE CONFIRMATION MODAL TO DELETE A GROUP
    const onDeleteClick = (id) => {
        const group = groups.find(g => g.id === id);
        setDeleteState({
            visible: true,
            title: `Are you sure you want to delete ${group?.title} ?`,
            id: id
        });
    }

    //TO EDIT A GROUP
    const editHandler = () => {
        const { new_role, id } = editState;
        const index = groups.findIndex(d => d.id === id);
        groups[index].role = new_role;
        setGroups([...groups]);
        onEditModalCancel();
    }

    //TO CANCEL THE EDITING OF  GROUP
    const onEditModalCancel = () => setEditState({ open: false });

    return (
        <div>
            <ConfirmationModal
                onClose={() => setDeleteState({ visible: false })}
                onOk={onDeleteOkayHandler}
                {...deleteState}
            />
            <Modal
                title={false}
                footer={false}
                visible={editState?.open}
                width={370}
                centered
                onCancel={onEditModalCancel}
            >
                <h1>Edit Groups</h1>
                <Input value={groups.find(gr => gr.id === editState?.id)?.title} disabled={true} />
                <p className='mt-10'>Select New Role</p>
                <Select
                    value={editState?.new_role}
                    onChange={val => setEditState({ ...editState, new_role: val })}
                    className="w-full"
                >
                    {
                        roles.map((role, i) => <Select.Option value={role.id} key={i}>{role.title}</Select.Option>)
                    }
                </Select>
                <p className='opacity-50 mt-10'>* {roles.find(r => r.id === editState?.new_role)?.description}</p>
                <div className='cancelSave__buttons'>
                    <Button primary>Cancel</Button>
                    <Button type="primary" primary onClick={editHandler}>Save</Button>

                </div>
            </Modal>
            <table
                className='userData__table'>
                <thead>
                    <td className='pl-2'>Groups</td>
                    <td>Role Name</td>
                    <td>Role Description</td>
                    <td className='text-center'>Info</td>
                    <td className='text-center'>Edit</td>
                    <td className='text-center'>Delete</td>
                </thead>
                <tbody>
                    {
                        groups.map((row, index) => <UserGroupsRow row={row} key={index} onClickEdit={onClickEdit} onDeleteClick={onDeleteClick} />)
                    }
                </tbody>
            </table>
        </div>
    )
}

export default UserGroups

const UserGroupsRow = ({ row, onClickEdit, onDeleteClick }) => {

    const { id, title, role: role_id } = row;
    const role = roles.find(role => role?.id === role_id);

    return (
        <tr>
            <td>{title}</td>
            <td><Tag>{role?.title}</Tag></td>
            <td>{role?.description}</td>
            <td className='text-center '>
                <InfoCircleOutlined style={{ color: "lightgray", fontSize: 20 }} />
            </td>
            <td className='text-center '>
                <EditFilled onClick={() => onClickEdit(id)} style={{ color: "#1164a3", fontSize: 20 }} />
            </td>
            <td className='text-center '>
                <DeleteFilled onClick={() => onDeleteClick(id)} style={{ color: "red", fontSize: 20 }} />
            </td>
        </tr>
    )
}