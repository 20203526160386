import React, { useContext } from 'react';
import {Select} from 'antd';
import GlobalContext from '../../../store/GlobalContext';


const UsersSelect = ({onChange,value,placeholder}) => {

    const { USERS } = useContext(GlobalContext);

  return (
        <Select
            className="w-full"
            value={!value ? [] : value?.split(",")}
            mode="multiple"
            allowClear
            placeholder={placeholder}
            onChange={(e) => onChange(e?.join(","))}
        >
            {USERS.length && USERS.map(({ displayName, id }) => (
                <Select.Option key={id} value={id}>
                    {displayName}
                </Select.Option>
            ))}
        </Select>
  )
}

export default UsersSelect