import React, { useContext } from 'react'
import GlobalContext from '../../store/GlobalContext';
import UnauthorizedView from '../../components/UI/UnauthorizedView';
import MainDashboard from './components/MainDashboard';
import './dashboard.scss';

const Dashboards = () => {

    const { amIApproved } = useContext(GlobalContext);


    if(amIApproved===false){
        return <UnauthorizedView/>
    }

    return (
        <MainDashboard/>
    )
}

export default Dashboards

