import React, { useState } from 'react';
import { Radio } from 'antd';
import PermissionManager from '../permissionManager';
import ModuleAccess from './components/ModuleAccess';
import UserGroups from './components/UserGroups';
import './configuration.scss';

const radio_tabs = [
    {
        title: "Module Access",
        value: 0
    }, {
        title: "Permission Manager",
        value: 1
    }, {
        title: "User Groups",
        value: 2
    }, {
        title: "Dashboard and Device Management",
        value: 3
    }
]

const ConfigurationManager = () => {

    const [tabSelected, setTabSelected] = useState(0);

    return (
        <div>
            <h1 className="mt-10">Configuration Wizard</h1>
            <Radio.Group className="mt-10 radio-tabs__configManager" value={tabSelected} onChange={e => setTabSelected(e.target.value)} buttonStyle="solid">
                {
                    radio_tabs.map(({ title, value }) => <Radio.Button value={value}>{title}</Radio.Button>)
                }
            </Radio.Group>
            <div className='mt-30'>
                {
                    tabSelected === 0 && <ModuleAccess />
                }
                {
                    tabSelected === 1 && <PermissionManager />
                }
                {
                    tabSelected === 2 && <UserGroups />
                }
                {
                    tabSelected === 3 && <></>
                }
            </div>
        </div>
    )
}

export default ConfigurationManager