import React, { useContext } from "react";
import GlobalContext from "../../../store/GlobalContext";
import { Select } from "antd";

const AssetsSelect = ({
  value,
  onChange,
  classes,
  disabled,
  placeholder,
  groupFilter,
}) => {
  const { ASSETS } = useContext(GlobalContext);

  return (
    <Select
      value={value}
      onChange={(val) => onChange(val)}
      className={`w-full ${classes}`}
      disabled={disabled}
      placeholder={placeholder}
    >
      {ASSETS?.length ? (
        ASSETS?.filter((d) =>
          !!groupFilter ? d[11] == groupFilter : true
        ).map((item, i) => (
          <Select.Option key={item?.id} value={item?.id}>
            {item[2]}
          </Select.Option>
        ))
      ) : (
        <Select.Option disabled={true}>No Asset Added</Select.Option>
      )}
    </Select>
  );
};

export default AssetsSelect;
