import { CloseOutlined } from '@ant-design/icons'
import React from 'react';
import styles from './icons.module.css';
import cx from 'classnames';

const RedCross = ({classes,...rest}) => {
  return (
        <div className={cx(styles.redCross,classes)} {...rest}>
            <CloseOutlined/>
        </div>
  )
}

export default RedCross