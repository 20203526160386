import { notification } from 'antd';
import moment from 'moment';
import { getUserFromStore } from '../api/auth';
import { NotificationType } from './validators';

export const ShowNotification = (title, description, type) => {
  notification.open({
    style: { backgroundColor: type === NotificationType[0] ? "#06ba39" : "red", color: "white" },
    message: <h3 style={{ color: "white" }}>{title}</h3>,
    description: description,
  });
}

export const SecureCall = async (func, onSuccess, successTitle, errTitle) => {
  try {
    const response = await func();
    onSuccess(response);
    // ShowNotification("Success!", successTitle, NotificationType[0]);
    return response;
  } catch (err) {
    const title = !!errTitle ? errTitle : "Error!";
    const message = err?.response?.data?.message || "Something might went wrong";
    const status = err?.response?.data?.status || 500;
    // ShowNotification(title, message, NotificationType[1]);
    return { status, message, error: true };
  }
}

export function Random(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function Base64(file, cb) {

  var reader = new FileReader();

  let base64String = '';

  reader.onload = function () {
    base64String = reader.result;
    cb(base64String);
  }
  reader.readAsDataURL(file);
}

export const dragArrayTransform = (array, min_length) => {
  let filtered = array?.length ? array : [];
  const diff = min_length - filtered?.length;
  if (diff > 0) {
    const array = Array(diff).fill(null);
    filtered = [...filtered, ...array];
  }
  return filtered;
}

export const getEmailDomain = (email) => {
  var domain = email.substring(email.lastIndexOf("@") + 1);
  return domain;
}

export const isMyLog = (log) => {
  const user = getUserFromStore();
  if (!user) return false;
  if (user?.email === log.split(" ")[0]) {
    return true;
  } else {
    return false;
  }
}

export function removeFirstWord(str) {
  const indexOfSpace = str.indexOf(' ');
  if (indexOfSpace === -1)  return '';
  return str.substring(indexOfSpace + 1);
}

export const firstWord = (str) =>{
  const split = str.split(' ');
  if(!split.length) return '';
  return split[0];
}


export const uppercase = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const diffInDays = (a,b) => moment(a).diff(moment(b), 'days');

export const split = (str,splitter) => !str ? [] : splitter ? str.split(splitter) : str.split(',');

