import { Col, Row, Spin } from "antd";
import { addDoc, collection } from "firebase/firestore";
import React, { useContext, useState } from "react";
import { checkFirebaseAuth } from "../../../api/auth";
import { db } from "../../../firebase";
import { TABLES } from "../../../store/global-types";
import GlobalContext from "../../../store/GlobalContext";
import OrdersCalender from "./OrdersCalender";
import OrdersList from "./OrdersList";
import moment from "moment";
import { useEffect } from "react";
import { deleteTableRows } from "../../../store/global-actions";

const OrdersMainPage = () => {
  const { BASE_URL, ORDERS, getOrders } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [subOrders, setSubOrders] = useState([]);
  const [screenState, setScreenState] = useState(0);

  useEffect(() => {
    setLoading(true);
    const unsubscribe = setTimeout(() => {
      setLoading(false);
    }, 4000);
    return () => clearTimeout(unsubscribe);
  }, []);

  const onCreateOrder = async (order) => {
    const auth = checkFirebaseAuth();
    if (!auth || !order) return;
    setLoading(true);
    const payload = {
      ...order,
      user: auth?.email,
      createdAt: moment().format("DD MMMM, YYYY  hh:mm A"),
    };
    await addDoc(collection(db, BASE_URL, TABLES.ORDERS), payload);
    await getOrders();
    setLoading(false);
  };

  const onCreateSuborder = async (data) => {
    const auth = checkFirebaseAuth();
    if (!auth || !data || !orderId) return;
    setLoading(true);
    const payload = {
      ...data,
      user: auth?.email,
      createdAt: moment().format("DD MMMM, YYYY  hh:mm A"),
    };
    await addDoc(
      collection(db, BASE_URL, TABLES.ORDERS, orderId, TABLES.SUBORDERS),
      payload
    );
    await getOrders();
    subOrders.push({ ...payload, id: Math.random() });
    setSubOrders([...subOrders]);
    setLoading(false);
  };

  const onDeleteOrder = async (id) => {
    if (!id) return;
    setLoading(true);
    await deleteTableRows(
      [id],
      TABLES.ORDERS,
      () => setLoading(false),
      () => setLoading(false),
      BASE_URL,
      false
    );
    await getOrders();
  };

  const onDeleteSuborder = async (orderId,id) =>{
    if (!id || !orderId) return;
    setLoading(true);
    await deleteTableRows(
      [id],
      `${TABLES.ORDERS}/${orderId}/${TABLES.SUBORDERS}`,
      () => setLoading(false),
      () => setLoading(false),
      BASE_URL,
      false
    );
    setTimeout(()=>{
      window.location.reload();
    },2000);
  }

  const onClickOrder = (id) => {
    setOrderId(id);
    subOrdersSetter(id);
  };

  const subOrdersSetter = (id) => {
    const sub_orders = ORDERS.find((d) => d.id === id)?.subOrders;
    setSubOrders([...sub_orders]);
  };

  return (
    <Spin spinning={loading}>
      <Row gutter={16}>
        <Col xs={24} className="mt-20">
          <h1>Production Planner</h1>
        </Col>
        <Col xs={24} lg={6} className="mt-20 orders">
          <OrdersList
            createOrder={onCreateOrder}
            orders={ORDERS}
            onClickOrder={onClickOrder}
            subOrders={subOrders}
            createSuborder={onCreateSuborder}
            deleteOrder={onDeleteOrder}
            deleteSuborder={onDeleteSuborder}
            screenState={screenState}
            setScreenState={setScreenState}
          />
        </Col>
        <Col xs={24} lg={18} className="mt-20" style={{overflowY:"auto"}}>
          <OrdersCalender orders={ORDERS} monthView={screenState===0}/>
        </Col>
      </Row>
    </Spin>
  );
};

export default OrdersMainPage;
