import { Layout, Menu } from "antd";
import "./sidebar.scss";
import React, { useContext, useState } from "react";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
  CodeOutlined,
  BuildOutlined,
  UsergroupAddOutlined,
  MoneyCollectOutlined,
  BookOutlined,
  DashboardOutlined,
  EnvironmentOutlined,
  UserSwitchOutlined,
  EyeInvisibleFilled,
  EyeFilled,
  CalendarOutlined,
  StopOutlined,
  CheckSquareOutlined,
  SettingFilled,
  SettingOutlined,
  SnippetsOutlined,
  NodeIndexOutlined,
  FundViewOutlined,
  ControlOutlined,
  FileSearchOutlined,
  BarChartOutlined,
  AppstoreAddOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ShowNotification } from "../../helpers/utils";
import { FirebaseLogout, getUserFromStore } from "../../api/auth";
import logo from "../../assets/png/sm-logo.png";
import brandLogo from "../../assets/png/brand-logo.png";
import { NotificationType } from "../../helpers/validators";
import { SM_TOKEN, IS_SM_LOGGED_IN } from "../../helpers/Constants";
import { SMF_TOKEN } from "../../helpers/Constants";
import { useEffect } from "react";
import Header from "./Header";
import GlobalContext from "../../store/GlobalContext";

const { Sider, Content } = Layout;

const SideBar = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [productionUrl, setProductionUrl] = useState("");
  const navigate = useNavigate();

  const menu_items = [
    {
      title: "Dashboard",
      icon: <AppstoreAddOutlined />,
      path: "/dashboard",
    },
    {
      title: "Assets",
      icon: <ControlOutlined />,
      path: "/assets",
      // hasChildren: true,
      // subOptions: [
      //   {
      //     title: "Machine lifecycle management",
      //     icon: <ToolOutlined />,
      //     path: "/machine-lifecycle-management"
      //   }, {
      //     title: "OEM Info",
      //     icon: <UsergroupAddOutlined />,
      //     path: "/oem-info"
      //   }
      // ]
    },
    {
      title: "Activity",
      icon: <BookOutlined />,
      path: "/maintenance",
    },
    // {
    //   title: "Production Planner",
    //   icon: <BarChartOutlined />,
    //   path: "/orders",
    // },
    // {
    //   title: "Live View",
    //   icon: <FundViewOutlined />,
    //   path: "/performance",
    // },
    // {
    //   title: "Production",
    //   icon: <NodeIndexOutlined />,
    //   onClick: () => !!productionUrl && window.open(productionUrl),
    //   path: "/production",
    // },
    {
      title: "Checklists",
      icon: <SnippetsOutlined />,
      path: "/checklists",
    },
    {
      title: "Users",
      icon: <UsergroupAddOutlined />,
      path: "user-management",
    },
    {
      title: "Locations",
      icon: <EnvironmentOutlined />,
      path: "/locations",
    },
    {
      title: "Vendors & Customers",
      icon: <UserSwitchOutlined />,
      path: "/vendors",
    },
    {
      title: "File Manager",
      icon: <FileSearchOutlined />,
      path: "files",
    },
    // {
    //   title:"Inspections",
    //   icon:<EyeFilled/>,
    //   path:"/inspection"
    // },
    // {
    //   title:"Tasks",
    //   icon:<CalendarOutlined/>,
    //   path:'/tasks'
    // },
    // {
    //   title:"Failure",
    //   icon:<StopOutlined/>,
    //   path:'/failures'
    // },
    {
      title: "Settings",
      icon: <SettingOutlined />,
      path: "/settings",
    },
  ];

  const logout = async () => {
    const fb = localStorage.getItem("isFbUser");
    localStorage.removeItem("fb_user");
    localStorage.removeItem("isFbUser");
    // localStorage.removeItem(SMF_TOKEN);
    // localStorage.removeItem(SM_TOKEN);
    localStorage.setItem(IS_SM_LOGGED_IN, false);
    if (fb === "true") {
      await FirebaseLogout();
      navigate("/", { replace: true });
      window.location.reload();
    } else {
      navigate("/", { replace: true });
      window.location.reload();
      ShowNotification(
        "Success!",
        "Successfully Logged out",
        NotificationType[1]
      );
    }
  };

  const [user, setUser] = useState(null);
  const { getTenantById, TENANTS, SETTINGS } = useContext(GlobalContext);

  useEffect(() => {
    const details = getUserFromStore();
    if (details) setUser(details);
  }, []);

  useEffect(() => {
    if (!TENANTS?.length || !user) return;
    const t = getTenantById(user?.tenantId);
    if (t) {
      user.tenantName = t.title;
      setUser({ ...user });
    }
  }, [TENANTS]);

  useEffect(() => {
    if (!SETTINGS.portals?.length) return;
    setProductionUrl(SETTINGS.portals[1]?.url);
  }, [SETTINGS]);

  useEffect(() => {
    if (onIframePage) {
      setCollapsed(true);
    }
  }, [window.location.pathname]);

  const onIframePage = window.location.pathname.includes("/performance");
  const isChecklistOpen = window.location.pathname.includes("/checklists");
  const onDashboard = window.location.pathname.includes("/dashboard");

  return (
    <Layout className="layout">
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo" />
        <Menu theme="light" mode="inline" defaultSelectedKeys={["1"]}>
          <div
            className={`menu-header ${
              !collapsed ? "justify-between" : "justify-center"
            }`}
            onClick={() => setCollapsed(!collapsed)}
          >
            {!collapsed && (
              <div className="flex-1 mr-30">
                <img src={logo} alt="logo" width="100%" height={70} />
              </div>
            )}
            {collapsed ? (
              <MenuUnfoldOutlined className="cursor-pointer c-black fs-16" />
            ) : (
              <MenuFoldOutlined className="cursor-pointer c-black fs-20" />
            )}
          </div>
          {menu_items.map(
            ({ title, icon, path, hasChildren, subOptions, onClick }) =>
              hasChildren ? (
                <Menu.SubMenu
                  // onClick={() => navigate(path)}
                  key={path}
                  icon={icon}
                  title={title}
                  className={`${
                    window.location.pathname === path
                      ? "ant-menu-item-selected"
                      : null
                  }`}
                >
                  {subOptions.map(({ title, path: subpath, icon }) => (
                    <Menu.Item
                      onClick={(e) => navigate(subpath)}
                      key={subpath}
                      icon={icon}
                    >
                      {title}
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              ) : (
                <Menu.Item
                  onClick={() => (!!onClick ? onClick() : navigate(path))}
                  key={path}
                  icon={icon}
                  className={`${
                    window.location.pathname.includes(path)
                      ? "ant-menu-item-selected"
                      : null
                  }`}
                >
                  {title}
                </Menu.Item>
              )
          )}
          <Menu.Item icon={<LogoutOutlined />} onClick={logout}>
            Logout
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Content
          className={`site-layout-background content ${
            onIframePage && "noPadding"
          } ${onDashboard && "bg-gray"}`}
        >
          {!onIframePage && <Header user={user} />}
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default SideBar;
