import { Modal } from "antd";
import React from "react";

const FilePreviewWindow = ({ open, onClose }) => {
  return (
    <Modal
      title={false}
      footer={false}
      visible={!!open}
      width={1000}
      centered
      onCancel={onClose}
    >
      <iframe src={open} width="100%" className="mt-20" height={700}></iframe>
    </Modal>
  );
};

export default FilePreviewWindow;
