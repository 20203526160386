import { FileAddFilled, PlusOutlined } from '@ant-design/icons'
import React, { useState } from 'react'
import styles from './common.module.scss';
import cx from 'classnames';
import { Upload } from 'antd';
import Image from './Image';
import { Base64 } from '../../helpers/utils';

const UploadImage = ({readOnly,files,setFiles,classes}) => {

  const changeFiles = (res) =>{
   const file = res.file;
   if (file)
       Base64(file, (result) => {
           if(Array.isArray(files)){
           setFiles([ ...files,result ])
           }else{
            setFiles([files,result])
           }
       });
  }

  const onCross = (i) =>{
    if(readOnly) return;
    if(i==="one"){
      setFiles([]);
    }else{
    files.splice(i,1);
    setFiles([...files]);
    }
  }
  
  return (
   <div className={`flex ${classes}`}>
   {!readOnly && (Array.isArray(files) ? files?.length < 5 : true) && 
   <Upload showUploadList={false} beforeUpload={()=>false} onChange={changeFiles}>
    <div className={cx(styles.uploader,"column-center")}>
    <PlusOutlined/>
    </div>
    </Upload>}
    {
       !!files && (
       typeof files === 'string' ?  <Image src={files} classes="ml-1" onCross={()=>onCross('one')} readOnly={readOnly}/> :
       Array.isArray(files) && files?.map((file,i)=><Image src={file} classes="ml-1" key={i} onCross={()=>onCross(i)} readOnly={readOnly}/>)
       )
    }
   </div>
  )
}

export default UploadImage