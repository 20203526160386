import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);
const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
      display: false,
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
  scales: {
    y: {
       grid: {
          display: false
       }
    },
    x: {
      grid: {
         display: false
      }
   }
 }
};

const AreaChart = ({
  labels,
  data,
  color,
  border,
  height,
  width,
  centered,
  classes,
}) => {
  const chart_data = {
    labels,
    datasets: [
      {
        fill: true,
        data: data || [],
        borderColor: border || "#40e0d0",
        backgroundColor: color || "#40e0d050",
        lineTension:0.4,
        borderWidth:1.5
      },
    ],
  };
  return (
    <div
      style={{
        height: !!height ? height : "auto",
        width: !!width ? width : "300px",
      }}
      className={`${centered && "mx-auto"} ${classes} `}
    >
      <Line options={options} data={chart_data} />
    </div>
  );
};

export default AreaChart;
