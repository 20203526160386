import { CheckOutlined } from '@ant-design/icons'
import React from 'react';
import styles from './icons.module.css';
import cx from 'classnames';

const GreenTick = ({classes,...rest}) => {
    return (
        <div className={cx(styles.greenTick,classes)} {...rest}>
            <CheckOutlined />
        </div>
    )
}

export default GreenTick