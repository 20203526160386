import React from 'react';
import {Select} from 'antd';
import { PRIORITY_TYPES } from '../../helpers/Constants';

export const getPriorityTitle = (id) => {
    const prior = PRIORITY_TYPES.find(d=> d.id === id);
    if(!prior) return null;
    return prior?.title;
}

export const getPriorityColor = (id) => {
    const prior = PRIORITY_TYPES.find(d=> d.id === id);
    if(!prior) return undefined;
    return prior?.color
}

const PrioritySelect = ({onChange,value,disabled,placeholder}) => {

  return (
        <Select
            className="w-full"
            value={value}
            onChange={val => onChange(val)}
            disabled={disabled}
            placeholder={placeholder || "select priority"}
        >
            {PRIORITY_TYPES.map(({ title, id }) => (
                <Select.Option key={id} value={id}>
                    {title}
                </Select.Option>
            ))}
        </Select>
  )
}

export default PrioritySelect