import React from 'react';
import { Random } from '../../helpers/utils';
import styles from './tag.module.css';
import cx from 'classnames';

const Tag = ({ children, classes, color, onClick }) => {

    if(!children){
        return null
    }

    const colors = ["#ff19d547", "#6719ff47", "#2adafb47", '#02f8174d', "#f8e0024d"];

    return (
        <div onClick={onClick} className={cx(styles.tag, classes)} style={{ background: !!color ? color : colors[Random(0, colors.length - 1)] }}>{children}</div>
    )
}

export default Tag