import React, { useState } from "react";
import { Row, Col } from "antd";
import images, { CHECKLIST_COLS } from "../checklist-data";
import ChecklistCard, { Task } from "./ChecklistCard";
import ChecklistDetails from "./ChecklistDetails";
import AntdTable from "../../../components/table/AntdTable";
import { CopyOutlined, DeleteFilled } from "@ant-design/icons";
import { useContext } from "react";
import GlobalContext from "../../../store/GlobalContext";
import moment from "moment";

const ChecklistsGrid = ({ checklists,setChecklistModalOpen,onChecklistDelete,copy }) => {

  const [checklist,setChecklist] = useState(false);
  const {getUserById} = useContext(GlobalContext);

  const onClickDelete = (e,id)=>{
    e.preventDefault();
    e.stopPropagation();
    onChecklistDelete(id);
  }
  const onClickCopy = (e,title,tasks)=>{
    e.preventDefault();
    e.stopPropagation();
    copy({title,tasks});
  }
 
  const CHECKLIST_ANTD_COLS = CHECKLIST_COLS.map((col)=>{
    return{
      ...col,
      render: (text,record) => 
      col?.id === "tasks" ?
      <>
      {text.slice(0,3).map((t, i) => <Task task={t} key={i} modal={false} />)}
      {text.length > 3 && <p className="fw-600 ml-10 ">+ {text.length - 3} more tasks</p>}
      </>:
      col?.id === "createdBy" ? getUserById(text)?.displayName || "Unknown":
      col?.id === "createdOn" ? <p className="text-nowrap">{moment(new Date(text)).format("DD MMMM, YYYY")}</p> :
      col?.id === "actions" ? <div className="flex items-center" style={{marginTop:-10}}>
      <CopyOutlined onClick={e=>onClickCopy(e,record?.title,record?.tasks)} style={{ fontSize: 22, color: "#00a0e4" }} className="mr-1"/>
      <DeleteFilled onClick={e=>onClickDelete(e,record?.id)} style={{ fontSize: 22, color: "#f07070" }} />
    </div> : <p className="text-nowrap ">{text}</p>
    }
  });

  return (
    <>
     {/* <Row xs={24} className="mt-10">
     <Col xs={24} md={12} xl={8}>
        <div className="checklist__card column-center text-center" onClick={()=>setChecklistModalOpen(true)}>
          <div>
          <img src={images.add_new} alt="add new" className="mt-30" width={70} />
          <h3 className="mt-10">Add New Checklist</h3>
          </div>
        </div>
      </Col>
      {checklists.map(
        (checklist) => (
          <Col xs={24} md={12} xl={8} key={checklist.id} >
          <ChecklistCard checklist={checklist} onClick={(id,data)=>setChecklist({...data})} onChecklistDelete={onChecklistDelete} copy={copy}/>
          </Col>
        )
      )}
    </Row> */}
    <AntdTable data={checklists} columns={CHECKLIST_ANTD_COLS}  notSelectable onRowClick={(data)=>setChecklist(data)} />
    <ChecklistDetails data={checklist} open={!!checklist} onClose={()=>setChecklist(false)} />
    </>
  );
};

export default ChecklistsGrid;
