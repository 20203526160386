import { initializeApp } from 'firebase/app';
import { GoogleAuthProvider, getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBdyuEVqr9PXz-FR099FDgGp09FDHB_zSQ",
  authDomain: "smartudyog-5b629.firebaseapp.com",
  projectId: "smartudyog-5b629",
  storageBucket: "smartudyog-5b629.appspot.com",
  messagingSenderId: "1011863377378",
  appId: "1:1011863377378:web:7afb51b20cabd0075355bb",
  measurementId: "G-T66JG7K6QE",
};

const app = initializeApp(firebaseConfig);

const provider = new GoogleAuthProvider();
const auth = getAuth();
const analytics = getAnalytics(app);
const db = getFirestore(app);
const storage = getStorage(app);

export default app;
export { provider, auth, db ,storage};