import {  ArrowRightOutlined, EditFilled, FireOutlined, FundViewOutlined, NodeIndexOutlined, SaveFilled } from "@ant-design/icons";
import { Input, Spin } from "antd";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import React, { useState } from "react";
import "firebase/auth";
import {useNavigate} from 'react-router-dom';
import Button from "../../../components/buttons";
import AddNewButton from "../../../components/buttons/AddNewButton";
import { auth, db } from "../../../firebase";
import OtpModal from "./OtpModal";
import { ShowNotification } from "../../../helpers/utils";
import { NotificationType } from "../../../helpers/validators";
import { useContext } from "react";
import GlobalContext from "../../../store/GlobalContext";
import { useEffect } from "react";
import { doc, setDoc } from "firebase/firestore";
import { TABLES } from "../../../store/global-types";
import {v4 as uuidv4} from 'uuid';

const urls = [
  { title: "Live View", url: "https://uniqgridcloud.com" },
  { title: "Production", url: "https://uniqgridcloud.com" },
  { title: "Energy", url: "https://uniqgridcloud.com" },
];

const portal_icons = {
  0:<FundViewOutlined/>,
  1:<NodeIndexOutlined/>,
  2:<FireOutlined/>
}

const generateRecaptcha = () => {
  window.recaptchaVerifier = new RecaptchaVerifier(
    "recaptcha-container",
    {
      size: "invisible",
      callback: (response) => {},
    },
    auth
  );
};

const PortalMainPage = () => {
  const [otpOpen, setOtpOpen] = useState(false);
  const [edittable, setEdittable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [portals,setPortals]=useState([]);

  const {SETTINGS,BASE_URL,getSettings} = useContext(GlobalContext);
  const navigate = useNavigate();

  useEffect(()=>{
   if(!SETTINGS.portals){
    setPortals(urls);
   }else{
    setPortals(SETTINGS.portals);
   }
  },[SETTINGS.id]);

  const verifyUser = async () => {
    // const phoneNumber = "+917018825820"
    const phoneNumber = "+917799122202";
    generateRecaptcha();
    let verify = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, phoneNumber, verify)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setOtpOpen(true);
      })
      .catch((error) => {
        ShowNotification("Error!", error.message, NotificationType[1]);
      });
  };

  const confirmOtp = (e, otp) => {
    setOtpOpen(false);
    setLoading(true);
    const confirmationResult = window.confirmationResult;
    confirmationResult
      .confirm(otp)
      .then((res) => {
        ShowNotification(
          "Success",
          "Verified Successfully!",
          NotificationType[0]
        );
        setEdittable(true);
        setLoading(false);
      })
      .catch((err) => {
        ShowNotification("Error", err.message, NotificationType[0]);
        setLoading(false);
      });
  };

  const savePortals = async() =>{
    const id = SETTINGS?.id ? SETTINGS?.id : uuidv4();
    setLoading(true);
    await setDoc(doc(db,BASE_URL,TABLES.SETTINGS,id),{portals},{merge:true});
    await getSettings();
    setLoading(false);
    setEdittable(false);
  }

  return (
    <Spin spinning={loading} className="container">
      {portals.map(({ title, url }, i) => (
        <div key={i} className="mt-30">
          <h3 className="fw-600">{portal_icons[i]} {title}</h3>
          <div className="flex">
            <Input
              value={url}
              disabled={!edittable}
              onChange={e=>{
                portals[i].url=e.target.value;
                setPortals([...portals]);
              }}
              style={{ maxWidth: "600px" }}
            />
            {/* <Button onClick={()=>navigate(`/performance?url=${url}`)} classes="ml-10"><ArrowRightOutlined/></Button>
            <Button classes="ml-10" onClick={()=>window.open(url, '_blank', 'noopener,noreferrer')}>New Tab</Button> */}
          </div>
        </div>
      ))}
      <div id="recaptcha-container"></div>
      <div className="flex items-center">

      </div>
      <AddNewButton onClick={verifyUser} icon={<EditFilled />} classes="mt-40">Edit</AddNewButton>
      {edittable && <AddNewButton onClick={savePortals} icon={<SaveFilled />} classes="mt-40 ml-10">Save</AddNewButton>}
      <OtpModal
        open={otpOpen}
        onClose={() => setOtpOpen(false)}
        onSubmit={confirmOtp}
      />
    </Spin>
  );
};

export default PortalMainPage;
