import axios from "axios"
import { createUserWithEmailAndPassword, getAdditionalUserInfo, GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup, signOut } from "firebase/auth"
import { addDoc, collection, doc, setDoc } from "firebase/firestore"
import { auth, db, provider } from "../firebase"
import { API_BASE_URL, SM_TOKEN, SMF_TOKEN, IS_SM_LOGGED_IN } from "../helpers/Constants"
import { v4 as uuidv4 } from 'uuid';
import { SecureCall, ShowNotification } from "../helpers/utils"
import { NotificationType } from "../helpers/validators"
import { TABLES } from "../store/global-types"
import { LOGIN } from "./end-points"

const headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json'
}


const setStorage = (token,isLoggedIn,isFbUser,user) =>{
  localStorage.setItem(SMF_TOKEN, token);
  localStorage.setItem(IS_SM_LOGGED_IN,isLoggedIn);
  localStorage.setItem('isFbUser', isFbUser);
  localStorage.setItem('fb_user', JSON.stringify(user));

}

export const SignIn = async (email, password,tenantId) => {
  // const payload = {
  //   "username": email,
  //   "password": password
  // }
  // const call = async () => {
  //   const res = await axios.post(`${API_BASE_URL}${LOGIN}`, payload, { headers: headers });
  //   return res;
  // }
  // const onSuccess = (res) => {
  //   const { data } = res;
  //   localStorage.setItem(SM_TOKEN, data.token);
  //   localStorage.setItem("smr_token", data.refreshToken);
  //   localStorage.setItem(IS_SM_LOGGED_IN, true);
  //   localStorage.setItem("isFbUser", false);
  // }
  // const response = await SecureCall(call, onSuccess, "You're successfully logged In", "Login Error");
  // if (response?.status === 200) {
  //   window.location.reload();
  // }
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        const userData = {
          createdAt: user?.metadata?.createdAt,
          email:user?.email,
          uid: user?.uid,
          tenantId:tenantId
        }
        setStorage(user.accessToken,true,true,userData);
        onLoginSuccess();
      })
      .catch((err) => {
        ShowNotification("Error!", err.message, NotificationType[1]);
        console.log(err);
      });
}

export const SignUp = async (fullName,email,password,tenantId,newOrg,orgName) => {

  const userTenantId = (!tenantId && newOrg) ? uuidv4() : tenantId;

  createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;
      const userData = {
        displayName: fullName,
        createdAt: user?.metadata?.createdAt,
        email:user?.email,
        emailVerified:user?.emailVerified,
        phoneNumber:user?.phoneNumber,
        photoURL:user?.photoURL,
        uid: user?.uid,
        tenantId: userTenantId
      }

      if(!newOrg) userData.approvedByAdmin=false;

      setStorage(user.accessToken,true,true,userData);

      const base_url = `${TABLES.MAIN_TABLE}/${userTenantId.trim()}`;

      addUserToDb(userData, base_url,newOrg,orgName);
    })
    .catch((err) => {
      ShowNotification("Error!", err.message, NotificationType[1]);
      console.log(err);
    });
}

export const FirebaseLogout = async () => {

  try {
    await signOut(auth);
    ShowNotification("Success!", "Logged out successfully", NotificationType[0]);
  } catch (err) {
    ShowNotification("Error!", err.message, NotificationType[1]);
  }

}

const onLoginSuccess = () => {
  window.location.reload();
}

const addUserToDb = async (user, base_url,newOrg,orgName) => {
  if (!user) return;
  try {
    if(newOrg){ 
      await setDoc(doc(db,base_url),{title:orgName});
      await addDoc(collection(db,`${base_url}/${TABLES.USER_GROUPS}`),{"Admin":{}})
    }
    await setDoc(doc(db, `${base_url}/users`,user.uid), { ...user,userGroup:newOrg ? "Admin" : "Unassigned" });
    onLoginSuccess();
  } catch (e) {
    ShowNotification(e.message || "Something went wrong", NotificationType[1]);
  }
}


export const handleGoogleLogin = async (e, tenantId,newOrg,orgName) => {
  e.preventDefault();

  const userTenantId = (!tenantId && newOrg) ? uuidv4() : tenantId;

  signInWithPopup(auth, provider)
    .then(async (result) => {
      const credential = GoogleAuthProvider.credentialFromResult(result);

      const details = getAdditionalUserInfo(result);

      const user = {
        displayName: result?.user?.displayName,
        createdAt: result?.user?.metadata?.createdAt,
        email: result?.user?.email,
        emailVerified: result?.user?.emailVerified,
        phoneNumber: result?.user?.phoneNumber,
        photoURL: result?.user?.photoURL,
        uid: result?.user?.uid,
        tenantId: userTenantId
      }

      setStorage(credential.accessToken,true,true,user);

      if (details?.isNewUser === true || newOrg) {

        const base_url = `${TABLES.MAIN_TABLE}/${userTenantId.trim()}`;

        if(!newOrg) user.approvedByAdmin=false;

        await addUserToDb(user, base_url,newOrg,orgName);
      } else {
        onLoginSuccess();
      }
    }).catch((error) => {
      ShowNotification("Error!", error.message || "Something went wrong.", NotificationType[1]);
    });
}

export const getUserFromStore = () => {
  const user = JSON.parse(localStorage.getItem("fb_user"));
  return user;
}

export const checkFirebaseAuth = (show = false) => {
  const user = getUserFromStore();
  if (!user || !user?.email) {
    if (show === true) ShowNotification("Error!", "you need to be a firebase user to perfrom this action", NotificationType[1]);
    return false;
  }
  return user;
}