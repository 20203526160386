import RRule from "rrule";

export const API_BASE_URL = "https://platform.uniqgridcloud.com/";
export const USERNAME = "amplus@uniqgrid.com";
export const PASSWORD = "Amplus@123";
export const CUSTOMER_ID = "3a8e58a0-f74a-11eb-a672-6d7dc2c7af58";
// export const CUSTOMER_ID = "cdca3250-917e-11ec-a672-6d7dc2c7af58";
export const ENTITY_TYPE = "DEVICE";
export const ENTITY_ID = "6af8ac60-2694-11ec-a672-6d7dc2c7af58";
export const EMAIL_SERVICE_ID = "service_s19ueby";
export const EMAIL_TEMPLATE_ID = "template_4qmqev6";
export const INVITE_USER_TEMPLATE_ID = "template_kzj0ip9";
export const EMAIL_PUBLIC_KEY = "P8iJ6Qmm6PSx1aDSw";

export const SMF_TOKEN = "smf_token";
export const SM_TOKEN = "sm_token";
export const IS_SM_LOGGED_IN = "is_sm_loggedIn";

export const TAG_CATS = ["Maintenance", "Assets", "Users"];
export const TAG_TYPES = ["Priority", "Category", "Status"];

export const PERMISSIONS = {
  0: "Assets Management",
  1: "Production",
  2: "Maintenance",
  3: "Energy Management",
  4: "Tasks",
  5: "Alerts",
  6: "Confuguration wizard",
};

export const ROLES = {
  0: "Admin",
  1: "Group 1",
  2: "Group 2",
};
export const GROUP_PERMISSIONS = {
  [ROLES[0]]: [
    PERMISSIONS[0],
    PERMISSIONS[1],
    PERMISSIONS[2],
    PERMISSIONS[3],
    PERMISSIONS[4],
    PERMISSIONS[5],
    PERMISSIONS[6],
  ],
  [ROLES[1]]: [
    PERMISSIONS[0],
    PERMISSIONS[1],
    PERMISSIONS[2],
    PERMISSIONS[3],
    PERMISSIONS[4],
    PERMISSIONS[5],
  ],
  [ROLES[2]]: [
    PERMISSIONS[0],
    PERMISSIONS[1],
    PERMISSIONS[2],
    PERMISSIONS[3],
    PERMISSIONS[4],
    PERMISSIONS[5],
  ],
};

export const EVENT_TYPE = {
  0:"Task",
  1:"Inspection",
  2:"Failure",
  3:"Maintenance",
  4:"Work Order"
}

export const currency_otps = ["INR","Euro","USD","GBP"];

export const STATUS_TYPES  =  [
  { id: 0, title: "Yet to Start", color: "#d7d7d9" },
  { id: 1, title: "In Progress", color: "#40a9ff" },
  { id: 2, title: "On Hold", color: "#ffcd00" },
  { id: 3, title: "Cancelled", color: "#f73b0c" },
  { id: 4, title: "Completed", color: "#05da29" },
];

export const nextStatusMenu = (curr) => {
  switch(curr){
    case 0 :{
      return [{ id: 1, title: "In Progress", color: "#40a9ff" }]
    }
    case 1:{
      return [{ id: 2, title: "On Hold", color: "#ffcd00" },
      { id: 3, title: "Cancelled", color: "#f73b0c" },
      { id: 4, title: "Completed", color: "#05da29" },]
    }
    case 2:{
      return [
      { id: 1, title: "In Progress", color: "#40a9ff" },
      { id: 3, title: "Cancelled", color: "#f73b0c" },
      { id: 4, title: "Completed", color: "#05da29" },
      ]
    }
    case 3:
    case 4:{
      return null
    }
    default:{
      return [{ id: 0, title: "Yet to Start", color: "#d7d7d9" }]
    }
  }
}

export const PRIORITY_TYPES = [
    { id: 0, title: "Low", color: "#40a9ff" },
    { id: 1, title: "Medium", color: "#ffcd00" },
    { id: 2, title: "High", color: "#f73b0c" },
  ];

export const FREQUENCY_TYPES = [
  { id: RRule.DAILY, title: "Daily" },
  { id: RRule.WEEKLY, title: "Weekly" },
  { id: RRule.MONTHLY, title: "Monthly" },
];
