import { Row, Spin } from "antd";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { checkFirebaseAuth, getUserFromStore } from "../../../api/auth";
import { logActivity, LOG_TYPES } from "../../../api/logs";
import AddNewButton from "../../../components/buttons/AddNewButton";
import Tag from "../../../components/tag";
import { db } from "../../../firebase";
import { ShowNotification, split } from "../../../helpers/utils";
import { NotificationType } from "../../../helpers/validators";
import { TABLES } from "../../../store/global-types";
import GlobalContext from "../../../store/GlobalContext";
import AddVendorModal from "./AddVendorModal";
import VendorsTable from "./VendorsTable";
import TabSwitchButton from "../../../components/buttons/TabSwitchButton";

export const VendorRow = ({ value, getter }) => {
  return !!value
    ? split(value).map((v) =>
        getter(v) ? <Tag classes="m-1">{getter(v)?.name}</Tag> : ""
      )
    : "N/A";
};

const VendorsMainPage = () => {
  const { BASE_URL, VENDORS, getVendors, fakeUpdateLogs, getUserByUid } =
    useContext(GlobalContext);

  const [loading, setLoading] = useState(false);
  const [vendorModalOpen, setVendorModalOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [vendors, setVendors] = useState([]);
  const [editData, setEditData] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [tab, setTab] = useState(0);

  useEffect(() => {
    if (!VENDORS || !VENDORS?.length) return;
    setVendors(VENDORS);
  }, [VENDORS]);

  const onVendorModalClose = () => {
    setVendorModalOpen(false);
    setEditData(null);
  };

  const user = getUserFromStore();

  const onRowsSelect = (rows) => setSelectedRows(rows);

  //ADD OR EDIT DATA ON DATABASE
  const addVendorData = async (data, updatedColumns) => {
    const auth = checkFirebaseAuth(true);
    if (!auth || !data) return;

    setLoading(true);
    try {
      const table = `${BASE_URL}/${TABLES.VENDORS}`;
      if (data?.id) {
        if (!updatedColumns?.length) throw Error("No columns to update");
        const doc_id = data?.id;
        delete data.id;
        await setDoc(doc(db, table, doc_id), { ...data }, { merge: true });
        const what = `${updatedColumns?.join(", ")}`;
        const log = await logActivity(
          TABLES.VENDORS,
          user?.email,
          what,
          LOG_TYPES.UPDATED,
          doc_id,
          BASE_URL
        );
        fakeUpdateLogs(log, doc_id, TABLES.VENDORS);
        const index = vendors.findIndex((d) => d?.id === doc_id);
        vendors[index] = { ...data, id: doc_id };
        setVendors([...vendors]);
      } else {
        const { uid } = user;
        const userId = getUserByUid(uid)?.id;
        await addDoc(collection(db, table), {
          ...data,
          createdBy: userId,
          createdOn: moment(new Date()).format("YYYY-MM-DD"),
        });
        // const log = await logActivity(table, user?.email, "a new vendor", LOG_TYPES.ADDED);
        // fakeUpdateLogs(log);
        await getVendors();
      }
      setLoading(false);
    } catch (err) {
      ShowNotification(
        "Error!",
        err?.message || "something went wrong!",
        NotificationType[1]
      );
      setLoading(false);
    }
  };

  //ADD A COMMENT TO THE TABLE ROW
  const addComment = async (id) => {
    if (!comment?.length) return;

    const auth = checkFirebaseAuth(true);
    if (!auth) return;

    setLoading(true);
    const log = await logActivity(
      TABLES?.VENDORS,
      user?.email,
      comment,
      LOG_TYPES?.COMMENTED,
      id,
      BASE_URL
    );
    fakeUpdateLogs(log, id, TABLES.VENDORS);
    setComment("");
    setLoading(false);
  };

  return (
    <Spin spinning={loading}>
      <h1>Vendors & Customers</h1>
      <Row
        align="center"
        justify="space-between"
        gutter={[0, 10]}
        className="mt-20"
      >
        <div className="flex ">
          <TabSwitchButton selected={tab === 0} onClick={() => setTab(0)}>
            Vendors
          </TabSwitchButton>
          <TabSwitchButton selected={tab === 1} onClick={() => setTab(1)}>
            Customers
          </TabSwitchButton>
        </div>
        <div className="flex items-center">
          <AddNewButton onClick={() => setVendorModalOpen(true)}>
            Add {tab===0 ? "Vendor" : "Customer"}
          </AddNewButton>
        </div>
      </Row>
      <VendorsTable
        vendors={vendors.filter((d) =>
          tab === 0 ? !d.isCustomer : d.isCustomer === true
        )}
        setVendors={setVendors}
        VENDORS={VENDORS}
        onRowsSelect={onRowsSelect}
        setVendorModalOpen={setVendorModalOpen}
        setEditData={setEditData}
        tab={tab}
      />
      <AddVendorModal
        open={vendorModalOpen}
        onClose={onVendorModalClose}
        comment={comment}
        setComment={setComment}
        addComment={addComment}
        addData={addVendorData}
        editData={editData}
      />
    </Spin>
  );
};

export default VendorsMainPage;
