import React, { useState } from 'react'
import AntdTable from '../../../components/table/AntdTable';
import TableManipulator from '../../../components/table/TableManipulator'
import { TABLES } from '../../../store/global-types';
import { LOCATION_COLUMNS } from '../location-data'

const LocationsTable = ({locations,setLocations,LOCATIONS,onRowClick,onRowSelect}) => {


    const [locationColumns,setLocationColumns] = useState(LOCATION_COLUMNS);

  return (
    <>
    <TableManipulator
        allColumns={LOCATION_COLUMNS}
        columns={locationColumns}
        data={LOCATIONS}
        filteredData={locations}
        setColumns={setLocationColumns}
        setFilteredData={setLocations}
        setView={()=>{}}
        table={TABLES.LOCATIONS}
    />
    <AntdTable columns={locationColumns} data={locations} onRowClick={onRowClick} onRowSelect={onRowSelect}/>
    </>
  )
}

export default LocationsTable

  