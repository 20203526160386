import { Spin } from "antd";
import React, { useState } from "react";
import { TABLES } from "../../../store/global-types";
import folder from "../../../assets/png/folder.png";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useContext } from "react";
import GlobalContext from "../../../store/GlobalContext";
import { db, storage } from "../../../firebase";
import { listAll, ref } from "firebase/storage";
import AddNewButton from "../../../components/buttons/AddNewButton";
import AddFolderModal from "./AddFolderModal";
import { checkFirebaseAuth } from "../../../api/auth";
import moment from "moment";
import { onDocUpload } from "../../../store/global-actions";
import { addDoc, collection } from "firebase/firestore";
import AntdTable from "../../../components/table/AntdTable";

const all_folders = [
  { title: "Assets", path: TABLES.ASSETS, isSystem: true },
  { title: "Activity", path: TABLES.MAINTENANCE, isSystem: true },
];

const FileManagerMainPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [folders, setFolders] = useState(all_folders);
  const [folderModalOpen, setFolderModalOpen] = useState(false);
  const { BASE_URL, FOLDERS,getFolders } = useContext(GlobalContext);

  useEffect(() => {
    if (!BASE_URL)return;
    setLoading(true);
    const unsubscribe = setTimeout(()=>{
      setLoading(false);
      setFolders([...all_folders,...FOLDERS]);
    },2000);
    return()=>clearTimeout(unsubscribe);
    // getFolders();
  }, [BASE_URL,FOLDERS]);

  const ANTD_FOLDER_COLS = [
    {
        id:"title",
        title:"Name",
        render:(text,record) =><div className="flex items-center"> <img src={folder} height="40px" alt="folder" /> <p className="ml-10">{text}</p></div>
    },
    {
        id:"id",
        title:"ID",
        render:(text)=><p className=''>{text ? text : '-'}</p>
    },
    {
        id:"user",
        title:"Created By",
        render:(text)=><p className=''>{text ? text : '-'}</p>

    },
    {
        id:"createdAt",
        title:"Created On",
        render:(text)=><p className=''>{text ? text : 'System Folder'}</p>
    }
]


  // const getFolders = async () => {
  //   setLoading(true);
  //   const listRef = ref(storage, `${BASE_URL}`);
  //   const res = await listAll(listRef);
  //   console.log(res.prefixes);
  //   const fs = res.prefixes?.length
  //     ? res.prefixes
  //         .filter(
  //           (d) => d.name !== TABLES.MAINTENANCE && d.name !== TABLES.ASSETS
  //         )
  //         .map((d) => ({
  //           title: d.name?.split("-")?.join(" "),
  //           path: d?.name.trim(),
  //         }))
  //     : [];
  //   if (fs.length) setFolders((prev) => [...prev, ...fs]);
  //   setLoading(false);
  // };

  // const onCreateNewFolder = async (name, file) => {
  //   if (!file) return;
  //   const auth = checkFirebaseAuth(true);
  //   if (!auth) return;
  //   setLoading(true);
  //   await onDocUpload(file, null, BASE_URL, name, null, true);
  //   setFolderModalOpen(false);
  //   await getFolders();
  //   setLoading(false);
  //   setTimeout(() => {
  //     window.location.reload();
  //   }, 1000);
  // };

  const onCreateNewFolder = async(name) =>{
    if(!name) return;
    const auth = checkFirebaseAuth(true);
    if(!auth) return;
    const paylod = {
      title:name.trim(),
      path:name.trim().split(" ").join('-'),
      user:auth?.email,
      createdAt:moment().format("DD MMMM, YYYY  hh:mm A")
    }
    setLoading(true);
    await addDoc(collection(db,BASE_URL,TABLES.FOLDERS),paylod);
    await getFolders();
    setLoading(false);
  }

  return (
    <>
      <Spin className="container" spinning={loading}>
        <div className="flex items-center w-full justify-between mt-30 mb-20">
          <h1>File Manager</h1>
          <AddNewButton onClick={() => setFolderModalOpen(true)}>
            Add new Folder
          </AddNewButton>
        </div>
        <AntdTable data={folders} columns={ANTD_FOLDER_COLS} onRowClick={(d)=>navigate("/files/" + d.path)}/>
      </Spin>
      <AddFolderModal
        open={folderModalOpen}
        onSubmit={onCreateNewFolder}
        onClose={() => setFolderModalOpen(false)}
      />
    </>
  );
};

export default FileManagerMainPage;
