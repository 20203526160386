import { Select } from "antd";
import React from "react";
import { EVENT_TYPE } from "../../helpers/Constants";
import Tag from "../tag";

export const EventTypeCol = ({ text }) =>{
  return EVENT_TYPE[text] ? <Tag>{EVENT_TYPE[text]}</Tag> : "N/A";
}

const EventTypeSelect = ({ onChange, value, disabled, placeholder }) => {
  return (
    <Select
      className="w-full"
      value={value}
      onChange={(val) => onChange(val)}
      disabled={disabled}
      placeholder={placeholder || "select event type"}
    >
      {Object.entries(EVENT_TYPE).map(([key, title], i) => (
        <Select.Option key={key} value={key}>
          {title}
        </Select.Option>
      ))}
    </Select>
  );
};

export default EventTypeSelect;
