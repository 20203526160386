import React, { useState } from "react";
import { ORDER_COLS } from "../orders-data";
import VendorsSelect from "../../vendors/components/VendorsSelect";
import { Checkbox, Input } from "antd";
import ModalFooter from "../../../components/UI/ModalFooter";

const CreateOrderForm = ({onBack,onSubmit}) => {
  const [data, setData] = useState({});

  const onChange = (e, id, isEvent = true) => {
    const value = isEvent ? e.target.value : e;
    data[id] = value;
    setData({ ...data });
  };

  const handleSubmit = async()=>{
    await onSubmit(data);
    onBack();
  }

  return (
    <div className="">
      <h1 className="mb-20">New Order</h1>
      {ORDER_COLS.map(({ title, id, placeholder, type }) => (
        <>
          {type !== "bool" && (id !== "vendor" ? true   : data.isVendor===true) && <p className="fw-600 mt-20">{title}</p>}
          {type === "bool" ? (
            <div className="flex mt-20">
              <span>
                <Checkbox
                  checked={data[id] === true}
                  onClick={() => onChange(true, id, false)}
                />{" "}
                Vendor
              </span>
              <span className="ml-10">
                <Checkbox
                  checked={data[id] === false}
                  onClick={() => onChange(false, id, false)}
                />{" "}
                Internal
              </span>
            </div>
          ) : id === "customer" ||
            (id === "vendor" && data.isVendor === true) ? (
            <VendorsSelect
              value={data[id]}
              onChange={(val) => onChange(val, id, false)}
              placeholder={placeholder}
              onlyCustomers={id === "customer"}
              onlyVendors={id === "vendor"}
            />
          ) : id === "vendor" && !data.isVendor ? null : (
            <Input
              value={data[id]}
              placeholder={placeholder}
              type={type}
              onChange={(e) => onChange(e, id)}
            />
          )}
        </>
      ))}
      <ModalFooter editMode={false} onSubmit={handleSubmit} onClose={onBack} />
    </div>
  );
};

export default CreateOrderForm;
