import React, { useState } from 'react'
import TableManipulator from '../../../components/table/TableManipulator'
import { TABLES } from '../../../store/global-types';
import { CUSTOMER_COLUMNS, VENDOR_COLUMNS } from '../vendor-data';
import { useContext } from 'react';
import GlobalContext from '../../../store/GlobalContext';
import AntdTable from '../../../components/table/AntdTable';
import { useEffect } from 'react';

const VendorsTable = ({vendors,setVendors,VENDORS,setEditData,setVendorModalOpen,onRowsSelect,tab}) => {

  const [vendorColumns,setVendorColumns] = useState([]);
  const {getUserById} = useContext(GlobalContext);

  useEffect(()=>{
   setVendorColumns(tab === 0 ? VENDOR_COLUMNS : CUSTOMER_COLUMNS);
  },[tab]);

  //OPEN EDIT MODAL
  const onRowClick = (data) => {
    setEditData(data);
    setVendorModalOpen(true);
 }

const VENDOR_ANTD_COLS = (vendorColumns).filter(d=>d.id!=='isCustomer').map((col)=>{
  return{
    ...col,
    render: (text) => 
    col?.id==="createdBy" ? <p className='text-nowrap'>{getUserById(text)?.displayName}</p> || "N/A" : text
  }
})

  return (
    <>
    <TableManipulator
        allColumns={tab === 0 ? VENDOR_COLUMNS : CUSTOMER_COLUMNS}
        columns={vendorColumns}
        data={VENDORS}
        filteredData={vendors}
        setColumns={setVendorColumns}
        setFilteredData={setVendors}
        setView={()=>{}}
        table={TABLES.VENDORS}
    />
    <AntdTable columns={VENDOR_ANTD_COLS} data={vendors} onRowsSelect={onRowsSelect} bordered onRowClick={onRowClick}/>
    </>
  )
}

export default VendorsTable;