import {
  AppstoreAddOutlined,
  EnvironmentOutlined,
  NumberOutlined,
  TagsOutlined,
  ThunderboltTwoTone,
} from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import RedCross from "../../../components/icons/RedCross";
import FiltersWrapper from "../../../components/UI/FiltersWrapper";
import { TAG_TYPES } from "../../../helpers/Constants";
import { TABLES } from "../../../store/global-types";
import GlobalContext from "../../../store/GlobalContext";

const AssetFilters = ({ setAssets, ASSETS }) => {
  const { VENDORS, ASSET_GROUPS, getTagList, TAGS, LOCATIONS } =
    useContext(GlobalContext);
  const [assetGroups, setAssetGroups] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [locations, setLocations] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [clickedIds, setClickedIds] = useState([]);

  const onClickOption = (id, filterId) => {
    if (!appliedFilters.includes(filterId)) appliedFilters.push(filterId);
    setAppliedFilters([...appliedFilters]);
    clickedIds.push(id);
    setClickedIds([...clickedIds]);
  };

  useEffect(() => {
    if (!clickedIds?.length) return;
    setAssets((prev) => {
      return prev.filter((d) =>
        clickedIds.some((id) => JSON.stringify(d)?.includes(id))
      );
    });
  }, [clickedIds]);

  const filters = [
    {
      id: 1,
      title: "Asset group",
      list: assetGroups,
      icon: <AppstoreAddOutlined />,
    },
    { id: 2, title: "Manufacturer", list: vendors, icon: <NumberOutlined /> },
    {
      id: 4,
      title: "Locations",
      list: locations,
      icon: <EnvironmentOutlined />,
    },
    { id: 5, title: "Tags", list: categories, icon: <TagsOutlined /> },
  ];

  useEffect(() => {
    if (!VENDORS || !VENDORS.length) return;
    setVendors(
      VENDORS.map((d) => ({
        key: d?.name,
        value: d?.id,
      }))
    );
  }, [VENDORS]);

  useEffect(() => {
    if (!LOCATIONS || !LOCATIONS.length) return;
    setLocations(
      LOCATIONS.map((d) => ({
        key: d?.locationTitle,
        value: d?.id,
      }))
    );
  }, [LOCATIONS]);

  useEffect(() => {
    if (!ASSET_GROUPS || !Object.keys(ASSET_GROUPS)?.length) return;
    setAssetGroups(
      Object.keys(ASSET_GROUPS).map((d) => ({
        key: d,
        value: d,
      }))
    );
  }, [ASSET_GROUPS]);

  useEffect(() => {
    if (!TAGS) return;
    const cat_tags = getTagList(TABLES.ASSETS, TAG_TYPES[1]);
    if (!cat_tags) return;
    setCategories(
      cat_tags.map((d) => ({
        key: d?.title,
        value: d?.id,
      }))
    );
  }, [TAGS]);

  const clearAll = () => {
    setAppliedFilters([]);
    setClickedIds([]);
    setAssets([...ASSETS]);
  };

  return (
    <>
      <div className="flex">
        <p className="mr-10 fw-600">
          <ThunderboltTwoTone /> Quick Filters
        </p>
        {appliedFilters?.length || clickedIds?.length ? (
          <Button onClick={clearAll} className="h-40 fw-600 rounded-10">
            <div className="flex items-center">
              <RedCross /> <p className="ml-1">Clear All</p>
            </div>
          </Button>
        ) : null}
      </div>
      <FiltersWrapper>
        {filters.map(({ id, title, list, icon }) => (
          <Dropdown
            overlay={
              <Menu>
                {list.map(({ key, value }) => (
                  <Menu.Item
                    key={value}
                    onClick={({ e, key }) => onClickOption(key, id)}
                  >
                    {key}
                  </Menu.Item>
                ))}
              </Menu>
            }
            placement="bottomCenter"
            key={id}
            trigger={["click"]}
          >
            <Button
              type={appliedFilters.includes(id) ? "primary" : "default"}
              className="h-40 fw-600 rounded-10 mr-10"
            >
              {icon} {title}
            </Button>
          </Dropdown>
        ))}
      </FiltersWrapper>
    </>
  );
};

export default AssetFilters;
